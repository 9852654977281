<template>
  <ion-page>
    <ion-content>

<!--To Do--> 
<ion-toolbar>
    <ion-buttons slot="start">
      <h4 class="ion-font-weight-bold">To Do</h4>
    </ion-buttons>
    <ion-buttons slot="end">
        <ion-button fill="clear">
          <div v-if="totalTodoRecords || todoFilters.length !== activityTypes.length" class="flex relative">
            <ion-icon :icon="filter" class="filter" @click="toggleMenu('todo')" />
            <div v-show="showToDoMenu">
              <div v-for="todoType in activityTypes">
                {{ capitalizeFirstLetter(todoType) }}
                <input type="checkbox" :value="todoType" v-model="todoFilters" />
              </div>
            </div>
          </div>
          </ion-button>
      </ion-buttons>
    </ion-toolbar>

      <activity-card v-if="totalTodoRecords" :activities="todo" />
      <div v-else>
        <div v-if="todoFilters.length == activityTypes.length">   
          <EmptyState message="There are currently no 'Activities' in your ToDo." />
        </div>
        <div v-else>
          <EmptyState message="Oops! It seems your search didn't match any 'Activities' in your ToDo list." />
        </div>
      </div>
  
<!--Archive-->
<ion-toolbar>
          <ion-buttons slot="start" class="ion-padding-start">
            <h4 class="ion-font-weight-bold">Archive</h4>
          </ion-buttons>
          <ion-buttons slot="end">
              <ion-button fill="clear" class="ion-padding-horizontal">
                <div v-if="totalArchiveRecords || archiveFilters.length !== activityTypes.length" class="flex relative">
                  <ion-icon :icon="filter" class="filter" @click="toggleMenu('archive')" />
                  <div v-show="showArchiveMenu">
                    <div v-for="archiveType in activityTypes">
                      {{ capitalizeFirstLetter(archiveType) }}
                      <input type="checkbox" :value="archiveType" v-model="archiveFilters" />
                    </div>
                  </div>
                </div>
              
              </ion-button>
          </ion-buttons>
        </ion-toolbar>

      <activity-card v-if="totalArchiveRecords" :activities="archive" />
      <div v-else>
        <div v-if="archiveFilters.length == activityTypes.length">
          <EmptyState message="There are currently no 'Activities' in your Archive." />
        </div>
        <div v-else>
          <EmptyState message="Oops! It seems your search didn't match any 'Activities' in your ToDo list." />
        </div>
      </div>
    </ion-content>
  </ion-page>

</template>

<script setup>

  import { ref, computed, watch, onMounted } from 'vue'
  import EmptyState from '../components/EmptyState';
  import ActivityCard from '../components/ActivityCard';
  import { filter } from 'ionicons/icons';
  import { sendPost } from '../components/entities/functions.js';
  import Header from '../components/Header';
  import { 
    IonPage, 
    IonContent, 
    IonButtons,  
    IonIcon,
    IonToolbar, 
    IonButton, 
  } from '@ionic/vue';

// Reactive references
const todo = ref([]);
const archive = ref([]);
const activityTypes = ref(['questionnaires', 'worksheets', 'readings']);
const todoFilters = ref(['questionnaires', 'worksheets', 'readings']);
const archiveFilters = ref(['questionnaires', 'worksheets', 'readings']);
const showToDoMenu = ref(false);
const showArchiveMenu = ref(false);
const loading = ref(true);
const firstLoading = ref(true);

// Computed properties
const totalTodoRecords = computed(() => {
  let total = 0;
  if (todo.value) {
    if (todo.value.questionnaire_instances) {
      total += todo.value.questionnaire_instances.length;
    }
    if (todo.value.reading_instances) {
      total += todo.value.reading_instances.length;
    }
    if (todo.value.worksheet_instances) {
      total += todo.value.worksheet_instances.length;
    }
  }
  return total;
});

const totalArchiveRecords = computed(() => {
  let total = 0;
  if (archive.value) {
    if (archive.value.questionnaire_instances) {
      total += archive.value.questionnaire_instances.length;
    }
    if (archive.value.reading_instances) {
      total += archive.value.reading_instances.length;
    }
    if (archive.value.worksheet_instances) {
      total += archive.value.worksheet_instances.length;
    }
  }
  return total;
});

// Methods
const getContent = async () => {
  loading.value = true;
  const response = await sendPost('/api/content/activities', {
    todo_types: todoFilters.value,
    archive_types: archiveFilters.value
  });
  loading.value = false;
  firstLoading.value = false;
  todo.value = response.todo;
  archive.value = response.archive;
};

const toggleMenu = (type) => {
  if (type == 'todo') {
    showToDoMenu.value = !showToDoMenu.value;
  } else {
    showArchiveMenu.value = !showArchiveMenu.value;
  }
};

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Watchers
watch(todoFilters, getContent);
watch(archiveFilters, getContent);

// Mounted hook
onMounted(() => {
  getContent();
});
</script>

<style scoped>
.ion-font-weight-bold {
  font-weight: medium;
  font-size: 18px;
}
</style>