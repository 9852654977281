<template>
  <ion-card 
    v-for="client in data" 
    :key="client.id" 
    class="ion-margin ion-no-padding ion-card-compact"
    style="background-color: white;"
    @click="goToClientPage(client.id)"
  >
    <ion-card-header class="ion-no-padding">
      <ion-card-title class="ion-text-center ion-padding">
        <h3 class="text-lg font-medium">{{ client.name }}</h3>
      </ion-card-title>
    </ion-card-header>

    <ion-card-content class=" ion-text-center">
      <div class="flex flex-wrap justify-center gap-1">
        <ion-chip 
          v-for="status in client.statuses" 
          :key="'status_' + client.id + '_' + status.id" 
          class="ion-chip-small"
        >
          <ion-label class="text-sm">{{ status.name }}</ion-label>
        </ion-chip>
      </div>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonChip, IonLabel } from '@ionic/vue';
import { useRouter } from 'vue-router';

// Props for the component
const props = defineProps({
  data: {
    type: Array,
    required: true,
  },
});

// Use Vue Router for navigation
const router = useRouter();

const goToClientPage = (id) => {
  router.push({ name: 'ClientView', params: { clientId: id } });
};
</script>

<style scoped>
/* Compact card style */
.ion-card-compact {
  max-width: 350px;
  margin: 10px auto;
  padding: 4px;
}

.ion-chip-small {
  font-size: 0.75rem;
  padding: 4px 8px;
}

/* Hover effect for card */
.ion-card-compact:hover {
  background-color: #f4f4f4;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Styling for client name */
.ion-card-title h3 {
  font-size: 1.2rem;
  font-weight: 600;
}

/* Styling for chips container */
.ion-card-content .flex {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 8px;
}
</style>