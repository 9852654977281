<template>

<ion-toolbar>
    <!-- Start Slot -->
    <ion-buttons slot="start" class="ion-padding-start">
      <h4 class="ion-font-weight-bold">{{ session ? 'Next ' + bookText : bookText + 's' }}</h4>
    </ion-buttons>
    <!-- End Slot -->
    <ion-buttons slot="end">
      <router-link to="/sessions" class="ion-text-primary">
        <ion-button fill="clear" class="ion-padding-horizontal">
          <span class="ion-text-body">See All</span>
        </ion-button>
      </router-link>
    </ion-buttons>
  </ion-toolbar>


    <!-- Next Session Card -->
      <ion-col v-if="session">
        <router-link to="/sessions">
          <ion-card style="background-color: white;">
          
            <ion-card-content class="ion-padding">
            <!-- Date and Time Section -->
            <ion-grid>
              <ion-row>
                <ion-col>     
                  <ion-text color="primary">
                  <h1>
                    {{ getWeekDay(session.scheduled_time) }}, {{ getDate(session.scheduled_time) }}
                  </h1>
                </ion-text>
                <ion-text color="dark">
                  <p>
                    {{ getStartTime(session.scheduled_time) }} - {{ getFinishTime(session.scheduled_time, session.duration) }}
                  </p>
                </ion-text>
                </ion-col>
              </ion-row>
              <ion-row class="ion-align-items-center ion-margin-top" v-if="session.user_name">
                <!-- Therapist's Avatar -->
                <ion-col size="auto">
                  <ion-avatar>
                    <img src="/img/avatar.jpg" alt="Therapist face image" />
                  </ion-avatar>
                </ion-col>
                <!-- Smaller, Subtle Therapist Name -->
                <ion-col>
                  <ion-text color="dark">
                    <p>
                      {{ session.user_name }}
                    </p>
                  </ion-text>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
            <ion-footer>
              <div v-if="session.isStartingSoon">
                <p v-if="!session.sessionStarted && !session.sessionEnded">
                  Get ready, this {{ bookText }} will begin in
                  <span class="font-semibold">{{ session.timeTillSessionStart }}</span>
                </p>
                <ion-button expand="block" class="ion-margin-top" @click="joinSession">
                  Enter Waiting Room
                  <ion-icon slot="end" name="videocam-outline" />
                </ion-button>
              </div>
              <p v-else-if="session.isInFuture" class="text-primary font-medium">Session Upcoming</p>
              <p v-else class="text-gray-500 italic">It looks like you missed your {{ bookText }}</p>
            </ion-footer>
          </ion-card>
        </router-link>
      </ion-col>
  

    <!-- No Upcoming Sessions Card -->
    <ion-row v-else>
      <ion-col>
        <ion-card class="ion-padding">
          <ion-card-content>
            <ion-text class="ion-text-center">
              <h2>You have no upcoming {{ bookText }}s!</h2>
            </ion-text>
            <div class="ion-text-center">
              <router-link to="/sessions">
                <ion-button class="ion-margin-top" color="secondary">
                  Book <span class="capitalize">{{ bookText }}</span>
                </ion-button>
              </router-link>
            </div>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { sendPost } from './entities/functions.js';
import { chevronForwardOutline } from 'ionicons/icons';

import { 

  IonAvatar, 
  IonRow, 
  IonGrid,
  IonButtons,  
  IonRadio,
  IonRadioGroup,
  IonCol,
  IonIcon,
  IonHeader, 
  IonFooter, 
  IonToolbar, 
  IonCard,
  IonCardContent, 
  IonText, 
  IonButton, 
 
  } from '@ionic/vue';

const router = useRouter();
const store = useStore();

const session = ref(null);
const timerInterval = ref(null);

const user = computed(() => store.getters['user']);
const bookText = computed(() => (user.value.is_free_consultation ? 'consultation' : 'session'));

const getWeekDay = (scheduled_time) => {
  const date = new Date(scheduled_time);
  const weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  return weekDays[date.getUTCDay()];
};

const getDate = (scheduled_time) => {
  const date = new Date(scheduled_time);
  return date.toLocaleDateString([], { day: 'numeric', month: 'long' });
};

const getStartTime = (time) => {
  const utcTime = new Date(time);
  return utcTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const getFinishTime = (time, duration) => {
  const finishTime = new Date(time);
  finishTime.setSeconds(finishTime.getSeconds() + duration);
  return finishTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

const formatTime = (seconds) => {
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
};

const isSessionStartingSoon = (scheduled_time, duration) => {
  const sessionStart = new Date(scheduled_time);
  const sessionEnd = new Date(scheduled_time);
  sessionEnd.setMinutes(sessionEnd.getMinutes() + duration / 60);

  const now = new Date();
  return now.getTime() >= sessionStart.getTime() - 15 * 60 * 1000 && now.getTime() <= sessionEnd.getTime();
};

const isSessionInFuture = (scheduled_time) => {
  const sessionStart = new Date(scheduled_time);
  const now = new Date();
  return sessionStart.getTime() > now.getTime();
};

const timerTick = () => {
  const now = Date.now();

  const sessionStartDateTime = new Date(session.value.scheduled_time);
  const sessionStartTime = sessionStartDateTime.getTime();
  const sessionEndTime = sessionStartTime + session.value.duration * 1000;

  session.value.sessionStarted = now >= sessionStartTime;
  session.value.sessionEnded = now >= sessionEndTime;
  session.value.isStartingSoon = isSessionStartingSoon(session.value.scheduled_time, session.value.duration);
  session.value.isInFuture = isSessionInFuture(session.value.scheduled_time);

  if (!session.value.sessionStarted && !session.value.sessionEnded) {
    const remainingTimeStart = Math.ceil((sessionStartTime - now) / 1000);
    session.value.timeTillSessionStart = formatTime(remainingTimeStart);
  }
};

const setTimer = () => {
  timerTick();
  timerInterval.value = setInterval(timerTick, 1000);
};

const joinSession = () => {
  router.push({ name: 'VideoChat' });
};

onMounted(() => {
  sendPost('/api/video/get-sessions', { limit: 1 }).then((response) => {
    if (response?.sessions?.length) {
      session.value = response.sessions[0];
      setTimer();
    }
  });
});

onBeforeUnmount(() => {
  if (timerInterval.value) {
    clearInterval(timerInterval.value);
  }
});
</script>

<style scoped>
.ion-font-weight-bold {
  font-weight: medium;
  font-size: 18px;
}
</style>