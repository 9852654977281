<template>
  <ion-app>
    <!-- Dynamically apply layout -->
    <component :is="currentLayout">
      <ion-router-outlet></ion-router-outlet>
    </component>
  </ion-app>
</template>

<script setup>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import {  } from '@ionic/vue';
import { computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';
import router from '../js/router';
import store from '../js/store';

// Dynamically determine the layout based on route meta
const route = useRoute();
const currentLayout = computed(() => route.meta.layout || 'div'); // Default to 'div' if no layout defined

// Setup Axios interceptors for handling authentication/authorization
onMounted(() => {
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        if (error.response.status === 401 || error.response.status === 419) {
          if (error.response.data.message === 'CSRF token mismatch.') return;
          store.dispatch('logout');
          router.replace({ name: 'Login' }); // Redirect to login if session expired
        } else if (error.response.status === 403) {
          router.push({ name: 'VerifyEmail' }); // Redirect to verify email page
        } else if (error.response.status === 423) {
          router.push({ name: 'ConfirmPassword' }); // Redirect to password confirmation page
        }
      }
      return Promise.reject(error);
    }
  );
});
</script>