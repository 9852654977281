<template>
  <ion-loading 
    :is-open="showOverlay" 
    :message="'Loading...'" 
    :spinner="'crescent'" 
  >
    <ion-button 
      v-show="showReloadButton" 
      @click="reloadPage" 
      color="primary" 
      class="mt-3"
    >
      Reload
    </ion-button>
  </ion-loading>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { 
  IonPage, 
  IonContent, 
  IonLoading,  
  IonButton, 

  } from '@ionic/vue';

// Props
const props = defineProps({
  loading: Boolean, // This comes from the parent component (e.g., dashboard)
});

// State
const showOverlay = ref(false);
const showReloadButton = ref(false);

// Methods
const reloadPage = () => {
  location.reload();
};

// Watch for loading prop change and control showOverlay state
watch(
  () => props.loading,
  (newValue) => {
    if (newValue) {
      showOverlay.value = true;
      // Show reload button after 30 seconds if still loading
      setTimeout(() => {
        showReloadButton.value = true;
      }, 30000);
    } else {
      showOverlay.value = false;
      showReloadButton.value = false;
    }
  },
  { immediate: true } // Ensure it checks immediately when the component is mounted
);
</script>