<template>
  <div class="pb-4 px-6">
    <!-- Expandable Button -->
    <div
      :class="{
        'border border-gray-600 shadow-lg bg-primary-100 rounded-md transition-all duration-300 overflow-hidden':
          true,
        'px-4 py-6': isFormVisible,
        'px-4 py-4': !isFormVisible,
      }"
      @click="toggleFormVisibility"
    >
      <div class="flex items-center justify-between cursor-pointer">
        <span class="text-gray-800 font-semibold">Send Me a Message</span>
        <!-- Heroicons Chevron -->
        <ChevronDownIcon
          :class="{ 'rotate-180': isFormVisible, 'rotate-0': !isFormVisible }"
          class="w-6 h-6 transform transition-transform duration-300"
        />
      </div>

      <!-- Contact Form -->
      <div v-if="isFormVisible" @click.stop>
        <form 
          @submit.prevent="submitForm"
          class="mt-4 max-w-lg mx-auto space-y-4"
        >
          <div class="flex flex-col">
            <label for="name" class="mb-1 text-sm font-medium text-gray-700">Name</label>
            <input 
              type="text" 
              id="name" 
              v-model="form.name" 
              class="border-none rounded-md p-3 focus:outline-none"
              placeholder="Enter your name" 
              required
            />
          </div>

          <div class="flex flex-col">
            <label for="email" class="mb-1 text-sm font-medium text-gray-700">Email</label>
            <input 
              type="email" 
              id="email" 
              v-model="form.email" 
              class="border-none rounded-md p-3 focus:outline-none"
              placeholder="Enter your email" 
              required
            />
          </div>

          <div class="flex flex-col">
            <label for="contactMessage" class="mb-1 text-sm font-medium text-gray-700">Message</label>
            <textarea 
              id="contactMessage" 
              v-model="form.contactMessage" 
              rows="5" 
              class="border-none rounded-md p-3 focus:outline-none"
              placeholder="Write your message here" 
              required
            ></textarea>
          </div>

          <button 
            type="submit" 
            class="tracking-wide w-full bg-primary text-white font-semibold rounded-md py-3 hover:bg-primary-600 transition-colors"
          >
            SEND
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from '@heroicons/vue/24/outline';

export default {
  components: {
    ChevronDownIcon,
  },
  data() {
    return {
      isFormVisible: false, // Controls the visibility of the form
      form: {
        name: '',
        email: '',
        contactMessage: '',
      },
    };
  },
  methods: {
    async submitForm() {
      try {
        const response = await fetch('/api/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(this.form),
        });
        if (!response.ok) throw new Error('Failed to send message.');
        alert('Message sent successfully!');
        this.form = { name: '', email: '', contactMessage: '' };
        this.isFormVisible = false; // Hide the form after successful submission
      } catch (error) {
        alert('Error sending message. Please try again later.');
      }
    },
    toggleFormVisibility() {
      this.isFormVisible = !this.isFormVisible;
    },
  },
};
</script>

<style>

</style>