<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div v-if="loading">Loading readings...</div>
      <div v-else>
        <div v-for="reading in readings" :key="reading.id" class="reading-card">
          <ion-card>
            <img :src="'/storage/' + reading.image" alt="Reading image" />
            <ion-card-header>
              <ion-card-title><h4>{{ reading.title }}</h4></ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <p>{{ reading.summary }}</p>
            </ion-card-content>
          </ion-card>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import axios from 'axios';
import { IonPage, IonContent, IonCard, IonCardHeader, IonCardTitle, IonCardContent } from '@ionic/vue';

const readings = ref([]);
const loading = ref(true);

onMounted(async () => {
  try {
    const response = await axios.get('/api/readings');
    readings.value = response.data.readings;
  } catch (error) {
    console.error('Failed to fetch readings:', error);
  } finally {
    loading.value = false;
  }
});
</script>

<style scoped>
.reading-card {
  margin-bottom: 20px;
}
</style>