<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Problem List -->
      <ion-list lines="full" v-if="getProblems.length > 0" class="my-8">
        <ion-item v-for="problem in getProblems" :key="problem.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ problem.problem }}</h3>
            <p class="text-muted">Category: {{ problem.category }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditProblemModal(problem)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteProblem(problem.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No problems have been recorded yet." />
      </div>

      <!-- Add Problem Button -->
      <ion-button expand="block" @click="openAddProblemModal">Add Problem</ion-button>

      <!-- Add/Edit Problem Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Problem' : 'Add Problem' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitProblem">
            <div class="ion-margin-bottom">
              <ion-label>Problem</ion-label>
              <ion-input v-model="newProblem.problem" placeholder="Enter problem" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Category</ion-label>
              <ion-select v-model="newProblem.category" placeholder="Select category" required>
                <ion-select-option v-for="category in categories" :key="category" :value="category">
                  {{ category }}
                </ion-select-option>
              </ion-select>
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Problem' : 'Add Problem' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentProblemId = ref(null);
const newProblem = ref({ problem: '', category: '' });
const categories = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch problems from Vuex store
const getProblems = computed(() => store.getters['problems/getProblems'].slice().reverse());

// Fetch problems on mount
onMounted(() => {
  store.dispatch('problems/fetchProblems');
});

// Open modal for adding a new problem
const openAddProblemModal = () => {
  isEditing.value = false;
  newProblem.value = { problem: '', category: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing problem
const openEditProblemModal = (problem) => {
  isEditing.value = true;
  newProblem.value = { problem: problem.problem, category: problem.category };
  currentProblemId.value = problem.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newProblem.value = { problem: '', category: '' }; // Reset form
  currentProblemId.value = null;
};

// Add or update a problem
const submitProblem = () => {
  if (newProblem.value.problem && newProblem.value.category) {
    if (isEditing.value) {
      store.dispatch('problems/updateProblem', { id: currentProblemId.value, ...newProblem.value });
    } else {
      store.dispatch('problems/addProblem', { ...newProblem.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete a problem
const deleteProblem = (id) => {
  store.dispatch('problems/deleteProblem', id);
};
</script>

<style scoped>
ion-label {
  font-size: 18px;
  color: #333;
}

ion-button {
  --border-radius: 5px;
  --height: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

li .ion-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --height: 35px;
  --width: 35px;
}

li .ion-button .ion-icon {
  --color: white;
}
</style>