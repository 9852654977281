<template>
  <InformationModal ref="informationModal"/>
  
  <div class="w-full my-2 ">
    <div class="w-full max-w-md rounded-2xl bg-secondary-100 p-2">
      <Disclosure v-slot="{ open }">
        <DisclosureButton
          class="flex w-full justify-between rounded-xl bg-secondary-200 px-4 py-5 text-left text-lg md:text-lg font-medium text-secondary-700 hover:bg-secondary-300 focus:outline-none focus-visible:ring focus-visible:ring-secondary-500/75"
        >
          <span>{{ processedTitle }}</span>
          <ChevronUpIcon
            :class="open ? 'rotate-180 transform' : ''"
            class="h-7 w-auto text-secondary-700"
          />
        </DisclosureButton>

        <transition
            enter-active-class="transition duration-100 ease-out"
            enter-from-class="transform scale-95 opacity-0"
            enter-to-class="transform scale-100 opacity-100"
            leave-active-class="transition duration-75 ease-out"
            leave-from-class="transform scale-100 opacity-100"
            leave-to-class="transform scale-95 opacity-0">

          <!-- Sorted Content Here -->
          <DisclosurePanel class="-mt-2 p-4 rounded-b-xl card-shadow max-w-md leading-normal text-sm font-sans font-normal text-white bg-white">
            <!-- Sorted Factor List -->
            <div v-for="item in sortedScores" :key="item.id" class="py-3 my-1">
              <div @click="openModal(formatFactorName(item.factor), item.factor_description)" class="cursor-pointer">
                <div class="flex flex-row items-center justify-between w-full mt-1 mb-2 py-px text-display-base">
                  <p class="flex">{{ formatFactorName(item.factor) }}
                    <span class="ml-4 cursor-pointer">
                      <InformationCircleIcon class="h-6 w-auto text-gray-400" />
                    </span>
                  </p>
                  <div class="flex items-center justify-between space-x-2">
                    <p>{{ item.value + (item.is_percentage ? '%' : '') }}</p>
                  </div> 
                </div>
                <div v-if="item.is_percentage" class="w-full h-2 bg-black/10 rounded-md ">
                  <div class="h-2 rounded-md" :style="{ width: item.value + '%', 'background-color': getFactorColor(item) }"></div>
                </div>
                
                <div class="pt-3 pb-6">
                  <p class="text-body-base">
                      {{ item.personal_factor_description }}
                    </p>
                </div>
              </div>
            </div>

            <div class="mt-4">
              <p class="text-body-sm">Last Measured - 
              <span class="text-display-sm-alpha text-secondary-600 text-end">{{ formattedUpdatedAt }}</span>
              </p>
            </div>

          </DisclosurePanel>
        </transition>
      </Disclosure>
    </div>
  </div>
</template>

<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon, InformationCircleIcon } from '@heroicons/vue/20/solid'
import InformationModal from './InformationModal';
import { generateColorCode } from './entities/functions.js';

</script>

<script>
export default {
  props: {
    data: Object
  },
  data: function () {
    return {
      informationModalActions: {
        closeModal: null,
        openModal: null
      },
    }
  },
  
  provide: function() {
    return {
      informationModalActions: this.informationModalActions
    };
  },

  computed: {
    formattedUpdatedAt() {
      let datetime = new Date(this.data.updated_at);
      return datetime.toLocaleDateString('en-GB');
    },
    processedTitle() {
      return this.data.title.replace('Measuring', '').trim();
    },
    sortedScores() {
      // Sort the scores based on value (percentage) from highest to lowest
      return this.data.calculated_scores
        .filter(item => item.is_percentage) // Optional: Filter if you want to sort only percentages
        .sort((a, b) => b.value - a.value); // Sorting descending by score
    }
  },

  methods: {
    openModal(title, description_one, description_two) {
      let text = [];
      if (description_one) {
        text.push(description_one);
      }
      if (description_two) {
        text.push(description_two);
      }
      if (text.length) {
        this.informationModalActions.openModal(title, text);
      }
    },

    closeModal() {
      this.informationModalActions.openModal();
    },

    formatFactorName(value) {
      if (!value) {
        return 'Score';
      }
      return value;
    },

    getFactorColor(item) {
      if (item.color) {
        return item.color;
      }
      return generateColorCode(this.formatFactorName(item.factor));
    },
  }
}
</script>