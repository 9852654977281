<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Aim List -->
      <ion-list lines="full" v-if="getAims.length > 0" class="my-8">
        <ion-item v-for="aim in getAims" :key="aim.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ aim.aim }}</h3>
            <p class="text-muted">Description: {{ aim.description }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditAimModal(aim)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteAim(aim.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No aims have been recorded yet." />
      </div>

      <!-- Add Aim Button -->
      <ion-button expand="block" @click="openAddAimModal">Add Aim</ion-button>

      <!-- Add/Edit Aim Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Aim' : 'Add Aim' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitAim">
            <div class="ion-margin-bottom">
              <ion-label>Aim</ion-label>
              <ion-input v-model="newAim.aim" placeholder="Enter aim" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Description</ion-label>
              <ion-input v-model="newAim.description" placeholder="Write description" required />
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Aim' : 'Add Aim' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentAimId = ref(null);
const newAim = ref({ aim: '', description: '' });
const descriptions = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch aims from Vuex store
const getAims = computed(() => store.getters['aims/getAims'].slice().reverse());

// Fetch aims on mount
onMounted(() => {
  store.dispatch('aims/fetchAims');
});

// Open modal for adding a new aim
const openAddAimModal = () => {
  isEditing.value = false;
  newAim.value = { aim: '', description: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing aim
const openEditAimModal = (aim) => {
  isEditing.value = true;
  newAim.value = { aim: aim.aim, description: aim.description };
  currentAimId.value = aim.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newAim.value = { aim: '', description: '' }; // Reset form
  currentAimId.value = null;
};

// Add or update an aim
const submitAim = () => {
  if (newAim.value.aim && newAim.value.description) {
    if (isEditing.value) {
      store.dispatch('aims/updateAim', { id: currentAimId.value, ...newAim.value });
    } else {
      store.dispatch('aims/addAim', { ...newAim.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete an aim
const deleteAim = (id) => {
  store.dispatch('aims/deleteAim', id);
};
</script>

<style scoped>
ion-label {
  font-size: 18px;
  color: #333;
}

ion-button {
  --border-radius: 5px;
  --height: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

li .ion-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --height: 35px;
  --width: 35px;
}

li .ion-button .ion-icon {
  --color: white;
}
</style>