import axios from "axios";
import { Capacitor } from "@capacitor/core"; // Detect if running in native app
import { sendGet, sendPost } from "../../components/entities/functions";

export const state = {
  user: null,
  user_id: null,
  is_assessment: false,
  token: null, // Token for native app
};

export const mutations = {
  updateUser: (state, user) => {
    state.user = user;
  },
  updateUserId: (state, user_id) => {
    state.user_id = user_id;
  },
  updateAssessment: (state, is_assessment) => {
    state.is_assessment = is_assessment;
  },
  updateWelcomeModalViewed: (state, value) => {
    if (state.user) {
      state.user.welcome_modal_viewed = value;
    }
  },
  logoutUser: (state) => {
    state.user = null;
    state.token = null; // Clear token on logout
  },
  setToken: (state, token) => {
    state.token = token;
  },
};

export const actions = {
  ['set_user'](context, user) {
    context.commit('updateUser', user);
  },

  // Attempt to fetch the authenticated user
  ['attempt_user'](context) {
    return sendGet('/api/me')
      .then((data) => {
        context.commit('updateUser', data.data);
        return data;
      })
      .catch((error) => {
        context.commit('updateUser', null);
        throw error;
      });
  },

  // Login action (supports both web and native apps)
  ['login'](context, credentials) {
    if (Capacitor.isNativePlatform()) {
      return sendPost('/api/login', credentials)
        .then((data) => {
          const { token } = data;
          if (token) {
            context.commit('setToken', token);
            localStorage.setItem('auth_token', token);
          }
          return context.dispatch('attempt_user');
        })
        .catch((error) => {
          throw error;
        });
    }

    return sendPost('/login', credentials)
      .then(() => {
        return context.dispatch('attempt_user');
      })
      .catch((error) => {
        throw error;
      });
  },

  ['logout'](context) {
    if (Capacitor.isNativePlatform()) {
      return sendPost('/api/logout')
        .then(() => {
          context.commit('logoutUser');
          localStorage.removeItem('auth_token');
        })
        .catch((error) => {
          console.error(error);
          throw error;
        });
    }

    return sendPost('/logout')
      .then(() => {
        context.commit('logoutUser');
      })
      .catch((error) => {
        console.error(error);
        throw error;
      });
  },

  ['set_user_id'](context, user_id) {
    context.commit('updateUserId', user_id);
  },
  ['set_is_assessment'](context, is_assessment) {
    context.commit('updateAssessment', is_assessment);
  },
  ['set_welcome_modal_viewed'](context, value) {
    context.commit('updateWelcomeModalViewed', value);
  },
};

export const getters = {
  ['user'](state) {
    return state.user;
  },
  ['user_id'](state) {
    return state.user_id;
  },
  ['is_assessment'](state) {
    return state.is_assessment;
  },
  ['token'](state) {
    return state.token;
  },
};

export default {
  state,
  actions,
  mutations,
  getters,
};
