import axios from "axios";

export const state = {
  thoughts: [],
};

export const mutations = {
  setThoughts: (state, thoughts) => (state.thoughts = thoughts),
  addThought: (state, thought) => state.thoughts.push(thought),
  removeThought: (state, id) =>
    (state.thoughts = state.thoughts.filter((t) => t.id !== id)),
};

export const actions = {
  // Fetch all thoughts
  async fetchThoughts({ commit }) {
    try {
      const response = await axios.get("/api/thoughts");
      commit("setThoughts", response.data.thoughts);
    } catch (error) {
      alert("Failed to fetch thoughts.");
      console.error(error);
    }
  },

  // Add a new thought
  async addThought({ commit }, thought) {
    try {
      const response = await axios.post("/api/thoughts", thought);
      commit("addThought", response.data.thought); // Assuming the response contains the newly created thought
    } catch (error) {
      alert("Failed to add thought.");
      console.error(error);
    }
  },

  // Update an existing thought
  async updateThought({ commit, state }, updatedThought) {
    try {
      const response = await axios.put(
        `/api/thoughts/${updatedThought.id}`,
        updatedThought
      );
    
      // Find the index of the thought being updated
      const index = state.thoughts.findIndex((thought) => thought.id === updatedThought.id);
    
      if (index !== -1) {
        // Replace the updated thought in the array
        state.thoughts[index] = response.data.thought;
      }

      // Alternatively, commit a mutation that updates the thought directly
      // commit('updateThought', response.data.thought); // if you have an 'updateThought' mutation

    } catch (error) {
      alert("Failed to update thought.");
      console.error(error);
    }
  },

  // Delete a thought
  async deleteThought({ commit }, id) {
    try {
      await axios.delete(`/api/thoughts/${id}`);
      commit("removeThought", id);
    } catch (error) {
      alert("Failed to delete thought.");
      console.error(error);
    }
  },
};

export const getters = {
  getThoughts(state) {
    return state.thoughts;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};