<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Lifeslices List -->
      <ion-list lines="full" v-if="getLifeslices.length > 0" class="my-8">
        <ion-item v-for="lifeslice in getLifeslices" :key="lifeslice.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ lifeslice.lifeslice }}</h3>
            <p class="text-muted">Category: {{ lifeslice.category }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditLifesliceModal(lifeslice)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteLifeslice(lifeslice.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No lifeslices have been recorded yet." />
      </div>

      <!-- Add Lifeslice Button -->
      <ion-button expand="block" @click="openAddLifesliceModal">Add Lifeslice</ion-button>

      <!-- Add/Edit Lifeslice Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Lifeslice' : 'Add Lifeslice' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitLifeslice">
            <div class="ion-margin-bottom">
              <ion-label>Lifeslice</ion-label>
              <ion-input v-model="newLifeslice.lifeslice" placeholder="Enter lifeslice" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Category</ion-label>
              <ion-select v-model="newLifeslice.category" placeholder="Select category" required>
                <ion-select-option v-for="category in categories" :key="category" :value="category">
                  {{ category }}
                </ion-select-option>
              </ion-select>
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Lifeslice' : 'Add Lifeslice' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentLifesliceId = ref(null);
const newLifeslice = ref({ lifeslice: '', category: '' });
const categories = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch lifeslices from Vuex store
const getLifeslices = computed(() => store.getters['lifeslices/getLifeslices'].slice().reverse());

// Fetch lifeslices on mount
onMounted(() => {
  store.dispatch('lifeslices/fetchLifeslices');
});

// Open modal for adding a new lifeslice
const openAddLifesliceModal = () => {
  isEditing.value = false;
  newLifeslice.value = { lifeslice: '', category: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing lifeslice
const openEditLifesliceModal = (lifeslice) => {
  isEditing.value = true;
  newLifeslice.value = { lifeslice: lifeslice.lifeslice, category: lifeslice.category };
  currentLifesliceId.value = lifeslice.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newLifeslice.value = { lifeslice: '', category: '' }; // Reset form
  currentLifesliceId.value = null;
};

// Add or update a lifeslice
const submitLifeslice = () => {
  if (newLifeslice.value.lifeslice && newLifeslice.value.category) {
    if (isEditing.value) {
      store.dispatch('lifeslices/updateLifeslice', { id: currentLifesliceId.value, ...newLifeslice.value });
    } else {
      store.dispatch('lifeslices/addLifeslice', { ...newLifeslice.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete a lifeslice
const deleteLifeslice = (id) => {
  store.dispatch('lifeslices/deleteLifeslice', id);
};
</script>

<style scoped>
ion-label {
  font-size: 18px;
  color: #333;
}

ion-button {
  --border-radius: 5px;
  --height: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

li .ion-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --height: 35px;
  --width: 35px;
}

li .ion-button .ion-icon {
  --color: white;
}
</style>