<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Sensations List -->
      <ion-list lines="full" v-if="getSensations.length > 0" class="my-8">
        <ion-item v-for="sensation in getSensations" :key="sensation.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ sensation.sensation }}</h3>
            <p class="text-muted">Category: {{ sensation.category }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditSensationModal(sensation)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteSensation(sensation.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No sensations have been recorded yet." />
      </div>

      <!-- Add Sensation Button -->
      <ion-button expand="block" @click="openAddSensationModal">Add Sensation</ion-button>

      <!-- Add/Edit Sensation Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Sensation' : 'Add Sensation' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitSensation">
            <div class="ion-margin-bottom">
              <ion-label>Sensation</ion-label>
              <ion-input v-model="newSensation.sensation" placeholder="Enter sensation" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Category</ion-label>
              <ion-select v-model="newSensation.category" placeholder="Select category" required>
                <ion-select-option v-for="category in categories" :key="category" :value="category">
                  {{ category }}
                </ion-select-option>
              </ion-select>
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Sensation' : 'Add Sensation' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentSensationId = ref(null);
const newSensation = ref({ sensation: '', category: '' });
const categories = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch sensations from Vuex store
const getSensations = computed(() => store.getters['sensations/getSensations'].slice().reverse());

// Fetch sensations on mount
onMounted(() => {
  store.dispatch('sensations/fetchSensations');
});

// Open modal for adding a new sensation
const openAddSensationModal = () => {
  isEditing.value = false;
  newSensation.value = { sensation: '', category: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing sensation
const openEditSensationModal = (sensation) => {
  isEditing.value = true;
  newSensation.value = { sensation: sensation.sensation, category: sensation.category };
  currentSensationId.value = sensation.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newSensation.value = { sensation: '', category: '' }; // Reset form
  currentSensationId.value = null;
};

// Add or update a sensation
const submitSensation = () => {
  if (newSensation.value.sensation && newSensation.value.category) {
    if (isEditing.value) {
      store.dispatch('sensations/updateSensation', { id: currentSensationId.value, ...newSensation.value });
    } else {
      store.dispatch('sensations/addSensation', { ...newSensation.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete a sensation
const deleteSensation = (id) => {
  store.dispatch('sensations/deleteSensation', id);
};
</script>

<style scoped>
ion-label {
  font-size: 18px;
  color: #333;
}

ion-button {
  --border-radius: 5px;
  --height: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

li .ion-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --height: 35px;
  --width: 35px;
}

li .ion-button .ion-icon {
  --color: white;
}
</style>