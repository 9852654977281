<template>

<ion-page>

<!-- Modals -->
<InformationModal ref="informationModal" />
<ActivityFinishQuestionnaire ref="finishModal" />
<ActivityFinishQuestionnaireGroup ref="finishGroupModal" :title="groupTitle" :slug="slug" />

<!-- Header -->
<ion-header>
  <div class="">
    <!-- Background Image -->
    <img class="object-cover w-full h-24" :src="image" alt="Header Background" />
    <div class="absolute inset-0 bg-questionnaire-600 opacity-80"></div>

    <!-- Back Button and Title -->
    <div class="absolute px-4 flex items-center top-6">
      <ion-button fill="clear" @click="goBack" class="ion-no-padding">
       
        <ion-icon :icon="chevronBackOutline" class="text-questionnaire bg-questionnaire-100 rounded-full"></ion-icon>
      </ion-button>
      <h2 class="ml-3 text-lg font-medium text-white">{{ title }}</h2>
    </div>
  </div>

  <!-- Progress Bar -->
  <div class="h-2 w-full bg-gray-100">
    <div class="h-2 bg-primary-500 transition-all duration-300" :style="{ width: percentage }"></div>
  </div>
</ion-header>

<!-- Content -->
<ion-content class="ion-padding">
  <div v-if="question">
    <!-- Question Card -->
    <ion-card class="ion-no-margin ion-padding">
      <ion-card-header>
        <ion-card-title>
          {{ questionIndex + 1 }}. {{ question.title }}
          <span v-if="question.required">*</span>
        </ion-card-title>
      </ion-card-header>
      <ion-card-content>
        <!-- Answer Options -->
        <ion-radio-group v-model="selected">
          <ion-item
            v-for="(option, index) in answerOptions"
            :key="'answer-option-' + index"
            class="ion-margin-bottom"
            :class="{ 'selected-option': option.order === selected }"
            lines="full"
            @click="select(index)"
          >
            <ion-radio slot="start" :value="option.order"></ion-radio>
            <ion-label>
              <span>{{ getLetterByIndex(index) }}.</span> {{ option.value }}
            </ion-label>
          </ion-item>
        </ion-radio-group>

        <!-- Validation Message -->
        <ion-text v-if="answerIsRequired" color="danger">
          <p>Please provide a response.</p>
        </ion-text>
      </ion-card-content>
    </ion-card>
  </div>
</ion-content>

<!-- Footer -->
<ion-footer class="ion-no-border">
  <ion-toolbar class="bg-questionnaire-100 h-16 standalone:h-20 ion-padding-horizontal">
    <div class="flex justify-between items-center">
      <!-- Previous Button -->
      <ion-button
        fill="clear"
        :disabled="questionIndex === 0"
        @click="previousQuestionHandler"
      >
        <ion-icon :icon="arrowBackOutline"></ion-icon>
      </ion-button>

      <!-- Progress -->
      <p class="text-center text-sm font-medium text-questionnaire">
        {{ questionIndex + 1 }}/{{ questions.length }}
      </p>

      <!-- Next/Finish Button -->
      <ion-button
        fill="clear"
        @click="nextQuestionHandler"
      >
        <span v-if="showFinishButton">{{ nextQuestionnaire ? 'NEXT' : 'FINISH' }}</span>
        
     
        <ion-icon
          v-if="questionIndex < questions.length - 1"
          :icon="arrowForwardOutline"
          
        ></ion-icon>
      </ion-button>
    </div>
  </ion-toolbar>
</ion-footer>

</ion-page>
</template>


<script setup>

 import { chevronBackOutline, arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
 import { ChevronLeftIcon, ChevronRightIcon, HomeIcon, ArrowLeftIcon, ArrowRightIcon } from '@heroicons/vue/24/outline'
 import ActivityFinishQuestionnaire from '../components/ActivityFinishQuestionnaire';
 import ActivityFinishQuestionnaireGroup from '../components/ActivityFinishQuestionnaireGroup';
 import { useRouter } from 'vue-router';
 import { sendPost } from '../components/entities/functions.js';
 import InformationModal from '../components/InformationModal';

 import { 
  IonPage, 
  IonContent, 
  IonRow, 
  IonGrid, 
  IonRadio,
  IonRadioGroup,
  IonCol,
  IonIcon,
  IonHeader, 
  IonFooter, 
  IonToolbar, 
  IonCard,
  IonCardTitle,
  IonCardHeader,  
  IonCardContent, 
  IonText, 
  IonButton, 
  IonItem, 
  IonCheckbox, 
  IonLabel, 
  IonInput, 
  IonImg 
  } from '@ionic/vue';

const router = useRouter()

    function goBack() {
        return router.go(-1)
}

  </script>

<script>
export default {
  data() {
    return {
      title: '',
      instructions: '',
      image: '',
      answerOptions: [],
      questions: [],
      isGroup: false,
      nextQuestionnaire: null,
      slug: null,
      groupTitle: null,

      questionAnswers: {},
      selected: null,
      questionIndex: 0,
      answerIsRequired: false,
      isDataBeenSent: false,
      userId: null,
      isAssessment: false,

      informationModalActions: {
        closeModal: null,
        openModal: null
      },
      questionnaireFinishActions: {
        closeModal: null,
        openModal: null
      },
      questionnaireGroupFinishActions: {
        closeModal: null,
        openModal: null
      },
    };
  },

  mounted() {
    this.userId = this.$store.getters['user_id'];
    this.isAssessment = this.$store.getters['is_assessment'];
    this.getQuestions();
    window.addEventListener('resize', this.adjustQuestionnaireContainerHeight);
  },

  beforeUnmount() {
      window.removeEventListener('resize', this.adjustQuestionnaireContainerHeight);
   },

  provide: function() {
    return {
      informationModalActions: this.informationModalActions,
      questionnaireFinishActions: this.questionnaireFinishActions,
      questionnaireGroupFinishActions: this.questionnaireGroupFinishActions
    };
  },

  watch: {
    instanceid() {
      this.getQuestions();
      if (this.userId) {
        localStorage.isQuestionnaireCompleted = '';
      }
    }
  },

  computed: {
    instanceid() {
      return this.$route.params.questionnaireinstanceid;
    },
    question() {
      if (!this.questions || this.questions.length == 0) {
        return null;
      }
      return this.questions[this.questionIndex];
    },
    percentage() {
      const percentage = ((this.questionIndex + 1) / this.questions.length) * 100;
      return Math.round(percentage) + '%';
    },
    questionsAnswered() {
      const count = Object.keys(this.questionAnswers).length;
      return count;
    },
    showFinishButton() {
      return this.questionsAnswered === this.questions.length && this.questionIndex === this.questions.length - 1;
    }
  },

  methods: {
    async getQuestions() {
      this.questionIndex = 0;
      this.selected = null;
      this.isDataBeenSent = false;
      this.questionAnswers = {};

      const response = await sendPost('/api/content/questionnaire-instance/' + this.instanceid + '/begin', {'user_id': this.userId});
      if (response) {
        if (response.unregistered) {
          this.$router.go(-1);
          return;
        }

        this.title = response.title;
        this.instructions = response.instructions;
        this.image = response.image;
        this.questions = response.questions;
        this.answerOptions = response.answerOptions;
        this.isGroup = response.isGroup;
        this.nextQuestionnaire = response.nextQuestionnaire;
        this.slug = response.slug;
        this.groupTitle = response.groupTitle;
        this.openModal(this.title, this.instructions);
      }
    },

    select(index) {
      this.answerIsRequired = false;
      if (this.selected == this.answerOptions[index].order) {
        delete this.questionAnswers[String(this.question.id)];
        this.selected = null;
        return;
      }
      this.selected = this.answerOptions[index].order;
      this.questionAnswers[String(this.question.id)] = this.selected;
      if (this.questionIndex < this.questions.length - 1) {
        this.nextQuestionHandler();
      }
    },

    getLetterByIndex(index) {
      const charCode = index + 65;
      const letter = String.fromCharCode(charCode);
      return letter;
    },

    previousQuestionHandler() {
      if (this.questionIndex - 1 < 0) {
        return;
      }
      this.answerIsRequired = false;
      this.questionIndex--;
      this.selected = this.questionAnswers[String(this.question.id)];
    },

    async nextQuestionHandler() {
      if (this.question.required && this.selected === null) {
        this.answerIsRequired = true;
        return;
      }

      if (this.questionIndex + 1 < this.questions.length) {
        this.selected = null;
        this.questionIndex++;
      } else {
        if (!this.isGroup) {
          this.setFinishModalState(true);
        } else if (!this.nextQuestionnaire) {
          localStorage.isQuestionnaireCompleted = 1;
          this.setFinishGroupModalState(true);
        }

        if (!this.isDataBeenSent) {
          this.isDataBeenSent = true;
          let isLast = this.isGroup && !this.nextQuestionnaire;
          await sendPost('/api/content/questionnaire-instance/' + this.instanceid + '/finish', { answers: this.questionAnswers, user_id: this.userId, last_questionnaire: isLast });
        }

        if (this.nextQuestionnaire) {
          if (!this.userId) {
            this.$router.push({ name: 'ActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: this.nextQuestionnaire } });
          } else {
            this.$store.dispatch('set_user_id', this.userId);
            this.$router.push({ name: 'RegisterActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: this.nextQuestionnaire } });
          }
        }

        return;
      }

      if (this.questionAnswers[String(this.question.id)] !== undefined) {
        this.selected = this.questionAnswers[String(this.question.id)];
      }
    },

    adjustQuestionnaireContainerHeight() {
      if (this.questions.length == 0 || true) {
        return;
      }
      const questionnaireContainer = this.$refs.questionnaireContainer;
      const offsetTop = questionnaireContainer.offsetTop;
      questionnaireContainer.style.height = `calc(100vh - ${offsetTop}px)`;
    },

    openModal(title, text) {
      if (title && text) {
        this.informationModalActions.openModal(title, text);
      }
    },

    closeModal() {
      this.informationModalActions.openModal();
    },

    setFinishModalState(state) {
      if (state && this.questionnaireFinishActions.openModal) {
        this.questionnaireFinishActions.openModal();
      }
      if (!state && this.questionnaireFinishActions.closeModal) {
        this.questionnaireFinishActions.closeModal();
      }
    },

    setFinishGroupModalState(state) {
      if (state && this.questionnaireGroupFinishActions.openModal) {
        this.questionnaireGroupFinishActions.openModal(this.userId, this.isAssessment);
      }
      if (!state && this.questionnaireGroupFinishActions.closeModal) {
        this.questionnaireGroupFinishActions.closeModal();
      }
    },
  }
};
</script>
