import axios from "axios";

export const state = {
  parentstyles: [],
};

export const mutations = {
  setParentstyles: (state, parentstyles) => (state.parentstyles = parentstyles),
  addParentstyle: (state, parentstyle) => state.parentstyles.push(parentstyle),
  removeParentstyle: (state, id) =>
    (state.parentstyles = state.parentstyles.filter((p) => p.id !== id)),
};

export const actions = {
  // Fetch all parentstyles
  async fetchParentstyles({ commit }) {
    try {
      const response = await axios.get("/api/parentstyles");
      commit("setParentstyles", response.data.parentstyles);
    } catch (error) {
      alert("Failed to fetch parentstyles.");
      console.error(error);
    }
  },

  // Add a new parentstyle
  async addParentstyle({ commit }, parentstyle) {
    try {
      const response = await axios.post("/api/parentstyles", parentstyle);
      commit("addParentstyle", response.data.parentstyle); // Assuming the response contains the newly created parentstyle
    } catch (error) {
      alert("Failed to add parentstyle.");
      console.error(error);
    }
  },

  // Update an existing parentstyle
  async updateParentstyle({ commit, state }, updatedParentstyle) {
    try {
      const response = await axios.put(
        `/api/parentstyles/${updatedParentstyle.id}`,
        updatedParentstyle
      );
    
      // Find the index of the parentstyle being updated
      const index = state.parentstyles.findIndex((parentstyle) => parentstyle.id === updatedParentstyle.id);
    
      if (index !== -1) {
        // Replace the updated parentstyle in the array
        state.parentstyles[index] = response.data.parentstyle;
      }

      // Alternatively, commit a mutation that updates the parentstyle directly
      // commit('updateParentstyle', response.data.parentstyle); // if you have an 'updateParentstyle' mutation

    } catch (error) {
      alert("Failed to update parentstyle.");
      console.error(error);
    }
  },

  // Delete a parentstyle
  async deleteParentstyle({ commit }, id) {
    try {
      await axios.delete(`/api/parentstyles/${id}`);
      commit("removeParentstyle", id);
    } catch (error) {
      alert("Failed to delete parentstyle.");
      console.error(error);
    }
  },
};

export const getters = {
  getParentstyles(state) {
    return state.parentstyles;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};