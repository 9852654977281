<template>
  <div>

<!-- SECTION-Hero -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />

    <section class="relative bg-white">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div class="px-6 pb-4 pt-4 sm:pb-20 lg:col-span-7 lg:px-0 lg:pb-40 lg:pt-20 xl:col-span-6">
                <div class="mx-auto max-w-2xl lg:mx-0">
                  <h1 class="text-center sm:text-left mt-4 sm:mt-8 text-4xl font-bold text-gray-900 sm:text-5xl"
    data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">
    <span class="block sm:inline">Personalised Mental</span>
    <span class="block sm:inline"> Health Support</span>
    <span class=""> Just for You</span>
</h1>
                    <p class="text-pretty text-center sm:text-left mt-4 text-lg text-gray-600 sm:text-xl"
                    data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">"Whether you're seeking ongoing weekly therapy, a structured program or a one-off MOT, I'm here to help you find the right fit for you."</p>
                    <div class="mt-8 sm:mt-10 flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
          <!-- Button1 -->
          <a href="/register/overview" class="w-56 mx-auto sm:mx-0 text-center rounded-full bg-primary px-4 py-3 text-lg font-medium tracking-wide text-white shadow-sm hover:bg-primary-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-600"
          data-aos="flip-up" data-aos-once="true" data-aos-duration="1000">
            Get Started  
          </a>
        </div>
                </div>
            </div>
            <div class="pt-4 pb-8 sm:pt-8 relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                <div class="py-8 px-8 sm:p-16 relative w-full h-0" style="padding-bottom:40%;">
                    <img class="absolute inset-0 w-full h-full object-cover object-center" data-aos="slide-right" data-aos-once="true" data-aos-duration="1000" src="/img/website/landing-hero-individual.jpg" alt="" />
                </div>
            </div>
        </div>
    </section>

    <img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
<!-- SECTION-Introduction Video -->
<section class="px-6 sm:px-6 max-w-7xl mx-auto pt-8 pb-8 sm:pt-8 sm:pb-20">
  <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
    <!-- Left side (Video) -->
    <div class="flex justify-center items-center">
      <video class="w-full sm:max-w-md rounded-xl shadow-lg mb-8" controls playsinline preload="metadata" 
        :poster="shouldUsePoster ? '/img/website/video-poster-me-1.jpg' : null">
        <source src="/img/website/landing-video-1.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
    <!-- Right side (TypingEffect and Text) -->
    <div class="flex flex-col justify-center">
      <TypingEffect class="py-2 sm:py-8" />
      <div class="max-w-3xl rounded-2xl py-2 sm:py-4 sm:pr-24">
        <p class="text-left ws-text-body-base text-gray-700">
          "With Youlio, I’m committed to guiding you on your unique journey to better mental health. Each person's path is different, and that's why I offer three tailored options to best support your needs."
          <span class="text-xs font-semibold text-primary">(MSc PGDip MBACP GMBPsS)</span>
        </p>
      </div>
    </div>
  </div>
</section>


<!-- SECTION-Three Services --> 
    <section>  

    <BlockThreeCards
  :card1="{
    icon: VideoCameraIcon, 
    title: 'Weekly Therapy', 
    description: 'Ongoing one-on-one sessions for consistent support and guidance. Ideal for those who want regular check-ins and a safe space to work through challenges.', 
    link: '/individuals/weekly-therapy', 
    ctaText: 'Learn More', 
    bgColor: 'primary-100'
  }"
  :card2="{
    icon: MapIcon, 
    title: 'Mental Health Programs', 
    description: 'Structured programs designed to address specific challenges like anxiety or low-self-esteem. Get a tailored plan and regular support to make meaningful progress.', 
    link: '/individuals/mental-health-programs', 
    ctaText: 'Learn More', 
    bgColor: 'gray-100'
  }"
  :card3="{
    icon: WrenchScrewdriverIcon, 
    title: 'Wellbeing MOT', 
    description: 'Questionnaires and readings followed by an in-depth extended session to explore your mental health and uncover immediate, actionable steps. ', 
    link: '/individuals/mental-health-mot', 
    ctaText: 'Learn More', 
    bgColor: 'secondary-100'
  }"
    />
    </section>  
         
<!-- SECTION-How Youlio Supports Your Journey -->
    <img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />
      <BlockSwiper
        :slides="4"
        overline="Enhanced Care"
        headline="What is Youlio?"
        subheadline="Youlio enables me to provide therapy-driven mental health support in a more effective and connected way. The platform integrates convenient tools and resources to streamline our sessions, track your progress, and offer continuous support."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Smooth Sailing Sessions"
        subheadline="Youlio makes therapy sessions a breeze! The platform handles all of our sessions, making it super easy for us to connect."
        imageSrc="/img/website/landing-features-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Messaging"
        subheadline="You can reach out to me anytime with our messaging feature, so you're never alone on your journey."
        imageSrc="/img/website/landing-features-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Personalised Support"
        subheadline="With Youlio, I can send you readings, questionnaires, and activities that are completely tailored towards you."
        imageSrc="/img/website/landing-features-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Progress Tracking"
        subheadline="Youlio enables us to monitor your journey, keeping us focused on the areas that matter most."
        imageSrc="/img/website/landing-features-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
      </BlockSwiper>

      <!-- SECTION-Testimonials--> 
      <BlockTestimonial
  :testimonials="[
    {
      text: 'My Therapy sessions with Tom have been life-changing. His empathic approach and practical advice have helped me navigate through my anxiety and build a healthier mindset. Highly recommend!',
      name: 'Daniel',
      age: 23
    },
    {
    text: 'Tom’s program helped me create lasting changes in my mental wellbeing. The practical strategies were so valuable to me. Highly recommended!',
    name: 'Emily',
    age: 27
  },
    {
      text: 'I love the Youlio platform, Tom and his genuine care have guided me through some tough times, helping me develop healthier coping mechanisms and a more positive outlook on life.',
      name: 'Michael',
      age: 45
    }
  ]"
  sectionBgColor="bg-primary-600"
/>
<!-- SECTION-How I Support Your Journey -->
    <BlockContent
    overline="Here For You"
    headline="Who Am I?"
    subheadline="As a licensed therapist with extensive experience, I’m dedicated to supporting you throughout your journey with Youlio. I offer personalised guidance and expertise, ensuring that you receive tailored care in every 1:1 session. My goal is to be a constant, supportive presence, helping you navigate challenges and celebrate progress."
    secondaryCtaText="Learn More"
    secondaryCtaLink="/approach"
    imageSrc="/img/website/me-4.jpg"
    bgClass="bg-white"
    theme="light"
    layout="image-right"
    />


<!-- SECTION-CTA -->
<Wave colorClass="fill-white" shadow position="down" /> 
    <BlockCTA    
      heading="Ready to Make a Change?"
      message="Taking the first step towards better mental health can be transformative. Reach out today and start your path to a better you."
      primaryButtonText="Get Started"
      primaryButtonLink="/register/overview"
      secondaryButtonText="Contact Me"
      secondaryButtonLink="/contact"
      imageSrc="/img/website/for-individuals-cta.jpg"
      bgClass="bg-white"
      theme="light"
    />

  </div>
</template>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockThreeCards from '../../website/components/BlockThreeCards' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { VideoCameraIcon, WrenchScrewdriverIcon, MapIcon, KeyIcon, CalendarIcon, CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid'   
import TypingEffect from '../../website/components/TypingEffect' ;

</script>



<script>
export default {
  data() {
    return {
      shouldUsePoster: true,
      dropdownVisible: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.dropdownVisible = !this.dropdownVisible;
    },
    handleClickOutside(event) {
      if (
        this.$refs.dropdownMenu &&
        !this.$refs.dropdownMenu.contains(event.target) &&
        !this.$refs.dropdownButton.contains(event.target)
      ) {
        this.dropdownVisible = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
/* Fade animation */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s ease;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
