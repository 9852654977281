import axios from "axios";

export const state = {
  barriers: []
};

export const mutations = {
  set_barriers: (state, barriers) => {
    state.barriers = barriers;
  }
};

export const actions = {
  async fetchBarriers({ commit }) {
    try {
      const response = await axios.get("/api/me/barriers");
      commit("set_barriers", response.data.barriers);
    } catch (error) {
      console.error("Error fetching barriers:", error);
      alert(error);
    }
  }
};

export const getters = {
  getBarriers(state) {
    return state.barriers;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};