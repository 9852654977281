<template>

  <loading-overlay :loading="loading"></loading-overlay>
  <div v-if="!loading" class="my-4 md:my-24 overflow-x-hidden">


<!-- Disclosure - Deep Dive -->
    <div class="mb-4 bg-gradient-to-r from-primary-200 to-secondary-200 p-4 max-w-6xl">
    <div>
     
        <div class="text-secondary-600 w-full text-left font-semibold">
          <h3>Deep Dive</h3>
        </div>
        <div>
          <div class="mt-4">
            <div v-if="Object.keys(deepDive).length">
              <deep-dive-values
                v-for="deepDiveValue in deepDive"
                :key="deepDiveValue.id"
                :data="deepDiveValue"
              />
            </div>
            <div v-else class="card-no-entries">
              <p>There are currently no entries.</p>
            </div>
          </div>
        </div>
    
    </div>
  </div>
  </div>


</template>

<script setup>
  import { ref } from 'vue'
  import { AdjustmentsVerticalIcon, ChevronLeftIcon } from '@heroicons/vue/24/outline';
  import { IonPage, IonContent } from '@ionic/vue';
  import Header from '../components/Header';
  import { sendPost } from './entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import PsychologicalHealthFactorHistory from '../components/PsychologicalHealthFactorHistory';
  import DeepDiveValues from '../components/DeepDiveValues';


</script>

<script>
  export default {
    data: function () {
      return {
        client: {},
        loading: false,
        collections: {},
        deepDive: {},
        spotlightsTodo: {},
        spotlightsArchive: {},
        activitiesTodo: [],
        activitiesArchive: [],
        activityTypes: ['questionnaires', 'worksheets', 'readings'],
        activitiesTodoFilters: ['questionnaires', 'worksheets', 'readings'],
        activitiesArchiveFilters: ['questionnaires', 'worksheets', 'readings'],
        showToDoMenu: false,
        showArchiveMenu: false,
        note: [], // Initialize as an empty array
      }
    },

    computed: {
      client_id() {
        return this.$route.params.clientId
      },
      totalTodoRecords() {
        let total = 0;
        if (this.activitiesTodo) {
          if (this.activitiesTodo.questionnaire_instances) {
            total += this.activitiesTodo.questionnaire_instances.length;
          }
          if (this.activitiesTodo.reading_instances) {
            total += this.activitiesTodo.reading_instances.length;
          }
          if (this.activitiesTodo.worksheet_instances) {
            total += this.activitiesTodo.worksheet_instances.length;
          }
        }
        return total;
      },
      totalArchiveRecords() {
        let total = 0;
        if (this.activitiesArchive) {
          if (this.activitiesArchive.questionnaire_instances) {
            total += this.activitiesArchive.questionnaire_instances.length;
          }
          if (this.activitiesArchive.reading_instances) {
            total += this.activitiesArchive.reading_instances.length;
          }
          if (this.activitiesArchive.worksheet_instances) {
            total += this.activitiesArchive.worksheet_instances.length;
          }
        }
        return total;
      },
      reversedNotes() {
      // Return a reversed copy of the notes array
      return this.client.note ? this.client.note.slice().reverse() : [];
    }
    },

    watch: {
      activitiesTodoFilters() {
        this.getClientInfo();
      },
      activitiesArchiveFilters() {
        this.getClientInfo();
      },
    },

    mounted() {
      this.getClientInfo();
    },

    methods: {
      async getClientInfo() {
        this.loading = true;
        const responce = await sendPost('/api/get/client-info', {
            client_id: this.client_id,
            activities_todo_types: this.activitiesTodoFilters,
            activities_archive_types: this.activitiesArchiveFilters
          });
          if (responce) {
            this.client = responce.client;
            this.collections = responce.collections;
            this.deepDive = responce.deep_dive;
            this.spotlightsTodo = responce.spotlights?.todo;
            this.spotlightsArchive = responce.spotlights?.archive;
            this.activitiesTodo = responce.activities?.todo;
            this.activitiesArchive = responce.activities?.archive;
          }
          this.loading = false;
      },

      toggleMenu(type) {
        if (type == 'todo') {
          this.showToDoMenu = !this.showToDoMenu;
        } else {
          this.showArchiveMenu = !this.showArchiveMenu;
        }
      },

      capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
      }
    }
  }
</script>
