<template>
  
    <ion-grid v-for="(spotlight, index) in spotlights" :key="spotlight.id" >
        <ion-card :routerLink="{ name: 'Spotlight', params: { spotlightid: spotlight.id } }" >    
          <img alt="spotlight image" src="/img/card-test.jpg" />
            <ion-card-header class="absolute top-0 right-0 p-4">
      
              <ion-chip fill="solid" style="background-color: white; color: var(--ion-color-dark); font-size: 12px; font-weight: 600;">
                {{ spotlight.status }}
              </ion-chip>
                  
          </ion-card-header>
          <ion-card-header>
            <ion-card-title class="line-clamp-2" style="font-size: 18px;">{{ spotlight.spotlight_question }}</ion-card-title>
            <ion-card-subtitle style="color: var(--ion-color-primary);">
              Spotlight
            </ion-card-subtitle>    
            </ion-card-header>

             <ion-button
            v-if="isShowAnswer && spotlight.status === 'Complete'"
            fill="outline"
            size="small"
            class="show-answer-btn"
            @click="openModal(spotlight)">
            Show Answer
          </ion-button>
        </ion-card>
      </ion-grid>


    <SpotlightAnswerModal
      v-if="modalVisible"
      :isOpen="modalVisible"
      :spotlightQuestion="activeSpotlight.spotlight_question"
      :spotlightAnswer="activeSpotlight.spotlight_answer"
      @close="handleCloseModal"
    />
 
</template>

<script setup>
import { ref } from 'vue';
import { IonCardTitle, IonCardSubtitle, IonGrid, IonRow, IonChip, IonCol, IonCard, IonImg, IonLabel, IonCardContent, IonButton, IonCardHeader } from '@ionic/vue';
import SpotlightAnswerModal from './SpotlightAnswerModal.vue';

defineProps({
  spotlights: Array,
  isInteractive: {
    type: Boolean,
    default: true
  },
  isShowAnswer: {
    type: Boolean,
    default: false
  }
});

const modalVisible = ref(false);
const activeSpotlight = ref({});

const openModal = (spotlight) => {
  activeSpotlight.value = spotlight;
  modalVisible.value = true;
};

const handleCloseModal = () => {
  console.log('Closing modal');
  modalVisible.value = false;
};
</script>