<template>

      <!-- Spotlights Section -->
      <div class="pt-4 text-secondary-600 w-full text-lg text-left font-semibold">
        <h3>Spotlights</h3>
      </div>

      <div class="mt-4">
        <!-- To Do Section -->
        <div class="mb-2 flex items-center text-page-subtitle-dark">To Do</div>
        <div v-if="spotlightsTodo.length" class="grid md:grid-cols-2">
          <spotlight-card :spotlights="spotlightsTodo" :is-interactive="false" />
        </div>
        <p v-else class="card-no-entries">
          There are currently no 'Spotlights' in ToDo list.
        </p>

        <!-- Archive Section -->
        <div v-if="spotlightsArchive.length">
          <div class="flex justify-between mt-12">
            <div class="mb-2 flex items-center text-page-subtitle-dark">Archive</div>
          </div>
          <div class="grid md:grid-cols-2">
            <spotlight-card
              :spotlights="spotlightsArchive"
              :is-interactive="false"
              :is-show-answer="true"
            />
          </div>
        </div>
      </div>


</template>

<script setup>
  import { ref, computed, watch, onMounted } from 'vue'
  import { useRoute } from 'vue-router'
  import { IonPage, IonContent } from '@ionic/vue'
  import Header from '../components/Header'
  import { sendPost } from './entities/functions.js'
  import SpotlightCard from '../components/SpotlightCard'

  // Define reactive state variables
  const client = ref({})
  const spotlightsTodo = ref([])
  const spotlightsArchive = ref([])

  const activitiesTodoFilters = ref(['questionnaires', 'worksheets', 'readings'])
  const activitiesArchiveFilters = ref(['questionnaires', 'worksheets', 'readings'])

  const route = useRoute()
  const client_id = computed(() => route.params.clientId)

  // Computed properties
  const totalTodoRecords = computed(() => calculateTotalRecords(spotlightsTodo.value))
  const totalArchiveRecords = computed(() => calculateTotalRecords(spotlightsArchive.value))
  const reversedNotes = computed(() => client.value.note?.slice().reverse() || [])

  // Watchers
  watch([activitiesTodoFilters, activitiesArchiveFilters], getClientInfo)

  // Lifecycle hook
  onMounted(getClientInfo)

  // Methods
  async function getClientInfo() {
    const response = await sendPost('/api/get/client-info', {
      client_id: client_id.value,
      activities_todo_types: activitiesTodoFilters.value,
      activities_archive_types: activitiesArchiveFilters.value
    })
    if (response) {
      client.value = response.client
      spotlightsTodo.value = response.spotlights?.todo || []
      spotlightsArchive.value = response.spotlights?.archive || []
    }
  }

  function calculateTotalRecords(spotlights) {
    return (spotlights || []).reduce((total, spotlight) => total + spotlight.instances?.length || 0, 0)
  }

</script>