<template>

      <spotlight-card v-if="todo.length" :spotlights="todo" />
      <div v-else lines="none">
        <EmptyState message="Oops! It seems your search didn't match any 'Activities' in your ToDo list." />
      </div>
 
</template>

<script setup>

  import { ref, onMounted } from 'vue';
  import SpotlightCard from './SpotlightCard';
  import EmptyState from '../components/EmptyState';
  import { sendPost } from './entities/functions.js';

// Reactive state for todo items
const todo = ref([]);

// Fetch content function
const getContent = async () => {
  const response = await sendPost('/api/me/spotlights', {
    withStatus: ['New'],
    limit: 1,
  });
  todo.value = response.todo;
};

// Fetch data on component mount
  onMounted(() => {
  getContent();
});
</script>
