<template>

  <div v-if="getGoalActions.length > 0">
    <div v-for="(goal, index) in getGoalActions" :key="goal.id">
      <div class="flex bg-secondary-50 py-6 px-6">    
        <p class="modal-goal-number">G{{ index + 1 }} </p>
        <p class="text-display-base">{{ goal.goal.goal }}</p>   
      </div>        
        <div v-for="(action, index) in goal.actions" :key="action.id" class="px-4 flex py-4 mx-2 border-t border-gray-100">            
          <p class="modal-action-number">{{ index + 1 }} </p>
          <p class="text-body-base">{{ action.goal_action }}</p>        
        </div>
      </div>
    </div>
         
</template>

<script setup>
import { ref, computed, onMounted, defineProps } from 'vue';
import { useStore } from 'vuex';

// Define props
const props = defineProps({
  goals: {
    type: Array,
    default: () => []
  },
  actions: {
    type: Array,
    default: () => []
  },
  onClientPage: {
    type: Boolean,
    default: false
  }
});

// Reactive variables for the store
const store = useStore();

// Computed property for grouped goal actions
const getGoalActions = computed(() => {
  let goals = [];
  let goalActions = [];

  if (!props.onClientPage) {
    goals = store.getters.getGoals;
    goalActions = store.getters.getGoalActions;
  } else {
    goals = props.goals;
    goalActions = props.actions;
  }

  // Group goal actions by goal ID
  const groupedActions = goalActions.reduce((acc, action) => {
    if (acc[action.goal_id]) {
      acc[action.goal_id].actions.push(action);
    } else {
      acc[action.goal_id] = {
        id: action.goal_id,
        goal: goals.find((goal) => goal.id === action.goal_id),
        actions: [action],
      };
    }
    return acc;
  }, {});

  // Convert grouped actions into an array
  return Object.values(groupedActions);
});

// Mounted lifecycle hook for dispatching action
onMounted(() => {
  if (!props.onClientPage) {
    store.dispatch('fetchGoalActions');
  }
});
</script>
