<template>
  <div v-if="Object.keys(groupedProblems).length > 0">
    <div v-for="(problems, category) in groupedProblems" :key="category">
      <h4>{{ category }}</h4>
      <div v-for="problem in problems" :key="problem.id" class="modal-list">
        <p class="text-body-cf-tp">{{ problem.problem }}</p>   
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { sendPost } from './entities/functions.js';

// Reactive variables
const client = ref({});
const loading = ref(false);

// Get client ID from route
const route = useRoute();
const clientId = computed(() => route.params.clientId);

// Fetch client info
const fetchClientInfo = async () => {
  loading.value = true;
  try {
    const response = await sendPost('/api/get/client-info', {
      client_id: clientId.value,
    });
    if (response) {
      client.value = response.client || {};
    }
  } catch (error) {
    console.error('Error fetching client info:', error);
  } finally {
    loading.value = false;
  }
};

// Computed property for problems
const getProblems = computed(() => client.value.problem || []);

// Computed property to group problems by category
const groupedProblems = computed(() => {
  const problems = getProblems.value;
  if (problems.length === 0) return {};

  return problems.reduce((acc, problem) => {
    const category = problem.category || 'Uncategorized';
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(problem);
    return acc;
  }, {});
});

// Fetch client info on component mount
onMounted(fetchClientInfo);
</script>