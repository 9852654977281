<template>
 
      <activity-card v-if="totalTodoRecords" :activities="todo"/>
      <div v-else>
        <EmptyState message="Oops! It seems your search didn't match any 'Activities' in your ToDo list." />
      </div>

</template>
  
<script setup>
import { ref, computed, onMounted } from 'vue';
import ActivityCard from '../components/ActivityCard';
import { IonPage, IonContent, IonButton, IonToolbar, IonButtons, IonIcon, IonList, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonChip } from '@ionic/vue';
import { sendPost } from './entities/functions.js';
import EmptyState from '../components/EmptyState';
import { chevronForwardOutline, arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';

// Reactive state
const todo = ref([]);

// Computed property
const totalTodoRecords = computed(() => {
  let total = 0;
  if (todo.value) {
    if (todo.value.questionnaire_instances) {
      total += todo.value.questionnaire_instances.length;
    }
    if (todo.value.reading_instances) {
      total += todo.value.reading_instances.length;
    }
    if (todo.value.worksheet_instances) {
      total += todo.value.worksheet_instances.length;
    }
  }
  return total;
});

// Fetch content when component is mounted
onMounted(async () => {
  await getContent();
});

// Method to fetch content
const getContent = async () => {
  const response = await sendPost('/api/content/activities', {
    todo_types: ['questionnaires', 'worksheets', 'readings'],
    limit: 1
  });
  todo.value = response.todo;
};
</script>
