<template>
  <div class="pt-4 px-6">
    <!-- Expandable Button -->
    <div
      :class="{
        'border border-gray-600 shadow-md bg-secondary-100 rounded-md transition-all duration-300 overflow-hidden':
          true,
       'px-4 py-6': isFormVisible,
        'px-4 py-4': !isFormVisible,
      }"
      @click="toggleFormVisibility"
    >
      <button
        class="flex items-center justify-between w-full text-gray-800 font-semibold transition-colors"
      >
        <span>Email Me</span>
        <!-- Heroicons Chevron -->
        <ChevronDownIcon
          :class="{ 'rotate-180': isFormVisible, 'rotate-0': !isFormVisible }"
          class="w-6 h-6 transform transition-transform duration-300"
        />
      </button>

      <!-- Email Information -->
      
        <div v-if="isFormVisible" class="mt-4">
          <p><a href="mailto:tom@youliohealth.com" class="text-primary-600 hover:underline">tom@youliohealth.com</a></p>
          <p class="pt-8">For general enquiries</p><a href="mailto:hello@youliohealth.com" class="text-primary-600 hover:underline">hello@youliohealth.com</a>
        </div>
      
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { ChevronDownIcon } from '@heroicons/vue/24/outline';

// State to control visibility
const isFormVisible = ref(false);

// Toggle function
const toggleFormVisibility = () => {
  isFormVisible.value = !isFormVisible.value;
};
</script>

<style>

</style>