<template>

 <div v-for="collection in collections" :key="collection.slug">
<!-- Check-In Card -->
  
      <ion-card v-if="collection.until_repeat > 0" style="background-color: white;">
        <ion-card-header>
          <ion-card-title>
            {{ collection.until_repeat }} DAYS <span>until next Check-In</span>
          </ion-card-title>
        </ion-card-header>
      </ion-card>

      <ion-card v-else style="background-color: white;">
  <router-link :to="'collections/' + collection.slug">
      <ion-card-content>
        <h4>Check-In is READY</h4>
      </ion-card-content>

  </router-link>
</ion-card>
   
<ion-card class="bg-white">
    <!-- Questionnaire Section -->
    <div v-for="questionnaire in collection.questionnaires" :key="questionnaire.id">
      <div v-for="(factor, index) in questionnaire.calculated_scores" :key="index">

        <!-- Factor Row -->
        <ion-item>
          <ion-label>
            {{ factor.name }}
            <span v-if="factor.diff" :class="[isBadDirection(factor) ? 'text-red-600' : 'text-emerald-500', 'ml-2']">
              <ion-icon :icon="factor.positive ? arrowUp : arrowDown" />
            </span>
            <span v-if="factor.diff" :class="[isBadDirection(factor) ? 'text-red-600' : 'text-emerald-500', 'font-semibold text-xs']">
              {{ factor.diff }}%
            </span>
          </ion-label>
          <ion-text>
            {{ factor.value }}%
          </ion-text>
        </ion-item>

        <!-- Progress Bar -->
        <ion-item>
          <ion-progress-bar :value="factor.value / 100" color="secondary" style="height: 6px;" />
        </ion-item>
      </div>
    </div>
 
  </ion-card>

  </div>
</template>
  
<script setup>
import { ref, onMounted } from 'vue';
import { sendPost, generateColorCode } from './entities/functions.js';
import { chevronForward, chevronBack, arrowUp, arrowDown } from 'ionicons/icons';

import { 

  IonProgressBar,
  IonIcon,
  IonButton, 
  IonButtons, 
  IonToolbar,
  IonCard,
  IonCardTitle,
  IonCardHeader,  
  IonCardContent, 
  IonText, 
  IonItem, 
  IonLabel, 
} from '@ionic/vue';

// Reactive state for collections
const collections = ref([]);

// Function to fetch data
const getData = async () => {
  const response = await sendPost('/api/collections', { page: 'dashboard' });
  if (response) {
    collections.value = response.collections;
  }
};

// Function to determine factor color
const getFactorColor = (factor) => {
  return factor.color ? factor.color : generateColorCode(factor.name);
};

// Function to determine if a factor is in a "bad" direction
const isBadDirection = (factor) => {
  let state = factor.positive;
  if (factor.is_increase_positive) {
    state = !state;
  }
  return state;
};

// Fetch data on component mount
onMounted(() => {
  getData();
});
</script>