<template>
  <ion-header>
    <ion-toolbar>
      <!-- Custom Back Button -->
      <ion-buttons slot="start">
        <button @click="goBack" class="circle-button">
          <ion-icon :icon="chevronBackOutline" class="icon"></ion-icon>
        </button>
      </ion-buttons>

      <!-- Title -->
      <ion-title>{{ title }}</ion-title>

      <!-- End Buttons (e.g., User Initials) -->
      <ion-buttons slot="end">
        <router-link to="/settings">
          <div class="circle-button">
            <span class="initials">{{ userInitials }}</span>
          </div>
        </router-link>
      </ion-buttons>
    </ion-toolbar>
  </ion-header>
</template>

<script setup>
import { IonButtons, IonHeader, IonToolbar, IonTitle, IonIcon } from '@ionic/vue';
import { chevronBackOutline } from 'ionicons/icons';
import { useStore } from 'vuex';
import { computed } from 'vue';

defineProps({
  title: {
    type: String,
    default: 'Default Title',
  },
});

const store = useStore();
const user = store.getters.user;

const userInitials = computed(() => {
  if (user && user.name) {
    return user.name
      .split(' ')
      .map((namePart) => namePart[0])
      .join('');
  }
  return '';
});

function goBack() {
  window.history.back();
}
</script>

<style scoped>
/* General Button Style */
.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px; /* Ensure consistent size */
  height: 25px;
  background-color: white; /* White background */
  border-radius: 50%; /* Circular shape */
  border: 1px solid var(--ion-color-medium); /* Optional border for clarity */
  cursor: pointer;
}

/* Back Icon Styling */
.circle-button .icon {
  font-size: 18px;
  color: var(--ion-color-dark); /* Dark icon */
}

/* User Initials Styling */
.circle-button .initials {
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-dark); /* Dark text */
  text-transform: uppercase; /* Optional for uppercase initials */
}
</style>