<template>
  <ion-modal :is-open="isOpen" @didDismiss="close" backdrop-dismiss="false">
    <!-- Modal Content -->
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="close">
            <ion-icon :icon="arrowBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>New Entry</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="handleCreate" color="primary">
            Save Entry
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content>
      <div class="p-4">
        <ckeditor
          v-model="content"
          :editor="editor"
          :config="editorConfig"
          class="custom-editor"
          ref="editorInstance"
        />
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { defineProps, defineEmits } from 'vue';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';
import { chevronBackOutline, arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';

import {
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  IonTitle,
  IonContent,
  IonIcon,
} from '@ionic/vue';

const props = defineProps({
  isOpen: Boolean,
  onClose: Function,
  onCreate: Function,
});

const emits = defineEmits(['close']);

const editor = BalloonEditor;
const editorConfig = {
  toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
  placeholder: 'Start writing...',
};

const content = ref('');
const editorInstance = ref(null);

const close = () => {
  content.value = ''; // Reset the content
  emits('close');
};

const handleCreate = () => {
  if (content.value.trim() === '') {
    alert('Please write something before saving.');
    return;
  }

  props.onCreate(content.value); // Pass content back to parent
  close();
};

onMounted(() => {
  setTimeout(() => {
    if (editorInstance.value?.editing?.view) {
      editorInstance.value.editing.view.focus();
    }
  }, 100);
});
</script>

<style scoped>
/* CKEditor styles */
::v-deep .ck-editor__editable_inline p {
  font-size: 16px;
}

/* Placeholder styling */
::v-deep .ck.ck-editor__editable > .ck-placeholder::before {
  color: #6b7280; /* Customize color as needed */
}
</style>