<template>
  <ion-page>
    <Header :title="'Reading'" :default-href="'/test-home'" />
    <ion-content>
      <div v-if="getReadingInstance && getReadingInstance.reading">
        <ion-card class="pb-8 relative">
          <IonImg :src="'/storage/' + getReadingInstance.reading.image" alt="Image to represent the reading" />
          
          <ion-card-header>
            <ion-label>Reading</ion-label>
            <p>{{ getReadingInstance.reading.title }}</p>
          </ion-card-header>

          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-icon :icon="albumsOutline" />
                
                <ion-label>{{ getReadingInstance.status }}</ion-label>
              </ion-item>
              <ion-item>
                <ion-icon :icon="timeOutline" />
                <ion-label>{{ getReadingInstance.reading.estimated_time }}</ion-label>
              </ion-item>
            </ion-list>

            <p>{{ getReadingInstance.reading.description }}</p>

            <router-link :to="{ name: 'ActivityReadingInstanceStart', params: { readinginstanceid: getReadingInstance.id } }">
              <IonButton expand="full">
                {{ buttonText }}
              </IonButton>
            </router-link>
          </ion-card-content>
        </ion-card>
      </div>

      <div v-else>
        Loading...
      </div>
    </ion-content>
  </ion-page>

</template>

<script setup>

  import { ref, onMounted, computed } from "vue";
  import { albumsOutline, timeOutline } from 'ionicons/icons';
  import { IonPage, IonIcon, IonContent, IonButton, IonImg, IonCard, IonCardHeader, IonCardContent, IonLabel, IonList, IonItem } from '@ionic/vue';
  import Header from '../components/Header';
  import { useStore } from "vuex";

  const store = useStore();

  onMounted(() => {
    store.dispatch("fetchReadingInstance");
  });

  const getReadingInstance = computed(() => {
    return store?.getters?.getReadingInstance || null;
  });

  const buttonText = computed(() => {
    let text = 'Begin';
    if (getReadingInstance.value) {
      switch (getReadingInstance.value.status) {
        case 'In Progress':
          text = 'Continue';
          break;
        case 'Complete':
          text = 'Revisit';
          break;
      }
    }
    return text;
  });
</script>
