<template>
  <!-- Card -->
  <div class="flex justify-between items-center bg-none mt-8 md:mt-12 mb-2">
    <router-link to="/spotlights">
      <h1 class="text-page-subtitle-dark">Next Spotlight</h1>
    </router-link>
    <router-link to="/spotlights">
      <div class="flex items-center justify-center text-primary-500 hover:text-primary-400">
      <h3 class="text-sm font-medium">See all</h3>
      <chevron-right-icon class="chevron-dashboard" />
    </div>
    </router-link>
  </div>
  <!-- Card Body -->
  <div class="pb-3 sm:p-0">
  <!-- Content goes here -->
    <spotlight-card v-if="todo.length" :spotlights="todo"/>
    <div v-else class="card-no-entries">
      There are currently no 'Spotlights' in your ToDo list.
    </div>
  <!-- Content goes here -->
  </div>
  <!-- Card END -->
</template>

<script setup>

  import SpotlightCard from './SpotlightCard';
  import { sendPost } from './entities/functions.js';
  import { ChevronRightIcon } from '@heroicons/vue/20/solid'

  </script>

  <script>
  export default {
    data() {
      return {
        todo: [],
      };
    },
    mounted() {
      this.getContent();
    },
    methods: {
      async getContent() {
        const response = await sendPost('/api/me/spotlights', {
          withStatus: ['New'],
          limit: 1,
        });
        this.todo = response.todo;
      },
    },
  };
</script>
