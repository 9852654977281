<template>
  <!-- Header -->
  <header class="header-container">
    <div class="flex items-center">
      <router-link to="/dashboard">
        <ChevronLeftIcon class="flex items-center lg:hidden chevron-header-dark -ml-2" />
      </router-link>
      <h1 class="text-page-title-dark">Clients Panel</h1>
    </div>
  </header>
  <!-- END Header -->

  <loading-overlay :loading="loading"></loading-overlay>
  <div class="overflow-x-hidden">
  <!--Title&Filter-->
    <main v-show="!loading" class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <div class="relative">
        <UserStatusFilter @update:selectedStatuses="handleSelectedStatuses"></UserStatusFilter>
      </div>
      <div class="my-4">
        <div v-if="Object.keys(clients).length">
          <ClientList :data="clients" />
        </div>
        <div v-else class="card-no-entries">
          <p>There are currently no entries</p>
        </div>
      </div>
    </main>
  </div>
</template>

<script setup>
  import { ChevronLeftIcon  } from '@heroicons/vue/24/outline'
  import ClientList from '../components/ClientList';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';
  import UserStatusFilter from '../components/UserStatusFilter.vue';
</script>

<script>
  export default {
    data: function () {
      return {
          clients: {},
          loading: false,
          statuses: false
      }
    },

    mounted() {
  // Set the default status to "Client"
  this.statuses = ['Client']; // Matches the default selectedStatuses from UserStatusFilter
  this.getClientList();
},

    methods: {
      async getClientList() {
  this.loading = true;
  const responce = await sendPost('/api/get/clients', {
    'statuses': this.statuses
  });
  if (responce) {
    // Sort clients alphabetically by their names before assigning to this.clients
    this.clients = responce.clients.sort((a, b) => {
      const nameA = a.name.toLowerCase(); // Assuming clients have a `name` property
      const nameB = b.name.toLowerCase();
      return nameA.localeCompare(nameB);
    });
  }
  this.loading = false;
},
      handleSelectedStatuses(statuses) {
        this.statuses = statuses;
        this.getClientList();
      }
    }
  }
</script>
