import axios from "axios";

export const state = {
  aims: [],
};

export const mutations = {
  setAims: (state, aims) => (state.aims = aims),
  addAim: (state, aim) => state.aims.push(aim),
  removeAim: (state, id) =>
    (state.aims = state.aims.filter((a) => a.id !== id)),
};

export const actions = {
  // Fetch all aims
  async fetchAims({ commit }) {
    try {
      const response = await axios.get("/api/aims");
      commit("setAims", response.data.aims);
    } catch (error) {
      alert("Failed to fetch aims.");
      console.error(error);
    }
  },

  // Add a new aim
  async addAim({ commit }, aim) {
    try {
      const response = await axios.post("/api/aims", aim);
      commit("addAim", response.data.aim); // Assuming the response contains the newly created aim
    } catch (error) {
      alert("Failed to add aim.");
      console.error(error);
    }
  },

  // Update an existing aim
  async updateAim({ commit, state }, updatedAim) {
    try {
      const response = await axios.put(
        `/api/aims/${updatedAim.id}`,
        updatedAim
      );
    
      // Find the index of the aim being updated
      const index = state.aims.findIndex((aim) => aim.id === updatedAim.id);
    
      if (index !== -1) {
        // Replace the updated aim in the array
        state.aims[index] = response.data.aim;
      }

      // Alternatively, commit a mutation that updates the aim directly
      // commit('updateAim', response.data.aim); // if you have an 'updateAim' mutation

    } catch (error) {
      alert("Failed to update aim.");
      console.error(error);
    }
  },

  // Delete an aim
  async deleteAim({ commit }, id) {
    try {
      await axios.delete(`/api/aims/${id}`);
      commit("removeAim", id);
    } catch (error) {
      alert("Failed to delete aim.");
      console.error(error);
    }
  },
};

export const getters = {
  getAims(state) {
    return state.aims;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};