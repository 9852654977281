import axios from "axios";

export const state = {
  feelings: [],
};

export const mutations = {
  setFeelings: (state, feelings) => (state.feelings = feelings),
  addFeeling: (state, feeling) => state.feelings.push(feeling),
  removeFeeling: (state, id) =>
    (state.feelings = state.feelings.filter((f) => f.id !== id)),
};

export const actions = {
  // Fetch all feelings
  async fetchFeelings({ commit }) {
    try {
      const response = await axios.get("/api/feelings");
      commit("setFeelings", response.data.feelings);
    } catch (error) {
      alert("Failed to fetch feelings.");
      console.error(error);
    }
  },

  // Add a new feeling
  async addFeeling({ commit }, feeling) {
    try {
      const response = await axios.post("/api/feelings", feeling);
      commit("addFeeling", response.data.feeling); // Assuming the response contains the newly created feeling
    } catch (error) {
      alert("Failed to add feeling.");
      console.error(error);
    }
  },

  // Update an existing feeling
  async updateFeeling({ commit, state }, updatedFeeling) {
    try {
      const response = await axios.put(
        `/api/feelings/${updatedFeeling.id}`,
        updatedFeeling
      );
    
      // Find the index of the feeling being updated
      const index = state.feelings.findIndex((feeling) => feeling.id === updatedFeeling.id);
    
      if (index !== -1) {
        // Replace the updated feeling in the array
        state.feelings[index] = response.data.feeling;
      }

      // Alternatively, commit a mutation that updates the feeling directly
      // commit('updateFeeling', response.data.feeling); // if you have an 'updateFeeling' mutation

    } catch (error) {
      alert("Failed to update feeling.");
      console.error(error);
    }
  },

  // Delete a feeling
  async deleteFeeling({ commit }, id) {
    try {
      await axios.delete(`/api/feelings/${id}`);
      commit("removeFeeling", id);
    } catch (error) {
      alert("Failed to delete feeling.");
      console.error(error);
    }
  },
};

export const getters = {
  getFeelings(state) {
    return state.feelings;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};