<template>
  <ion-page>
    
    <ion-content class="ion-padding">
      <!-- Account Button -->
      <ion-item 
        button 
        @click="$router.push({ name: 'SettingsAccount' })"
        lines="full"
        detail="false" 
        class="ion-no-padding">
        <ion-label class="text-gray-800">Account</ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" class="text-gray-800 h-6 w-6"></ion-icon>
      </ion-item>
      
      <!-- Billing Button -->
      <ion-item 
        button 
        @click="$router.push({ name: 'SettingsBilling' })"
        lines="full" 
        detail="false"
        class="ion-no-padding">
        <ion-label class="text-gray-800">Billing</ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" class="text-gray-800 h-6 w-6"></ion-icon>
      </ion-item>
      
      <!-- Notifications Button -->
      <ion-item 
        button 
        @click="$router.push({ name: 'SettingsNotifications' })"
        lines="full"
        detail="false" 
        class="ion-no-padding">
        <ion-label class="text-gray-800">Notifications</ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" class="text-gray-800 h-6 w-6"></ion-icon>
      </ion-item>

      <!-- Support Button -->
      <ion-item 
        button 
        @click="$router.push({ name: 'SettingsSupport' })"
        lines="full" 
        detail="false"
        class="ion-no-padding">
        <ion-label class="text-gray-800">Support</ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" class="text-gray-800 h-6 w-6"></ion-icon>
      </ion-item>

      <!-- Log Out Button -->
      <ion-item 
        button 
        @click="openModal"
        lines="full"
        detail="false" 
        class="ion-no-padding">
        <ion-label class="text-gray-800">Log Out</ion-label>
        <ion-icon :icon="chevronForwardOutline" slot="end" class="text-gray-800 h-6 w-6"></ion-icon>
      </ion-item>

      <!-- Logout Modal -->
      <ion-modal :is-open="isOpen" @didDismiss="closeModal">
        <ion-content class="ion-padding">
          <div class="text-center">
            <ion-icon 
              name="warning-outline" 
              class="ion-margin-bottom" 
              style="font-size: 48px; color: var(--ion-color-warning);"
            ></ion-icon>
            <h2 class="ion-margin-bottom" style="font-weight: bold;">Log Out</h2>
            <p class="ion-margin-bottom">Are you sure you want to log out?</p>
          </div>
          <div class="ion-margin-top">
            <!-- Log Out Button -->
            <ion-button 
              expand="block" 
              color="danger" 
              @click="logout"
            >
              Log Out
            </ion-button>
            <!-- Cancel Button -->
            <ion-button 
              expand="block" 
              fill="clear" 
              color="medium" 
              @click="closeModal"
            >
              Cancel
            </ion-button>
          </div>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { IonItem, IonLabel, IonIcon, IonModal, IonButton, IonContent, IonPage } from '@ionic/vue';

import { chevronForwardOutline } from 'ionicons/icons';

const isOpen = ref(false);

function closeModal() {
  isOpen.value = false;
}

function openModal() {
  isOpen.value = true;
}

const store = useStore();
const router = useRouter();

const user = computed(() => store.getters.user);

function logout() {
  store.dispatch('logout')
    .then(() => {
      closeModal(); // Close the modal before navigating
      router.push({ name: 'Login' }); // Redirect to the login page
    })
    .catch(error => {
      console.error("Logout failed:", error);
    });
}
</script>