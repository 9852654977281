import axios from "axios";

export const state = {
  copings: []
};

export const mutations = {
  set_copings: (state, copings) => {
    state.copings = copings;
  }
};

export const actions = {
  async fetchCopings({ commit }) {
    try {
      const response = await axios.get("/api/me/copings");
      commit("set_copings", response.data.copings);
    } catch (error) {
      console.error("Error fetching copings:", error);
      alert(error);
    }
  }
};

export const getters = {
  getCopings(state) {
    return state.copings;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};