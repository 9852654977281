<template>
  <!-- Filter Icon Button -->
  <ion-button fill="clear" @click="openPopup">
    <ion-icon :icon="filter" slot="icon-only"></ion-icon>
  </ion-button>

  <ion-popover 
  :is-open="showPopup" 
  @didDismiss="closePopup"
  :style="{ '--min-width': '300px', '--max-width': '400px', '--height': 'auto' }"
>
  <ion-header>
    <ion-toolbar>
      <ion-title>Select Status</ion-title>
    </ion-toolbar>
  </ion-header>

  <div class="p-4 flex flex-col gap-4">
    <!-- Action Buttons -->
    <div class="flex gap-2 justify-between">
      <ion-button size="small" fill="outline" @click="disableAll">
        <ion-icon :icon="remove" slot="icon-only"></ion-icon>
        Disable All
      </ion-button>
      <ion-button size="small" fill="outline" @click="enableAll">
        <ion-icon :icon="add" slot="icon-only"></ion-icon>
        Enable All
      </ion-button>
    </div>

    <!-- Status List -->
    <ion-list class="mt-2">
      <ion-item v-for="status in statuses" :key="status.id">
        <ion-checkbox
          slot="start"
          :checked="selectedStatuses.includes(status.id)"
          @ionChange="toggleStatus(status.id, $event)"
        ></ion-checkbox>
        <ion-label>{{ status.name }}</ion-label>
      </ion-item>
    </ion-list>

    <!-- Footer Buttons -->
    <div class="flex justify-end gap-2">
      <ion-button fill="outline" @click="closePopup" size="small">Cancel</ion-button>
      <ion-button @click="applySelection" size="small">Apply</ion-button>
    </div>
  </div>
</ion-popover>
</template>

<script setup>
import { ref, onMounted, defineEmits } from "vue";
import { filter, add, remove } from 'ionicons/icons';
import { sendPost } from "../components/entities/functions.js";
import { 
  
  IonIcon,
  IonHeader, 
  IonPopover,
  IonTitle,
  IonList, 
  IonToolbar, 
  IonButton, 
  IonItem, 
  IonCheckbox, 
  IonLabel, 

  } from '@ionic/vue';

// Emits the selected statuses to the parent component
const emit = defineEmits();

// Reactive state
const statuses = ref([]);
const selectedStatuses = ref([]);
const showPopup = ref(false);

// Toggles the selection of a single status
const toggleStatus = (statusId, event) => {
  if (event.detail.checked) {
    // Add the status if it's not already in the list
    if (!selectedStatuses.value.includes(statusId)) {
      selectedStatuses.value.push(statusId);
    }
  } else {
    // Remove the status if it's unchecked
    selectedStatuses.value = selectedStatuses.value.filter((id) => id !== statusId);
  }
};

// Fetch statuses from the API
const getStatuses = async () => {
  const response = await sendPost("/api/get/statuses");
  if (response) {
    statuses.value = Object.entries(response.statuses).map(([id, name]) => ({
      id,
      name,
    }));

    // Set "Client" status as the default if found
    const clientStatus = statuses.value.find((status) => status.name === "Client");
    if (clientStatus) {
      selectedStatuses.value = [clientStatus.id];
      emit("update:selectedStatuses", selectedStatuses.value); // Emit the default to the parent
    } else {
      selectedStatuses.value = []; // No default if "Client" status is missing
    }
  }
};

// Functions to manage popup state
const openPopup = () => {
  showPopup.value = true;
};

const closePopup = () => {
  showPopup.value = false;
};

// Apply the current selection and close the popup
const applySelection = () => {
  emit("update:selectedStatuses", selectedStatuses.value); // Emit the selection
  closePopup();
};

// Disable all selections
const disableAll = () => {
  selectedStatuses.value = [];
};

// Enable all selections
const enableAll = () => {
  selectedStatuses.value = statuses.value.map((status) => status.id);
};

// Fetch statuses when the component is mounted
onMounted(() => {
  getStatuses();
});
</script>