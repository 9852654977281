<template>

  <ion-list>
    <!-- Questionnaire Card -->
    <div v-for="questionnaireInstance in questionnaireInstances" :key="questionnaireInstance.id">
      <router-link :to="{ name: 'ActivityQuestionnaireInstance', params: { questionnaireinstanceid: questionnaireInstance.id } }">    
        
        <ion-card class="relative">
          <img alt="questionnaire image" :src="'/storage/' + questionnaireInstance.questionnaire.image" />
            <ion-card-header class="absolute top-0 right-0 p-4">
         
              <ion-chip fill="solid" style="background-color: white; color: var(--ion-color-dark); font-size: 12px; font-weight: 600;">
  {{ questionnaireInstance.status }}
</ion-chip>
            
       
          </ion-card-header>
          <ion-card-header style="background-color: white;">
            <ion-card-title style="font-size: 18px;">{{ questionnaireInstance.questionnaire.title }}</ion-card-title>
            <ion-card-subtitle style="color: var(--ion-color-primary);">
              Questionnaire</ion-card-subtitle>
          </ion-card-header>
        </ion-card>

        </router-link>
    </div>

    <!-- Exercise Card -->
    <div v-for="worksheetInstance in worksheetInstances" :key="worksheetInstance.id">
      <router-link :to="{ name: 'ActivityWorksheetInstance', params: { worksheetinstanceid: worksheetInstance.id } }">
      
        <ion-card class="relative">
          <img alt="exercise image" :src="'/storage/' + worksheetInstance.worksheet.image" />
            <ion-card-header class="absolute top-0 right-0 p-4">
              <ion-chip fill="solid" style="background-color: white; color: var(--ion-color-dark); font-size: 12px; font-weight: 600;">
                {{ worksheetInstance.status }}</ion-chip>
        
          </ion-card-header>
          <ion-card-header style="background-color: white;">
            <ion-card-title style="font-size: 18px;">{{ worksheetInstance.worksheet.title }}</ion-card-title>
            <ion-card-subtitle style="color: var(--ion-color-primary);">Exercise</ion-card-subtitle>
          </ion-card-header>
        </ion-card>

      </router-link>
    </div>

    <!-- Reading Card -->
    <div v-for="readingInstance in readingInstances" :key="readingInstance.id">
      <router-link :to="{ name: 'ActivityReadingInstance', params: { readinginstanceid: readingInstance.id } }">
 
        <ion-card class="relative">
          <img alt="reading image" :src="'/storage/' + readingInstance.reading.image" />
            <ion-card-header class="absolute top-0 right-0 p-4">
              <ion-chip fill="solid" style="background-color: white; color: var(--ion-color-dark); font-size: 12px; font-weight: 600;">
              {{ readingInstance.status }}</ion-chip>
         
          </ion-card-header>
          <ion-card-header style="background-color: white;">
            <ion-card-title style="font-size: 18px;">{{ readingInstance.reading.title }}</ion-card-title>
            <ion-card-subtitle style="color: var(--ion-color-primary);">Reading</ion-card-subtitle>
          </ion-card-header>
        </ion-card>
      
      </router-link>
    </div>


  </ion-list>
</template>


<script setup>
  import { ref, watch, onMounted } from 'vue';
  import { IonList, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, IonChip } from '@ionic/vue';

  const props = defineProps({
    activities: Object,
    isInteractive: {
      type: Boolean,
      default: true
    }
  });

  const questionnaireInstances = ref([]);
  const worksheetInstances = ref([]);
  const readingInstances = ref([]);

  watch(() => props.activities, setActivities);

  onMounted(() => {
    setActivities();
  });

  function setActivities() {
    if (props.activities) {
      questionnaireInstances.value = props.activities.questionnaire_instances;
      worksheetInstances.value = props.activities.worksheet_instances;
      readingInstances.value = props.activities.reading_instances;
    }
  }
</script>

