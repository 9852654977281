<template>

  <ion-page>
    <Header :title="'Deep Dive'" :default-href="'/test-home'" />
      <ion-content>

    <div v-if="Object.keys(results).length">
      <deep-dive-values v-for="result in results" :data="result" />
    </div>
    <div v-else>
      <EmptyState message="There are currently no 'Actions' in your ToDo list." icon="heart" />
    </div>

    </ion-content>
  </ion-page>
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import EmptyState from '../components/EmptyState';
  import Header from '../components/Header';
  import { IonPage, IonContent } from '@ionic/vue';
  import DeepDiveValues from '../components/DeepDiveValues';
  import { sendPost } from '../components/entities/functions.js';
  import LoadingOverlay from '../components/LoadingOverlay';

  // Reactive references
  const results = ref({});
  const loading = ref(false);

  // Function to get results
  const getResults = async () => {
    loading.value = true;
    const response = await sendPost('/api/deep-dive');
    if (response) {
      results.value = response.results;
    }
    loading.value = false;
  };

  // Run getResults on mounted
  onMounted(() => {
    getResults();
  });
</script>
