<template>
  <ion-page>
    <!-- Header -->
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/journals"></ion-back-button>
        </ion-buttons>
        <ion-title>My Journal</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="saveJournal" color="primary">
            Save
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- Content -->
    <ion-content>
      <!-- Date Display -->
      <ion-item lines="none" class="ion-padding">
        <ion-label class="ion-text-wrap text-primary text-sm font-medium">
          {{ formattedDate }}
        </ion-label>
      </ion-item>

      <!-- Journal Editor -->
      <div class="ion-padding">
        <ckeditor
          :key="editorKey"
          v-model="editableContent"
          :editor="editor"
          :config="editorConfig"
          placeholder="Edit your journal..."
          class="min-h-64"
          ref="editorInstance"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, onMounted, nextTick } from 'vue';
import { useRoute, useRouter } from 'vue-router';  // Import useRouter
import axios from 'axios';
import { Ckeditor } from '@ckeditor/ckeditor5-vue';
import BalloonEditor from '@ckeditor/ckeditor5-build-balloon';

import { 
  IonPage, 
  IonContent,
  IonHeader, 
  IonToolbar, 
  IonTitle,
  IonButtons,
  IonBackButton,
  IonButton, 
  IonItem, 
  IonLabel, 
} from '@ionic/vue';

const route = useRoute();
const router = useRouter();  // Initialize useRouter

const journalContent = ref('');  // Content in view mode
const editableContent = ref('');  // Content in edit mode
const loading = ref(true);
const editor = BalloonEditor;
const editorConfig = {
  toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'blockQuote', 'undo', 'redo'],
};
const formattedDate = ref('');
const editorInstance = ref(null);

const editorKey = ref(0); // For forcing re-render of CKEditor

// Fetch journal content from the server
async function fetchJournal() {
  try {
    const response = await axios.get(`/api/journals/${route.params.id}`);
    journalContent.value = response.data.content; // Ensure this is in HTML format
    editableContent.value = journalContent.value;
    formattedDate.value = new Date(response.data.created_at).toLocaleDateString('en-GB', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    });
    nextTick(() => focusEditor());  // Focus editor after content loads
  } catch (error) {
    console.error("Error fetching journal:", error);
  } finally {
    loading.value = false;
  }
}

// Focus editor function
function focusEditor() {
  setTimeout(() => {
    if (editorInstance.value && editorInstance.value.editorInstance) {
      editorInstance.value.editorInstance.ui.view.editable.element.focus();
    }
  }, 100);
}

// Save edited content and navigate back
async function saveJournal() {
  try {
    const response = await axios.put(`/api/journals/${route.params.id}`, { content: editableContent.value });
    journalContent.value = response.data.content;  // Updated HTML content after saving
    
    // Redirect to the /journals page after saving
    router.push('/journals');
  } catch (error) {
    console.error("Error saving journal:", error);
  }
}

onMounted(fetchJournal);
</script>

<style scoped>
::v-deep .ck-editor__editable_inline p {
  padding: 0 !important;
  font-size: 16px !important; /* Adjust the font size as needed */
}
</style>