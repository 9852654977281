import { createRouter, createWebHistory } from "vue-router";
import store from "../store/index.js";
//auth
import ForgotPassword from '../pages/auth/ForgotPassword'
import Login from '../pages/auth/Login'
import NotFound from '../pages/NotFound'
import RegisterOverview from '../pages/auth/RegisterOverview'
import RegisterStart from '../pages/auth/RegisterStart'
import ResetPassword from '../pages/auth/ResetPassword'
import VerifyEmail from '../pages/auth/VerifyEmail'
//Layouts
import NoLayout from '../layouts/NoLayout.vue'
import WebAppLayout from '../layouts/WebAppLayout.vue'
import WebAppNoFooterLayout from '../layouts/WebAppNoFooterLayout.vue'
import WebsiteLayout from '../layouts/WebsiteLayout.vue'
import RegisterLayout from '../layouts/RegisterLayout.vue'

//webApp
import Activities from '../pages/Activities';
import ActivityQuestionnaireInstance from '../pages/ActivityQuestionnaireInstance';
import ActivityReadingInstance from '../pages/ActivityReadingInstance';
import ActivityWorksheetInstance from '../pages/ActivityWorksheetInstance';
import ActivityQuestionnaireInstanceStart from '../pages/ActivityQuestionnaireInstanceStart';
import ActivityReadingInstanceStart from '../pages/ActivityReadingInstanceStart';
import ActivityWorksheetInstanceStart from '../pages/ActivityWorksheetInstanceStart'; 
import CaseFormulation from '../pages/CaseFormulation';
import MyJourney from '../pages/MyJourney';
import Stage1 from '../pages/Stage1';
import Stage1Symptoms from '../pages/Stage1Symptoms';
import Stage1Problems from '../pages/Stage1Problems';
import Stage1Schemas from '../pages/Stage1Schemas';
import Stage1Modes from '../pages/Stage1Modes';
import Stage2 from '../pages/Stage2';
import Stage2OverallAims from '../pages/Stage2OverallAims';
import Stage2Goals from '../pages/Stage2Goals';
import Stage3 from '../pages/Stage3';
import Stage4 from '../pages/Stage4';
import Stage3Actions from '../pages/Stage3Actions';
import Chat from '../pages/Chat';
import Dashboard from '../pages/Dashboard';
import DeepDive from '../pages/DeepDive';
import FeelingsFinder from '../pages/FeelingsFinder';
import ValuesFinder from '../pages/ValuesFinder';
import Spotlights from '../pages/Spotlights';
import SpotlightView from '../pages/SpotlightView';
import LifePatternView from '../pages/LifePatternView';
import PartView from '../pages/PartView';
import Sessions from '../pages/Sessions';
import VideoChat from '../pages/VideoChat';
import Collections from '../pages/Collections';
import Settings from '../pages/Settings';
import SettingsAccount from '../pages/SettingsAccount';
import SettingsBilling from '../pages/SettingsBilling';
import SettingsNotifications from '../pages/SettingsNotifications';
import SettingsSupport from '../pages/SettingsSupport';
import SlackTest from '../pages/SlackTest';
import Test from '../pages/Test';
import TreatmentPlan from '../pages/TreatmentPlan';
import ClientsPanel from '../pages/ClientsPanel';
import ClientView from '../pages/ClientView';
import ClientViewTracking from '../pages/ClientViewTracking';
import ClientViewDeepDive from '../pages/ClientViewDeepDive';
import ClientViewSpotlights from '../pages/ClientViewSpotlights';
import ClientViewActivities from '../pages/ClientViewActivities';
import ClientViewStage1 from '../pages/ClientViewStage1';
import ClientViewStage2 from '../pages/ClientViewStage2';
import ClientViewStage3 from '../pages/ClientViewStage3';
import ClientViewNotes from '../pages/ClientViewNotes';
import UserNotes from '../pages/UserNotes';
import UserNoteDetail from '../pages/UserNoteDetail';
//website
import Anxiety from '../website/pages/common_problems/Anxiety';
import AssessmentOverview from '../website/pages/assessment/AssessmentOverview';
import AssessmentStart from '../website/pages/assessment/AssessmentStart';
import AssessmentPassword from '../website/pages/assessment/AssessmentPassword';
import AssessmentFinish from '../website/pages/assessment/AssessmentFinish';
import CommonProblems from '../website/pages/CommonProblems';
import Depression from '../website/pages/common_problems/Depression';
import FAQ from '../website/pages/FAQ';
import Helplines from '../website/pages/Helplines';
import InitialSteps from '../website/pages/InitialSteps';
import Landing from '../website/pages/Landing';
import Individuals from '../website/pages/Individuals';
import IndividualsStudents from '../website/pages/IndividualsStudents';
import Individuals1 from '../website/pages/Individuals1';
import Individuals2 from '../website/pages/Individuals2';
import Individuals3 from '../website/pages/Individuals3';
import Organisations from '../website/pages/Organisations';
import Organisations1 from '../website/pages/Organisations1';
import Organisations2 from '../website/pages/Organisations2';
import Organisations3 from '../website/pages/Organisations3';
import Partnerships from '../website/pages/Partnerships';
import LandingMOT from '../website/pages/LandingMOT';
import LandingConsult from '../website/pages/LandingConsult';
import Approach from '../website/pages/Approach';
import Platform from '../website/pages/Platform';
import Pricing from '../website/pages/Pricing';
import Contact from '../website/pages/Contact';
import PrivacyPolicy from '../website/pages/PrivacyPolicy';
import SelfEsteem from '../website/pages/common_problems/SelfEsteem';
import Stress from '../website/pages/common_problems/Stress';
import Terms from '../website/pages/Terms';
import TypesOfTherapy from '../website/pages/TypesOfTherapy';
import WhatTherapyProvides from '../website/pages/WhatTherapyProvides';
import AddingPWA from '../website/pages/AddingPWA';


  const router = createRouter({
      history: createWebHistory(),
      routes: [

//protected routes   

{ name: 'Activities', 
      component: Activities, 
      path: '/activities',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'CaseFormulation', 
      component: CaseFormulation, 
      path: '/case-formulation',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'MyJourney', 
      component: MyJourney, 
      path: '/my-journey',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage1', 
      component: Stage1, 
      path: '/my-journey/stage-1',
      meta: { requiresAuth: true, layout: WebAppLayout }},
     
      { name: 'Stage1Symptoms', 
      component: Stage1Symptoms, 
      path: '/my-journey/stage-1-symptoms',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage1Problems', 
      component: Stage1Problems, 
      path: '/my-journey/stage-1-problems',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage1Schemas', 
      component: Stage1Schemas, 
      path: '/my-journey/stage-1-schemas',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage1Modes', 
      component: Stage1Modes, 
      path: '/my-journey/stage-1-modes',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage2', 
      component: Stage2, 
      path: '/my-journey/stage-2',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage2OverallAims', 
      component: Stage2OverallAims, 
      path: '/my-journey/stage-2-overall-aims',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage2Goals', 
      component: Stage2Goals, 
      path: '/my-journey/stage-2-goals',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage3', 
      component: Stage3, 
      path: '/my-journey/stage-3',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage3Actions', 
      component: Stage3Actions, 
      path: '/my-journey/stage-3-actions',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'Stage4', 
        component: Stage4, 
        path: '/my-journey/stage-4',
        meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'Chat', 
      component: Chat, 
      path: '/chat',
      meta: { requiresAuth: true, layout: WebAppNoFooterLayout }},
      
      { name: 'DeepDive', 
      component: DeepDive, 
      path: '/deep-dive',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'FeelingsFinder', 
        component: FeelingsFinder, 
        path: '/feelings-finder',
        meta: { requiresAuth: true, layout: WebAppLayout }},

        { name: 'ValuesFinder', 
          component: ValuesFinder, 
          path: '/values-finder',
          meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'Home', 
      component: Dashboard, 
      path: '/dashboard',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'Spotlights', 
      component: Spotlights, 
      path: '/spotlights',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'Spotlight', 
      component: SpotlightView, 
      path: '/spotlights/:spotlightid',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'LifePatternView', 
        component: LifePatternView, 
        path: '/lifetrap/:id',
        props: true, // Enable route params as props
        meta: { requiresAuth: true, layout: WebAppLayout }},

        { name: 'PartView', 
          component: PartView, 
          path: '/emotionalstate/:id',
          props: true, // Enable route params as props
          meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'Sessions', 
      component: Sessions, 
      path: '/sessions',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'ClientsPanel',
      component: ClientsPanel,
      path: '/clients-panel',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'ClientView',
      component: ClientView,
      path: '/clients-panel/:clientId',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'ClientViewTracking',
        component: ClientViewTracking,
        path: '/clients-panel/:clientId/tracking',
        meta: { requiresAuth: true, layout: WebAppLayout }},

        { name: 'ClientViewDeepDive',
          component: ClientViewDeepDive,
          path: '/clients-panel/:clientId/deep-dive',
          meta: { requiresAuth: true, layout: WebAppLayout }},
          
          { name: 'ClientViewSpotlights',
            component: ClientViewSpotlights,
            path: '/clients-panel/:clientId/spotlights',
            meta: { requiresAuth: true, layout: WebAppLayout }},

            { name: 'ClientViewActivities',
              component: ClientViewActivities,
              path: '/clients-panel/:clientId/activities',
              meta: { requiresAuth: true, layout: WebAppLayout }},
              
              { name: 'ClientViewStage1',
                component: ClientViewStage1,
                path: '/clients-panel/:clientId/stage-1',
                meta: { requiresAuth: true, layout: WebAppLayout }},

                { name: 'ClientViewStage2',
                  component: ClientViewStage2,
                  path: '/clients-panel/:clientId/stage-2',
                  meta: { requiresAuth: true, layout: WebAppLayout }},

                  { name: 'ClientViewStage3',
                    component: ClientViewStage3,
                    path: '/clients-panel/:clientId/stage-3',
                    meta: { requiresAuth: true, layout: WebAppLayout }},

                    { name: 'ClientViewNotes',
                      component: ClientViewNotes,
                      path: '/clients-panel/:clientId/notes',
                      meta: { requiresAuth: true, layout: WebAppLayout }},

                      { name: 'UserNotes',
                        component: UserNotes,
                        path: '/user-notes',
                        meta: { requiresAuth: true, layout: WebAppLayout }},

                        { name: 'UserNoteDetail',
                          component: UserNoteDetail,
                          path: '/user-notes/:id',
                          meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'VideoChat',
          component: VideoChat,
          path: '/video-chat',
          meta: { requiresAuth: true, layout: NoLayout }},

      { name: 'Collections',
      component: Collections,
      path: '/collections/:slug',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'ActivityQuestionnaireInstance', 
      component: ActivityQuestionnaireInstance, 
      path: '/activities/questionnaire/:questionnaireinstanceid',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'ActivityQuestionnaireInstanceStart', 
      component: ActivityQuestionnaireInstanceStart, 
      path: '/activities/questionnaireinstance/:questionnaireinstanceid',
      meta: { requiresAuth: true, layout: WebAppNoFooterLayout }},
      
      { name: 'RegisterActivityQuestionnaireInstanceStart',
      component: ActivityQuestionnaireInstanceStart,
      path: '/register/questionnaire/:questionnaireinstanceid',
      meta: { layout: RegisterLayout, isGuest: true, requiresAuth: false }},

      { name: 'ActivityReadingInstance', 
      component: ActivityReadingInstance, 
      path: '/activities/reading/:readinginstanceid',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'ActivityReadingInstanceStart', 
      component: ActivityReadingInstanceStart, 
      path: '/activities/readinginstance/:readinginstanceid',
      meta: { requiresAuth: true, layout: WebAppNoFooterLayout }},
      
      { name: 'Settings', 
      component: Settings, 
      path: '/settings',
      meta: { requiresAuth: true, layout: WebAppLayout }},

      { name: 'SettingsAccount', 
        component: SettingsAccount, 
        path: '/settings-account',
        meta: { requiresAuth: true, layout: WebAppLayout }},

        { name: 'SettingsBilling', 
          component: SettingsBilling, 
          path: '/settings-billing',
          meta: { requiresAuth: true, layout: WebAppLayout }},

          { name: 'SettingsNotifications', 
            component: SettingsNotifications, 
            path: '/settings-notifications',
            meta: { requiresAuth: true, layout: WebAppLayout }},

            { name: 'SettingsSupport', 
              component: SettingsSupport, 
              path: '/settings-support',
              meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'SlackTest', 
      component: SlackTest, 
      path: '/slack',
      meta: { requiresAuth: true, layout: WebAppLayout }},
     
      { name: 'Test', 
      component: Test, 
      path: '/test',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'TreatmentPlan', 
      component: TreatmentPlan, 
      path: '/treatment-plan',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'ActivityWorksheetInstance', 
      component: ActivityWorksheetInstance, 
      path: '/activities/worksheet/:worksheetinstanceid',
      meta: { requiresAuth: true, layout: WebAppLayout }},
      
      { name: 'ActivityWorksheetInstanceStart', 
      component: ActivityWorksheetInstanceStart, 
      path: '/activities/worksheetinstance/:worksheetinstanceid',
      meta: { requiresAuth: true, layout: WebAppNoFooterLayout }},

//non-protected routes                    
      { name: 'Landing',
      component: Landing, 
      path: '/',
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'LandingMOT',
      component: LandingMOT, 
      path: '/landing-mot',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals',
      component: Individuals, 
      path: '/individuals',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'IndividualsStudents',
      component: IndividualsStudents, 
      path: '/students',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations',
      component: Organisations, 
      path: '/organisations',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals1',
      component: Individuals1, 
      path: '/individuals/weekly-therapy',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals2',
      component: Individuals2, 
      path: '/individuals/mental-health-programs',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals3',
      component: Individuals3, 
      path: '/individuals/mental-health-mot',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations1',
      component: Organisations1, 
      path: '/organisations/schools-universities',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations2',
      component: Organisations2, 
      path: '/organisations/startups-small-businesses',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations3',
      component: Organisations3, 
      path: '/organisations/charities-nonprofits',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Partnerships',
      component: Partnerships, 
      path: '/partnerships',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Platform',
      component: Platform, 
      path: '/platform',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Contact',
      component: Contact, 
      path: '/contact',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'LandingConsult',
      component: LandingConsult, 
      path: '/landing-consult',
      meta: { isGuest: true, layout: WebsiteLayout }},
                      
      { name: 'Approach',
      component: Approach, 
      path: '/approach',
      meta: { isGuest: true, layout: WebsiteLayout }},
                      
      { name: 'Helplines',
      component: Helplines, 
      path: '/helplines',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'InitialSteps',
      component: InitialSteps, 
      path: '/initial-steps',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Pricing',
      component: Pricing, 
      path: '/pricing',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'FAQ',
      component: FAQ, 
      path: '/faq',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'CommonProblems',
      component: CommonProblems,
      path: '/common-problems',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Depression',
      component: Depression, 
      path: '/common-problems/depression',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Anxiety',
      component: Anxiety,  
      path: '/common-problems/anxiety',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'SelfEsteem',
      component: SelfEsteem,  
      path: '/common-problems/self-esteem',
      meta: { isGuest: true, layout: WebsiteLayout }},
  
      { name: 'Stress',
      component: Stress, 
      path: '/common-problems/stress',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'WhatTherapyProvides',
      component: WhatTherapyProvides,
      path: '/therapy-provides',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'TypesOfTherapy',
      component: TypesOfTherapy,  
      path: '/types-of-therapy',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      path: '/privacy',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Terms',
      component: Terms, 
      path: '/terms',
      meta: { isGuest: true, layout: WebsiteLayout }},   
      
      { name: 'AddingPWA',
      component: AddingPWA, 
      path: '/add-to-home-screen',
      meta: { isGuest: true, layout: NoLayout }},  

//not protected and no layout                        
      { name: 'AssessmentOverview',
      component: AssessmentOverview,
      path: '/assessment/overview',
      meta: { isGuest: true, layout: NoLayout }},
      
      { name: 'AssessmentStart',
      component: AssessmentStart,
      path: '/assessment/start',
      meta: { isGuest: true, layout: NoLayout }},
      
      { name: 'AssessmentPassword',
      component: AssessmentPassword,
      path: '/assessment/password',
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'AssessmentFinish',
      component: AssessmentFinish,
      path: '/assessment/Finish',
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'RegisterOverview', 
      component: RegisterOverview, 
      path: '/register/overview', 
      meta: { isGuest: true, layout: NoLayout }}, 
      
      { name: 'RegisterStart', 
      component: RegisterStart, 
      path: '/register/start', 
      meta: { isGuest: true, layout: NoLayout }},
  
      { name: 'Login',
      component: Login, 
      path: '/login', 
      meta: { isGuest: true, layout: NoLayout }},    
  
      { name: '404',
      component: NotFound,
      path: '/:pathMatch(.*)*',
      meta: { isGuest: true, layout: NoLayout }},   
      
      { name: 'ForgotPassword', 
      component: ForgotPassword, 
      path: "/forgot-password",
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'ResetPassword',
      component: ResetPassword,
      path: "/reset-password/:token",
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'VerifyEmail',
      component: VerifyEmail,
      path: "/verify-email",
      meta: { isGuest: true, layout: NoLayout }},
          
        ],
   

    scrollBehavior(to, from, savedPosition) {

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
    },
    });


    router.beforeEach((to, from, next) => {
      if (to.meta.requiresAuth && !store.getters.user) {
        next({ name: "Login" });
      } else if (to.meta.isGuest && store.getters.user) {
        next({ name: "Home" });
      } else {
        // Scroll to top before navigating
        window.scrollTo(0, 0);
        next();
      }
    });

export default router;