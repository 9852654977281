<template>

  <ion-page>
    <Header :title="'Spotlights'" :default-href="'/test-home'" />
      <ion-content>

<!--ToDo Spotlights-->
      <ion-toolbar class="ion-no-padding ion-margin-bottom" style="border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;">
        <ion-buttons slot="start" class="ion-padding-start">
          <h4 class="ion-font-weight-bold">To Do</h4>
        </ion-buttons>
      </ion-toolbar>

      <div v-if="todo.length">
        <spotlight-card :spotlights="todo" />
      </div>
      <div v-else>
        <EmptyState 
          message="There are currently no 'Spotlights' in your ToDo." 
        />
      </div>


<!--Archive Spotlights-->
          <ion-toolbar class="ion-no-padding ion-margin-bottom" style="border-top: 1px solid #ccc; border-bottom: 1px solid #ccc;">
      <ion-buttons slot="start" class="ion-padding-start">
        <h4 class="ion-font-weight-bold">Archive</h4>
      </ion-buttons>
    </ion-toolbar>

      <div v-if="archive.length">
        <spotlight-card :spotlights="archive"/>
      </div>
      <div v-else>
        <EmptyState message="There are currently no 'Spotlights' in your Archive." />
      </div>

    </ion-content>
  </ion-page>
</template>

<script setup>

  import { ref, onMounted } from 'vue';
  import { IonPage, IonContent, IonToolbar, IonButtons, IonButton, IonIcon } from '@ionic/vue';
  import Header from '../components/Header';
  import SpotlightCard from '../components/SpotlightCard';
  import EmptyState from '../components/EmptyState';
  import { sendPost } from '../components/entities/functions.js';

// Local state
const todo = ref([]);
const archive = ref([]);
const loading = ref(false);

// Mounted lifecycle hook
onMounted(() => {
  getContent();
});

// Methods
async function getContent() {
  loading.value = true;
  const response = await sendPost('/api/me/spotlights');
  todo.value = response.todo;
  archive.value = response.archive;
  loading.value = false;
}
</script>


