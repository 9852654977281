import axios from "axios";

export const state = {
  values: [],
};

export const mutations = {
  setValues: (state, values) => (state.values = values),
  addValue: (state, value) => state.values.push(value),
  removeValue: (state, id) =>
    (state.values = state.values.filter((v) => v.id !== id)),
};

export const actions = {
  // Fetch all values
  async fetchValues({ commit }) {
    try {
      const response = await axios.get("/api/values");
      commit("setValues", response.data.values);
    } catch (error) {
      alert("Failed to fetch values.");
      console.error(error);
    }
  },

  // Add a new value
  async addValue({ commit }, value) {
    try {
      const response = await axios.post("/api/values", value);
      commit("addValue", response.data.value); // Assuming the response contains the newly created value
    } catch (error) {
      alert("Failed to add value.");
      console.error(error);
    }
  },

  // Update an existing value
  async updateValue({ commit, state }, updatedValue) {
    try {
      const response = await axios.put(
        `/api/values/${updatedValue.id}`,
        updatedValue
      );

      // Find the index of the value being updated
      const index = state.values.findIndex((value) => value.id === updatedValue.id);

      if (index !== -1) {
        // Replace the updated value in the array
        state.values[index] = response.data.value;
      }

      // Alternatively, commit a mutation that updates the value directly
      // commit('updateValue', response.data.value); // if you have an 'updateValue' mutation
    } catch (error) {
      alert("Failed to update value.");
      console.error(error);
    }
  },

  // Delete a value
  async deleteValue({ commit }, id) {
    try {
      await axios.delete(`/api/values/${id}`);
      commit("removeValue", id);
    } catch (error) {
      alert("Failed to delete value.");
      console.error(error);
    }
  },
};

export const getters = {
  getValues(state) {
    return state.values;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};