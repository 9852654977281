import axios from "axios";

export const state = {
  problems: [],
};

export const mutations = {
  setProblems: (state, problems) => (state.problems = problems),
  addProblem: (state, problem) => state.problems.push(problem),
  removeProblem: (state, id) =>
    (state.problems = state.problems.filter((p) => p.id !== id)),
};

export const actions = {
  // Fetch all problems
  async fetchProblems({ commit }) {
    try {
      const response = await axios.get("/api/problems");
      commit("setProblems", response.data.problems);
    } catch (error) {
      alert("Failed to fetch problems.");
      console.error(error);
    }
  },

  // Add a new problem
  async addProblem({ commit }, problem) {
    try {
      const response = await axios.post("/api/problems", problem);
      commit("addProblem", response.data.problem); // Assuming the response contains the newly created problem
    } catch (error) {
      alert("Failed to add problem.");
      console.error(error);
    }
  },

// Update an existing problem
async updateProblem({ commit, state }, updatedProblem) {
  try {
    const response = await axios.put(
      `/api/problems/${updatedProblem.id}`,
      updatedProblem
    );
    
    // Find the index of the problem being updated
    const index = state.problems.findIndex((problem) => problem.id === updatedProblem.id);
    
    if (index !== -1) {
      // Replace the updated problem in the array
      state.problems[index] = response.data.problem;
    }

    // Alternatively, commit a mutation that updates the problem directly
    // commit('updateProblem', response.data.problem); // if you have an 'updateProblem' mutation

  } catch (error) {
    alert("Failed to update problem.");
    console.error(error);
  }
},

  // Delete a problem
  async deleteProblem({ commit }, id) {
    try {
      await axios.delete(`/api/problems/${id}`);
      commit("removeProblem", id);
    } catch (error) {
      alert("Failed to delete problem.");
      console.error(error);
    }
  },
};

export const getters = {
  getProblems(state) {
    return state.problems;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};