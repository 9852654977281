<template>
  <ion-page>
    <ion-content>
      <!-- Floating Action Button for Creating a Journal Entry -->
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openCreateJournalModal" color="primary">
          <ion-icon :icon="pencilOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <!-- Journal Entries List -->
      <ion-list lines="full" class="ion-margin">
        <ion-item
          v-for="journal in journals"
          :key="journal.id"
          detail="true"
          button
          :router-link="{ name: 'JournalDetail', params: { id: journal.id } }"
        >
          <!-- Journal Entry Details -->
          <ion-label class="ion-text-wrap">
            <h3 class="text-sm text-primary">{{ formatDate(journal.created_at) }}</h3>
            <p class="line-clamp-1 text-gray-700" v-html="journal.content"></p>
          </ion-label>
          <!-- Trash Icon -->
          <ion-icon
            :icon="trashBinOutline"
            slot="end"
            class="text-gray-600 hover:text-red-700"
            @click.stop.prevent="openModal(journal.id)" 
          ></ion-icon>
        </ion-item>
      </ion-list>

      <!-- Confirmation Modal -->
      <ConfirmationModal
        :isOpen="isModalOpen"
        :onConfirm="confirmDelete"
        :onClose="closeModal"
        title="Delete Journal Entry"
        message="Are you sure you want to delete this journal entry?"
      />

      <!-- Create Journal Modal -->
      <CreateJournalModal
        :isOpen="isCreateJournalModalOpen"
        :onClose="closeCreateJournalModal"
        :onCreate="addJournal"
      />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { pencilOutline, trashBinOutline } from 'ionicons/icons';
import CreateJournalModal from '../components/CreateJournalModal'; // Reuse your modal for creating journal entries
import ConfirmationModal from '../components/ConfirmationModal';
import axios from 'axios';

import { 
  IonPage, 
  IonContent,
  IonList,
  IonFab,
  IonFabButton, 
  IonIcon,
  IonItem, 
  IonLabel, 
} from '@ionic/vue';

const journals = ref([]);
const isModalOpen = ref(false);
const isCreateJournalModalOpen = ref(false);
const journalIdToDelete = ref(null);

async function fetchJournals() {
  try {
    const response = await axios.get('/api/journals');
    journals.value = response.data;
  } catch (error) {
    console.error("Error fetching journals:", error);
  }
}

async function addJournal(content) {
  if (!content.trim()) return;
  try {
    const response = await axios.post('/api/journals', { content });
    journals.value.unshift(response.data);
  } catch (error) {
    console.error("Error adding journal:", error);
  }
}

function openModal(journalId) {
  isModalOpen.value = true;
  journalIdToDelete.value = journalId;
}

function closeModal() {
  isModalOpen.value = false;
  journalIdToDelete.value = null;
}

async function confirmDelete() {
  try {
    await axios.delete(`/api/journals/${journalIdToDelete.value}`);
    await fetchJournals(); // Re-fetch journals after deletion
    closeModal();
  } catch (error) {
    console.error("Error deleting journal:", error);
  }
}

function openCreateJournalModal() {
  isCreateJournalModalOpen.value = true;
}

function closeCreateJournalModal() {
  isCreateJournalModalOpen.value = false;
}

function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString(); 
}

// Fetch journals on mount
onMounted(() => {
  fetchJournals();
});
</script>

<style scoped>
.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hover\\:text-red-700:hover {
  color: #dc2626;
}
</style>