<template>
  <div
    v-for="note in reversedNotes"
    :key="note.id"
    class="bg-white border p-4 my-2 shadow-md rounded-md max-w-lg"
  >
    <Disclosure>
      <template #default="{ open }">
        <DisclosureButton class="w-full text-left">
          <h3 class="font-semibold">{{ note.title }}</h3>
        </DisclosureButton>
        <DisclosurePanel>
          <hr class="mb-2">
          <p v-html="note.description"></p>
        </DisclosurePanel>
      </template>
    </Disclosure>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { sendPost } from './entities/functions.js';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';

const client = ref({});
const loading = ref(false);

const route = useRoute();
const clientId = computed(() => route.params.clientId);

const fetchClientInfo = async () => {
  loading.value = true;
  try {
    const response = await sendPost('/api/get/client-info', {
      client_id: clientId.value,
    });
    if (response) {
      client.value = response.client || {};
    }
  } catch (error) {
    console.error('Error fetching client info:', error);
  } finally {
    loading.value = false;
  }
};

const reversedNotes = computed(() =>
  client.value.note ? client.value.note.slice().reverse() : []
);

onMounted(fetchClientInfo);
</script>