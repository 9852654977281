<template>

 
  <!-- Thoughts -->
  <div v-if="getData('thoughts').length > 0">
    <div v-for="thought in getData('thoughts')" :key="thought.id" class="">
      <div class="modal-list">
        <p class="text-body-cf-tp">"{{ thought.thought }}"</p>
      </div>
      <p class=" px-2 mx-2 py-2 text-primary-500 font-normal text-sm text-left">
        <span class="font-medium italic">perspective:</span> {{ thought.category }}
      </p>
    </div>
  </div>

  <!-- Behaviours -->
  <div v-if="getData('behaviours').length > 0">
    <div v-for="behaviour in getData('behaviours')" :key="behaviour.id" class="modal-list">
      <p class="text-body-cf-tp">{{ behaviour.behaviour }}</p>
    </div>
  </div>

  <!-- Feelings -->
  <div v-if="getData('feelings').length > 0">
    <div v-for="feeling in getData('feelings')" :key="feeling.id" class="modal-list">
      <p class="text-body-cf-tp">I feel <span class="font-semibold text-secondary-600">{{ feeling.feeling }}</span></p>
    </div>
  </div>

  <!-- Life Balances -->
  <div v-if="getData('lifeslices').length > 0">
    <p class="my-4 px-4 text-body-cf-tp text-left">
      The life dimensions I am least satisfied with are:
    </p>
    <div v-for="lifeslice in getData('lifeslices')" :key="lifeslice.id" class="">
      <div class="modal-list">
        <p class="text-body-cf-tp-semibold">{{ lifeslice.lifeslice }}</p>
      </div>
      <p class="px-2 mx-2 py-2 text-body-base text-left">
        <span class="font-medium italic">My Example:</span> {{ lifeslice.category }}
      </p>
    </div>
  </div>

  <!-- Personality -->
  <div v-if="getData('characters').length > 0">
    <div v-for="character in getData('characters')" :key="character.id">
      <div class="modal-list">
        <p class="text-body-cf-tp-semibold">{{ character.character }}</p>
      </div>
      <p class="px-2 mx-2 py-2 text-body-base text-left">
        <span class="font-medium italic">My Example:</span> {{ character.category }}
      </p>
    </div>
  </div>

  <!-- Sensations -->
  <div v-if="getData('sensations').length > 0">
    <div v-for="sensation in getData('sensations')" :key="sensation.id" class="modal-list">
      <p class="text-body-base">{{ sensation.sensation }}</p>
    </div>
  </div>


<!-- Values  -->
<div v-if="getData('values').length > 0">
  <div v-for="value in getData('values')" :key="value.id" class="">
    <div class="modal-list">
      <p class="text-body-cf-tp-semibold">{{ value.value }}</p>
    </div>
    <p class="px-2 mx-2 py-2 text-body-base text-left"><span class="font-medium italic">My Example:</span> {{ value.category }}</p>        
  </div>
</div>

<!-- Parenting styles -->
<div v-if="getData('parentstyles').length > 0">
  <div v-for="parentstyle in getData('parentstyles')" :key="parentstyle.id">
    <div class="modal-list">
      <UserGroupIcon class="modal-icon" />
      <p class="text-body-cf-tp-semibold">{{ parentstyle.parentstyle }}</p>
    </div>
    <p class="px-2 mx-2 py-2 text-body-base text-left">
      <span class="font-medium italic">My Example:</span> {{ parentstyle.category }}
    </p>
  </div>
</div>

</template>


<script>
import { computed, onMounted } from 'vue';
import { useStore } from 'vuex';

export default {
  props: {
    thoughts: {
      type: Array,
      default: []
    },
    behaviours: {
      type: Array,
      default: []
    },
    feelings: {
      type: Array,
      default: []
    },
    lifeslices: {
      type: Array,
      default: []
    },
    characters: {
      type: Array,
      default: []
    },
    sensations: {
      type: Array,
      default: []
    },
    values: {
      type: Array,
      default: []
    },
    parentstyles: {
      type: Array,
      default: []
    },
    onClientPage: {
      type: Boolean,
      default: false
    }
    
  },

  setup(props) {
    const store = useStore();

    const getData = (type) => {
      if (!props.onClientPage) {
        return store.getters[`get${capitalizeFirstLetter(type)}`] || [];
      }
      return props[type] || [];
    };

    const fetchData = (type) => {
      if (!props.onClientPage) {
        store.dispatch(`fetch${capitalizeFirstLetter(type)}`);
      }
    };

    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

    onMounted(() => {
      ['thoughts', 'behaviours', 'feelings', 'lifeslices', 'characters', 'sensations', 'values', 'parentstyles'].forEach(fetchData);
    });

    return {
      getData
    };
  }
};
</script>