// goalActions.module.js
import axios from "axios";

export const state = {
  goalActions: []
};

export const mutations = {
  set_goalActions: (state, goalActions) => {
    state.goalActions = goalActions;
  }
};

export const actions = {
  async fetchGoalActions({ commit }) {
    try {
      const response = await axios.get("/api/me/goal-actions");
      commit("set_goalActions", response.data.goalActions);
    } catch (error) {
      console.error("Error fetching goal actions:", error);
      alert(error);
    }
  }
};

export const getters = {
  getGoalActions(state) {
    return state.goalActions;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};