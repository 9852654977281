<template>
 
<ul role="list" class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-6 gap-y-6 ">

<!-- Questionnaire Card -->
  <li v-for="questionnaireInstance in questionnaireInstances" :key="questionnaireInstance.id">
    <component :is="isInteractive ? 'router-link' : 'span'" :to="{name: 'ActivityQuestionnaireInstance', params: { questionnaireinstanceid: questionnaireInstance.id },}">
    <div class="card-design-1 relative bg-primary-500/50 py-4 h-32 hover:opacity-80"
  :style="{ 
    'background-image': 'url(\'/storage/' + questionnaireInstance.questionnaire.image + '\')',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    'background-blend-mode': 'multiply'
  }"> 
      <span class="card-status">{{ questionnaireInstance.status }}</span>
      <div class="py-1 pl-4 bg-questionnaire mt-8 w-full">
        <h4 class="pt-1 text-activity-type text-questionnaire-100">Questionnaire</h4>
        <p class=" -mt-0.5 text-activity-display-card">{{ questionnaireInstance.questionnaire.title }}</p>
        </div>
      </div>
    </component>
 
  </li>

<!-- Exercise Card -->

<li v-for="worksheetInstance in worksheetInstances" :key="worksheetInstance.id">
  <component :is="isInteractive ? 'router-link' : 'span'" :to="{name: 'ActivityWorksheetInstance', params: { worksheetinstanceid: worksheetInstance.id },}">
  <div class="card-design-1 relative bg-rose-300/50 py-4 h-32 hover:opacity-80"
  :style="{ 
    'background-image': 'url(\'/storage/' + worksheetInstance.worksheet.image + '\')',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    'background-blend-mode': 'multiply'
  }">
  
      <span class="card-status">{{ worksheetInstance.status }}</span>
      <div class="py-1 pl-4 bg-exercise mt-8 w-full">
        <h4 class="pt-1 text-activity-type text-exercise-100">Exercise</h4>
        <p class=" -mt-0.5 text-activity-display-card">{{ worksheetInstance.worksheet.title }}</p>
        </div>
      </div>
    </component>
  
  </li>

<!-- Reading Card -->
<li v-for="readingInstance in readingInstances" :key="readingInstance.id">
  <component :is="isInteractive ? 'router-link' : 'span'" :to="{name: 'ActivityReadingInstance', params: { readinginstanceid: readingInstance.id },}">
  <div class="card-design-1 relative bg-secondary-300 py-4 h-32 hover:opacity-80"
  :style="{ 
    'background-image': 'url(\'/storage/' + readingInstance.reading.image + '\')',
    'background-repeat': 'no-repeat',
    'background-size': 'cover',
    'background-blend-mode': 'multiply'
  }"> 
      <span class="card-status">{{ readingInstance.status }}</span>
      <div class="py-1  pl-4 bg-reading mt-8 w-full">
        <h4 class="pt-1 text-activity-type text-reading-100">Reading</h4>
        <p class=" -mt-0.5 text-activity-display-card">{{ readingInstance.reading.title }}</p>
        </div>
      </div>
  </component>
  </li>
<!-- END Reading Card -->

</ul>
  
</template>

<script setup>
  import { ref } from 'vue'
</script>

<script>
export default {
  props: {
      activities: Object,
      isInteractive: {
        type: Boolean,
        default: true
      }
  },
  data: function () {
    return {
      questionnaireInstances: [],
      worksheetInstances: [],
      readingInstances: [],
    }
  },

  watch: {
    activities() {
      this.setActivities();
    }
  },

  mounted() {
    this.setActivities();
  },

  methods: {
    setActivities() {
      if (this.activities) {
        this.questionnaireInstances = this.activities.questionnaire_instances;
        this.worksheetInstances = this.activities.worksheet_instances;
        this.readingInstances = this.activities.reading_instances;
      }
    }
  }
}
</script>
