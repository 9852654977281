<template>

<!-- Header -->
    <div class="flex justify-between items-center mt-10 md:mt-12 mb-2">
      <router-link to="/sessions">
        <h1 class="text-page-subtitle-dark capitalize">{{ session ? 'Next ' + bookText : bookText + 's' }}</h1>
      </router-link>
      <router-link to="/sessions">
        <div class="flex items-center justify-center text-primary-500 hover:text-primary-400">
      <h3 class="text-sm font-medium">See more</h3>
      <chevron-right-icon class="chevron-dashboard" />
    </div>
      </router-link>
    </div>

<!-- CARD - Next Session -->

  <div v-if="session">  
    <router-link to="/sessions"> 
    <div class="card-design-1 bg-gradient-to-r from-primary-100 to-secondary-100 pt-2">      
      <div class="flex items-center justify-between px-4">
  <!-- Left column with text lines -->
  <div >
    <h1 class="text-lg sm:text-xl font-semibold text-secondary-600">{{ getWeekDay(session.scheduled_time) }}, {{ getDate(session.scheduled_time) }}</h1>
    <h1 class="text-lg font-normal text-gray-700">{{ getStartTime(session.scheduled_time) }} - {{ getFinishTime(session.scheduled_time, session.duration) }}</h1>
  </div>

  <!-- Right column with the image -->
  <div class="pt-2 pb-2">
    <img class="h-16" src="/img/webapp/dashboard/icon_session2.png" alt="imageAltText" />
  </div>
</div> 

  <!-- adding the therapists avatar and name --> 
          <div v-if="session.user_name" class="pl-4 pb-1 text-sm text-secondary-600 font-medium">
           <div class="flex items-center">
                <div class="inline-block h-7 w-auto overflow-hidden ring-1 ring-white shadow-md rounded-full">
                <img class="h-7" src="/img/avatar.jpg" alt="Therapist face image" />
                </div>
                <h1 class="text-body-sm ml-2">{{ session.user_name }}</h1>                 
              </div>  
            </div>
             
<!-- Join / Message -->

<div class="flex justify-between items-center">
          <div v-if="session.isStartingSoon" class="mx-auto text-gray-700 font-normal text-base">
            
            <p v-if="!session.sessionStarted && !session.sessionEnded" 
            class="pt-4">
              Get ready, this {{ bookText }} will begin in <span class="font-semibold text-gray-700"> {{ session.timeTillSessionStart }}</span>
            </p>
            
            <div class="mx-auto ">
            <button class="mt-2 mb-4 button-waiting-room cursor-pointer flex items-center" @click="joinSession(session)">
              <p class="pr-4">Enter Waiting Room</p>
              <component :is="VideoCameraIcon" class="h-7 w-auto stroke-1 text-white" aria-hidden="true" />
            </button>
          </div>
            
          </div>
          <p v-else-if="session.isInFuture" class="pt-2 text-sm text-primary font-medium">
<!-- Session Upcoming -->
          </p>
          <p v-else class="px-4 py-4 text-sm text-gray-700 font-medium italic">
            'It looks like you missed your {{ bookText }}'
          </p>

        </div>
        
            </div>
          </router-link>
        </div>

     





<!-- CARD - No Upcoming Sessions -->
      <div v-else class="card-design-1 py-6 px-4 mx-auto bg-gradient-to-r from-primary-100 to-secondary-100">

      <h1 class="text-center text-base text-gray-800 font-normal">You have no upcoming {{ bookText }}s!</h1>
  
        <div class="flex justify-center">
          <router-link to="/sessions">
            <button class="mt-2 rounded-md bg-secondary-500 hover:bg-secondary-600 px-8 py-2 text-base tracking-wide font-semibold text-white text-center shadow-sm mx-auto">
              Book <span class="capitalize">{{ bookText }}</span></button>
          </router-link> 
        </div>
      </div>
</template>

<script setup>
  import { VideoCameraIcon } from '@heroicons/vue/24/outline'
  import { ChevronRightIcon, ClockIcon } from '@heroicons/vue/20/solid'
  import { sendPost } from './entities/functions.js';
</script>

<script>
export default {
    data() {
        return {
            session: null,
            timerInterval: null,
        };
    },
    mounted() {
        sendPost("/api/video/get-sessions", { "limit": 1 }).then((response) => {
            if (response?.sessions?.length) {
                this.session = response.sessions[0];
                this.setTimer()
            }
        });
    },
    beforeUnmount() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
    },
    computed: {
      user() {
        return this.$store.getters['user'];
      },
      bookText() {
        return this.user.is_free_consultation ? 'consultation' : 'session';
      }
    },

    methods: {
        setTimer() {
          this.timerTick();
          this.timerInterval = setInterval(() => {
            this.timerTick();
          }, 1000);
        },

        timerTick() {
          let now = Date.now();

          let sessionStartDateTime = new Date(this.session.scheduled_time);
          let sessionStartTime = sessionStartDateTime.getTime();
          let sessionEndTime = sessionStartTime + this.session.duration * 1000;

          this.session.sessionStarted = now >= sessionStartTime;
          this.session.sessionEnded = now >= sessionEndTime;
          this.session.isStartingSoon = this.isSessionStartingSoon(this.session.scheduled_time, this.session.duration);
          this.session.isInFuture = this.isSessionInFuture(this.session.scheduled_time);

          if (!this.session.sessionStarted && !this.session.sessionEnded) {
            let remainingTimeStart = Math.ceil((sessionStartTime - now) / 1000);
            this.session.timeTillSessionStart = this.formatTime(remainingTimeStart);
          }
        },
        isSessionStartingSoon(scheduled_time, duration) {
          let sessionStart = new Date(scheduled_time);
          let sessionEnd = new Date(scheduled_time);
          sessionEnd.setMinutes(sessionEnd.getMinutes() + duration / 60);

          let now = new Date();
          return now.getTime() >= sessionStart.getTime() - 15 * 60 * 1000
                && now.getTime() <= sessionEnd.getTime();
        },
        isSessionInFuture(scheduled_time) {
          let sessionStart = new Date(scheduled_time);
          let now = new Date();
          return sessionStart.getTime() > now.getTime();
        },
        formatTime(seconds) {
          let minutes = Math.floor(seconds / 60);
          seconds %= 60;
          return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
        },
        joinSession() {
          this.$router.push({ name: 'VideoChat' });
        },
        getWeekDay(scheduled_time) {
            let date = new Date(scheduled_time);
            let weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
            return weekDays[date.getUTCDay()];
        },
        getDate(scheduled_time) {
            let date = new Date(scheduled_time);
            return date.toLocaleDateString([], { day: "numeric", month: "long" }); // will return date like "7 March"
        },
        getStartTime(time) {
            let utcTime = new Date(time);
            return utcTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        },
        getFinishTime(time, duration) {
            let finishTime = new Date(time);
            finishTime.setSeconds(finishTime.getSeconds() + duration);
            return finishTime.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
        },
    },
    components: { ClockIcon }
}
</script>
