<template>
  <!-- Ionic Modal -->
  <ion-modal :is-open="isOpen" @did-dismiss="closeModal" ref="modalRef">
    <ion-content class="ion-padding">
      <div class="text-center">
        <img class="mx-auto h-24 w-auto" src="/img/webapp/activities/activity-complete.png" alt="activity complete" />
        <h3 class="text-display-base pt-4">Exercise Complete</h3>
        <p class="text-body-base pt-4">
          Well done on finishing this exercise. You can access it again at anytime on your activities page.
        </p>
        <ion-button expand="full" class="mt-4" @click="goToActivities">Got it, thanks</ion-button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, inject } from 'vue';
import { useRouter } from 'vue-router';
import { IonModal, IonContent, IonButton } from '@ionic/vue';

// Inject the readingFinishActions to use the modal control functions
const worksheetFinishActions = inject('worksheetFinishActions');

// Define the modal visibility state
const isOpen = ref(false);

// Router to navigate to Activities page
const router = useRouter();

// Open the modal when called
const openModal = () => {
  isOpen.value = true;
};

// Close the modal by toggling the visibility
const closeModal = () => {
  isOpen.value = false;  // Toggle the modal visibility to false
};

// Function for navigation and closing the modal
const goToActivities = async () => {
  try {
    await router.push({ name: 'Activities' }); // Navigate to the Activities page
    closeModal(); // Close the modal after navigation
  } catch (error) {
    console.error('Error navigating to Activities:', error);
  }
};

// Ensure that `readingFinishActions` methods are accessible
onMounted(() => {
  if (worksheetFinishActions) {
    worksheetFinishActions.openModal = openModal;
    worksheetFinishActions.closeModal = closeModal;
  }
});

onBeforeUnmount(() => {
  if (worksheetFinishActions) {
    worksheetFinishActions.openModal = null;
    worksheetFinishActions.closeModal = null;
  }
});
</script>