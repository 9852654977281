<template>
  <div class="for-organisations-page">

<!-- SECTION-Hero -->
<img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />

    <section class="relative bg-white">
        <div class="mx-auto max-w-7xl lg:grid lg:grid-cols-12 lg:gap-x-8">
            <div class="px-6 pb-8 pt-8 sm:pb-20 lg:col-span-7 lg:px-0 lg:pb-40 lg:pt-20 xl:col-span-6">
                <div class="mx-auto max-w-2xl lg:mx-0">
                    <h1 class="text-center sm:text-left mt-4 sm:mt-8 text-4xl font-bold  text-gray-900 sm:text-5xl"
                    data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">Supporting Mental Health Across Organisations</h1>
                    <p class="text-center sm:text-left mt-4 text-lg text-gray-600 sm:text-xl"
                    data-aos="zoom-in" data-aos-once="true" data-aos-duration="1000">From educational institutions to growing businesses and mission-driven nonprofits, Youlio allows me to partner with your organisation to deliver mental health support where it’s needed most.</p>               
                </div>
            </div>
            <div class="py-8 sm:pt-8 relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                <div class="py-8 px-8 sm:p-16 relative w-full h-0" style="padding-bottom:40%;">
                    <img class=" absolute inset-0 w-full h-full object-cover object-center" src="/img/website/landing-hero-organisation.jpg" alt=""
                    data-aos="slide-right" data-aos-once="true" data-aos-duration="1000" />
                </div>
            </div>
        </div>
    </section>

<!-- SECTION-What Organisations Do I Support --> 
  
      <section>   

        <BlockThreeCards
      :card1="{
        icon: AcademicCapIcon, 
        title: 'Schools and Universities', 
        description: 'Supporting students and staff with targeted mental health programs that enhance well-being and resilience across your institution.', 
        link: 'organisations/schools-universities', 
        ctaText: 'Learn More', 
        bgColor: 'primary-100'
      }"
      :card2="{
        icon: BuildingOffice2Icon, 
        title: 'Startups and Small Businesses', 
        description: 'Helping teams navigate the challenges of growth with mental health strategies that foster productivity, creativity, and balance.', 
        link: '/organisations/startups-small-businesses', 
        ctaText: 'Learn More', 
        bgColor: 'gray-100'
      }"
      :card3="{
        icon: BuildingLibraryIcon, 
        title: 'Charities and Non-profits', 
        description: 'Empowering those who give back by providing accessible mental health support that aligns with your mission and values.', 
        link: '/organisations/charities-nonprofits', 
        ctaText: 'Learn More', 
        bgColor: 'secondary-100'
      }"
    />
    </section> 

    <img class="" src="img/website/wave-white-1.jpg" alt="Youlio logo" />


<!-- SECTION-Introduction Video -->
<section class="px-6 sm:px-6 max-w-7xl mx-auto pt-8 pb-12 sm:pt-8 sm:pb-20">
  <div class="grid grid-cols-1 lg:grid-cols-2 lg:gap-8">
    <!-- Left side (Video) -->
    <div class="flex justify-center items-center">
      <img class="" src="/img/website/me-4.jpg" alt="Youlio logo" />
    </div>
    <!-- Right side (TypingEffect and Text) -->
    <div class="flex flex-col justify-center">
      <TypingEffect class="py-4 sm:py-8" />
      <div class="max-w-3xl rounded-2xl py-2 sm:py-4 sm:pr-24">
        <p class="text-left ws-text-body-base text-gray-700">
          "With Youlio, I work directly with organisations to create tailored mental health solutions that align with your unique needs. Together, we can build a supportive environment that prioritises well-being and drives meaningful change."
          <span class="text-xs font-semibold text-primary">(MSc PGDip MBACP GMBPsS)</span>
        </p>
      </div>
    </div>
  </div>
</section>

<!-- SECTION-How Youlio Supports Your Journey -->
        <Wave colorClass="fill-white" shadow position="down" /> 
        <BlockSwiper
        :slides="4"
        overline="Streamlined Support"
        headline="Optimising Well-being with Youlio"
        subheadline="Youlio helps me deliver tailored mental health support more effectively. The platform’s integrated tools streamline interactions, track progress, and offer continuous support for diverse needs in various settings."
>
        <template #slide-0>
          <BlockContentInSwiper
        headline="Smooth Sailing Sessions"
        subheadline="Youlio makes therapy sessions a breeze! The platform handles all of our sessions, making it super easy for us to connect."
        imageSrc="/img/website/landing-features-1.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-1>
          <BlockContentInSwiper
        headline="Messaging"
        subheadline="You can reach out to me anytime with our messaging feature, so you're never alone on your journey."
        imageSrc="/img/website/landing-features-2.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-2>
          <BlockContentInSwiper
        headline="Personalised Support"
        subheadline="With Youlio, I can send you readings, questionnaires, and activities that are completely tailored towards you."
        imageSrc="/img/website/landing-features-3.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        <template #slide-3>
          <BlockContentInSwiper  
        headline="Progress Tracking"
        subheadline="Youlio enables us to monitor your journey throughout treatment, keeping us focused on the areas that matter most."
        imageSrc="/img/website/landing-features-4.jpg"
        bgClass="bg-white"
        theme="light"
        layout="image-right"
        paddingTopClass="pt-4 sm:pt-4 md:pt-4"
        paddingBottomClass="pb-8 sm:pb-8 md:pb-8"
        />
        </template>
        </BlockSwiper>
   
<!-- SECTION-How I Support Your Organisation
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockContent
      overline="Here For You "
      headline="How I Support Your Organisation"
      subheadline="As a licensed therapist with extensive experience, I’m committed to helping your organisation create a mentally healthy environment. Through Youlio, I offer personalised guidance tailored to your organisations unique needs, providing expert support in every session. My goal is to be a reliable partner in fostering well-being, helping your organisation to achieve a healthier, more resilient culture."
      imageSrc="/img/website/me-3.jpg"
      bgClass="bg-white"
      theme="light"
      layout="image-right"
      /> -->
      
 <!-- SECTION-Why Choose Youlio -->
 <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockWhyChoose
      overline="Personalised Support"
      headline="Why Choose Youlio?"
      subheadline="Combining my therapeutic expertise with Youlio's innovative platform creates a simple, highly effective therapy experience tailored specifically to your organisation’s needs."
      listImageSrc="/img/website/for-organisations-why-choose-youlio.jpg"
      listImageAlt="Why Choose Us"
      :listItems="[
        { title: 'Flexible and Accessible', description: 'Access therapy and mental health programs anytime, anywhere—perfect for modern organisations and remote teams.' },
        { title: 'Comprehensive Support', description: 'From one-off sessions to ongoing programs, Youlio covers all your mental health needs in a single, secure platform.' },
        { title: 'Personalised Approach', description: 'Youlio enables me to deliver flexible, responsive, and personalised mental health support.' },
      ]"
       />

  <!-- SECTION-CTA -->
      <Wave colorClass="fill-white" shadow position="down" /> 
      <BlockCTA    
      heading="Bring Youlio to Your Organisation"
      message="Ready to explore how Youlio can help your organisation? Reach out today to find out more"
      primaryButtonText="Get In Contact"
      primaryButtonLink="/contact"
      imageSrc="/img/website/for-organisations-cta.jpg"
      bgClass="bg-white"
      theme="light"
      />

  </div>
</template>

<script>
export default {
  name: "ForOrganisationsPage",
};
</script>

<script setup>
      
import BlockCTA from '../../website/components/BlockCTA' ;
import Wave from '../../website/components/Wave' ;
import BlockHero from '../../website/components/BlockHero' ;
import BlockWhyChoose from '../../website/components/BlockWhyChoose' ;
import BlockThreeCards from '../../website/components/BlockThreeCards' ;
import BlockContent from '../../website/components/BlockContent' ;
import BlockContentInSwiper from '../../website/components/BlockContentInSwiper' ;
import BlockSwiper from '../../website/components/BlockSwiper' ;
import BlockTestimonial from '../../website/components/BlockTestimonial' ;
import BlockStructureTitle from '../../website/components/BlockStructureTitle' ;
import { AcademicCapIcon, BuildingLibraryIcon, BuildingOffice2Icon, KeyIcon, CalendarIcon, CheckIcon, ClipboardIcon } from '@heroicons/vue/24/outline'
import { ChevronRightIcon } from '@heroicons/vue/20/solid' 
import TypingEffect from '../../website/components/TypingEffect' ;

</script>
