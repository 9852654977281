<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Symptom List -->
      <ion-list lines="full" v-if="getSymptoms.length > 0" class="my-8">
        <ion-item v-for="symptom in getSymptoms" :key="symptom.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ symptom.symptom }}</h3>
            <p class="text-muted">Category: {{ symptom.category }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditSymptomModal(symptom)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteSymptom(symptom.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No symptoms have been recorded yet." />
      </div>

      <!-- Add Symptom Button -->
      <ion-button expand="block" @click="openAddSymptomModal">Add Symptom</ion-button>

      <!-- Add/Edit Symptom Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Symptom' : 'Add Symptom' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitSymptom">
            <div class="ion-margin-bottom">
              <ion-label>Symptom</ion-label>
              <ion-input v-model="newSymptom.symptom" placeholder="Enter symptom" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Category</ion-label>
              <ion-select v-model="newSymptom.category" placeholder="Select category" required>
                <ion-select-option v-for="category in categories" :key="category" :value="category">
                  {{ category }}
                </ion-select-option>
              </ion-select>
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Symptom' : 'Add Symptom' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentSymptomId = ref(null);
const newSymptom = ref({ symptom: '', category: '' });
const categories = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch symptoms from Vuex store
const getSymptoms = computed(() => store.getters['symptoms/getSymptoms'].slice().reverse());

// Fetch symptoms on mount
onMounted(() => {
  store.dispatch('symptoms/fetchSymptoms');
});

// Open modal for adding a new symptom
const openAddSymptomModal = () => {
  isEditing.value = false;
  newSymptom.value = { symptom: '', category: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing symptom
const openEditSymptomModal = (symptom) => {
  isEditing.value = true;
  newSymptom.value = { symptom: symptom.symptom, category: symptom.category };
  currentSymptomId.value = symptom.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newSymptom.value = { symptom: '', category: '' }; // Reset form
  currentSymptomId.value = null;
};

// Add or update a symptom
const submitSymptom = () => {
  if (newSymptom.value.symptom && newSymptom.value.category) {
    if (isEditing.value) {
      store.dispatch('symptoms/updateSymptom', { id: currentSymptomId.value, ...newSymptom.value });
    } else {
      store.dispatch('symptoms/addSymptom', { ...newSymptom.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete a symptom
const deleteSymptom = (id) => {
  store.dispatch('symptoms/deleteSymptom', id);
};
</script>