<template>
  <HeaderComponent :pageTitle="'My Notes'" />

  <div class="overflow-x-hidden">
    <main class="main-layout" data-aos="slide-left" data-aos-once="true" data-aos-duration="250">
      <!-- Create Note Button -->
      <button @click="openCreateNoteModal" class="bg-primary text-white p-4 rounded-full shadow-md mb-4">
        <PencilIcon class="h-7 w-7" aria-hidden="true" />
      </button>

      <!-- Display list of notes -->
     
      <div class="bg-white px-4 rounded-xl shadow-md max-w-lg">
        <ul class="mt-4 relative"> <!-- Changed to relative for positioning of the trash icon -->
          <li v-for="note in notes" :key="note.id" class="border-b py-4 flex items-center justify-between">
            <div class="flex-1 pr-10"> <!-- Added padding right for space -->
              <div class="text-primary font-medium text-xs">{{ formatDate(note.created_at) }}</div>
              <RouterLink :to="{ name: 'UserNoteDetail', params: { id: note.id } }" class="text-gray-700 hover:underline">
                <div v-html="note.content" class="line-clamp-1 max-w-xs" />
              </RouterLink>
            </div>
            <!-- Separate container for the trash icon -->
            <div class="ml-2 relative">
              <button @click="openModal(note.id)" class="absolute right-0 top-1/2 transform -translate-y-1/2 text-gray-600 hover:text-red-700">
                <TrashIcon class="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </li>
        </ul>
      </div>

      <!-- Confirmation Modal for Deleting Note -->
      <ConfirmationModal
        :isOpen="isModalOpen"
        :onConfirm="confirmDelete"
        :onClose="closeModal"
        title="Delete Note"
        message="Are you sure you want to delete this note?"
      />

      <!-- Create Note Modal -->
      <CreateNoteModal
        :isOpen="isCreateNoteModalOpen"
        :onClose="closeCreateNoteModal"
        :onCreate="addNote"
      />
    </main>
  </div>
</template>

<script setup>
import { onMounted, ref } from 'vue'; // Import onMounted
import { TrashIcon, PencilIcon } from '@heroicons/vue/24/outline';
import HeaderComponent from '../components/HeaderComponent';
import CreateNoteModal from '../components/CreateNoteModal'; // Import your new modal
import ConfirmationModal from '../components/ConfirmationModal';
import axios from 'axios';

const notes = ref([]);
const isModalOpen = ref(false);
const isCreateNoteModalOpen = ref(false); // State for the create note modal
const noteIdToDelete = ref(null);

async function fetchNotes() {
  try {
    const response = await axios.get('/api/user-notes');
    notes.value = response.data;
  } catch (error) {
    console.error("Error fetching notes:", error);
  }
}

async function addNote(content) {
  if (!content.trim()) return; // Prevent empty notes
  try {
    const response = await axios.post('/api/user-notes', { content });
    notes.value.unshift(response.data); // Add the new note to the beginning of the list
  } catch (error) {
    console.error("Error adding note:", error);
  }
}

function openModal(noteId) {
  isModalOpen.value = true;
  noteIdToDelete.value = noteId;
}

function closeModal() {
  isModalOpen.value = false;
  noteIdToDelete.value = null;
}

async function confirmDelete() {
  try {
    await axios.delete(`/api/user-notes/${noteIdToDelete.value}`);
    await fetchNotes(); // Re-fetch notes after deletion
    closeModal();
  } catch (error) {
    console.error("Error deleting note:", error);
  }
}

function openCreateNoteModal() {
  isCreateNoteModalOpen.value = true;
}

function closeCreateNoteModal() {
  isCreateNoteModalOpen.value = false;
}

// Format date to display only the date
function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Use toLocaleDateString to get the date only
}

// Fetch notes on mount
onMounted(() => {
  fetchNotes();
});
</script>

<style scoped>
/* Your existing styles can stay here */
</style>