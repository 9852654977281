<template>
   <ion-page>

    <ion-content>
  
<!-- Text for session starting -->
<div v-if="waitingRoom && session">
  
      <div v-if="!sessionStarted && !sessionEnded">
        
        <div class="flex items-center">
        <button @click="goBack">      
        <ChevronLeftIcon class="chevron-dark -ml-2" />
        </button>

          <h3 class="text-white font-normal text-xl">Waiting Room</h3>
        </div> 

        <p class="pt-2 text-center text-white font-normal text-base">Get ready, this {{ session_type }} will begin in <span class="text-primary-400 font-semibold"> {{ timeTillSessionStart }}</span>
        </p>
  
      </div>

<!-- Text for session already started -->
      <div v-if="sessionStarted && !sessionEnded">
        <div class="flex items-center">
        <go-back-dark class="flex items-center " /> 
        <h3 class="text-white font-normal text-xl">Waiting Room</h3>
      </div>
        <p class="pt-2 text-white font-normal text-base">This {{ session_type }} has already begun, but there is still <span class="text-primary font-semibold"> {{ timeTillSessionEnd }}</span> left.</p>
      </div>
<!-- Text for session has finished -->
      <div v-if="sessionEnded">
        This {{ session_type }} has now finished. Perhaps take a moment to reflect on what came up.
      </div>
    </div>
<!-- Timer bar for the therapist -->
    <div v-else>
      <div v-if="isTherapist">
        <div class="flex flex-row">
          <div class="w-16 mx-auto">
            {{ timeTillSessionEnd }}
          </div>
          <div class="w-full bg-gray-200 rounded-full h-2.5 my-auto">
            <div class="bg-primary-600 h-2.5 rounded-full" :style="{width: remainingSessionTimePercentage + '%'}"></div>
          </div>
        </div>
      </div>
    </div>

<!-- Header -->
    <div class=" mt-4 md:mt-0 flex justify-center items-center flex-wrap gap-2 z-50">
<!-- Mute toggle button -->
      <button v-if="!waitingRoom" @click="toggleTrack('audio')" class="bg-secondary-200 text-gray-800 py-2 px-3 rounded-lg inline-flex items-center">
        <MicrophoneIcon class="mr-1 w-5 h-auto text-gray-800 dark:text-white" aria-hidden="true" />   
        <span>{{ trackButtonText.audio }}</span>
      </button>
<!-- Camera toggle button -->
      <button v-if="!waitingRoom" @click="toggleTrack('video')" class="bg-primary-300 text-gray-800 py-2 px-3 rounded-lg inline-flex items-center">
        <VideoCameraIcon class="mr-1 w-5 h-auto text-gray-800 dark:text-white" aria-hidden="true" />
        <span>Camera {{ trackButtonText.video }}</span>
      </button>
<!-- Enter session button -->
      <button v-if="waitingRoom" :disabled="isLoading || sessionEnded" @click="connectToRoom" type="button" 
      class="md:max-w-md text-white text-center bg-primary hover:bg-primary-500 font-medium rounded-lg text-base px-5 py-2 mr-2 inline-flex justify-center items-center">
        <p class="text-center">Enter Therapy Room</p>
        <ArrowPathIcon v-if="isLoading" role="status" class="inline w-5 h-auto ml-3 text-white animate-spin" aria-hidden="true" />
        <ArrowRightOnRectangleIcon v-else class="w-7 h-auto ml-2 text-white dark:text-white" aria-hidden="true" />
      </button>
<!-- Exit session button -->    
      <button v-else @click="disconnect" class="bg-gray-200 hover:bg-gray-400 text-gray-800 py-2 px-3 rounded-lg inline-flex items-center">
        <ArrowLeftOnRectangleIcon class="mr-1 w-5 h-auto text-gray-800 dark:text-white" aria-hidden="true" />
        <span>Exit {{ session_type }}</span>
      </button>
<!-- END Header -->    
    </div>

<!-- Camera screens -->
    <div ref="chatContainer" class="mt-4 flex flex-wrap justify-center gap-1 max-h-screen" style="height: calc(-72px + 100vh);">
      <div id="remote-media-div" class="relative w-full h-full max-h-screen">
        <!-- Video will be dynamically inserted here -->
      </div>
      <div id="local-media-div" :class="[!waitingRoom && participantConnected ? 'twilio-local-video' : 'twilio-local-video-waiting']">
        <!-- Video will be dynamically inserted here -->
      </div>
    </div>
  </ion-content>
</ion-page>
</template>

<script setup>

  import { ChevronLeftIcon, ArrowPathIcon, VideoCameraIcon, MicrophoneIcon, ArrowRightOnRectangleIcon, ArrowLeftOnRectangleIcon } from '@heroicons/vue/24/outline'
  import { IonPage, IonIcon, IonContent, IonButton, IonImg, IonCard, IonCardHeader, IonCardContent, IonLabel, IonList, IonItem } from '@ionic/vue';
  import { sendPost } from '../components/entities/functions.js';
  import Video from 'twilio-video';
  import { useRouter } from 'vue-router';

const router = useRouter()

    function goBack() {
        return router.go(-1)
}

</script>

<script>
let roomInstance = null;
let localTracks = [];

export default {
  data () {
    return {
      registrantToken: '',
      token: null,
      name: null,
      session: null,
      audioEnable: true,
      videoEnable: true,
      localVideoAspectRatio: 1,
      remoteVideoAspectRatio: 1,
      trackButtonText: {
        audio: 'Mute',
        video: 'Off'
      },
      waitingRoom: true,
      isLoading: false,
      isTherapist: false,
      sessionStartTime: null,
      sessionEndTime: null,
      timerInterval: null,
      sessionStarted: false,
      sessionEnded: false,
      timeTillSessionStart: '',
      timeTillSessionEnd: '',
      remainingSessionTimePercentage: 0,
      participantConnected: false,
    }
  },

  watch: {
    participantConnected(value) {
      if (value && this.session.status !== 'active') {
        this.session.status = 'active';
        sendPost('/api/video/set-session-status', {
          'id': this.session.id,
          'status': 'active'
        });
      }
    }
  },

  computed: {
    session_type() {
      return this.session.is_consultation ? 'consultation' : 'session';
    }
  },

  mounted() {
    this.getSession();

    var audioOptions = { name: 'microphone' };
    var videoOptions = { name: 'camera' };

    let audioTrackPromise = Video.createLocalAudioTrack(audioOptions).catch(error => {
      console.error(`Failed to create audio track: ${error.message}`);
    });

    let videoTrackPromise = Video.createLocalVideoTrack(videoOptions).catch(error => {
      console.error(`Failed to create video track: ${error.message}`);
    });

    Promise.all([audioTrackPromise, videoTrackPromise]).then(([audioTrack, videoTrack]) => {
      if (audioTrack) {
        localTracks.push(audioTrack);
      }
      if (videoTrack) {
        localTracks.push(videoTrack);
        this.adjustChatContainerHeight();
      }
      localTracks.forEach(localTrack => {
        document.getElementById('local-media-div').appendChild(localTrack.attach());
        this.addVideoSizeListener('#local-media-div video');
      });
    })
    .catch(error => {
      console.error(`Unable to connect to Room: ${error.message}`);
    });
  },

  beforeUnmount() {
    const localVideo = document.querySelector('#local-media-div video');
    const remoteVideo = document.querySelector('#remote-media-div video');

    if (localVideo) {
      localVideo.removeEventListener('loadedmetadata', this.setVideoAspectRatio);
    }
    if (remoteVideo) {
      remoteVideo.removeEventListener('loadedmetadata', this.setVideoAspectRatio);
    }

    this.disconnect();

    if (localTracks) {
      localTracks.forEach(track => track.stop());
      localTracks = [];
    }

    if (this.timerInterval) {
      clearInterval(this.timerInterval);
    }
  },

  methods: {
    toggleTrack(trackType) {
      const enable = this[`${trackType}Enable`] = !this[`${trackType}Enable`];
      const action = enable ? 'enable' : 'disable';
      const buttonText = enable ? (trackType === 'audio' ? 'Mute' : 'Off') : (trackType === 'audio' ? 'Unmute' : 'On');

      roomInstance.localParticipant[`${trackType}Tracks`].forEach(publication => {
        publication.track[action]();
      });

      this.trackButtonText[trackType] = buttonText;
    },

    getSession() {
      sendPost('/api/video/get-session').then((response) => {
        if (!response || response.status === 'fail') {
          return;
        }
        this.session = response.session;
        this.isTherapist = response.is_therapist;

        let sessionStartDateTime = new Date(this.session.scheduled_time);
        this.sessionStartTime = sessionStartDateTime.getTime();
        this.sessionEndTime = this.sessionStartTime + this.session.duration * 1000;

        this.setTimer();
      });
    },

    setTimer() {
      this.timerTick();
      this.timerInterval = setInterval(() => {
        this.timerTick();
      }, 1000);
    },

    timerTick() {
      let now = Date.now();

      this.sessionStarted = now >= this.sessionStartTime;
      this.sessionEnded = now >= this.sessionEndTime;

      let remainingTimeStart = Math.ceil((this.sessionStartTime - now) / 1000);
      this.timeTillSessionStart = this.formatTime(remainingTimeStart);

      let remainingTimeEnd = Math.ceil((this.sessionEndTime - now) / 1000);
      this.timeTillSessionEnd = this.formatTime(remainingTimeEnd);
      this.remainingSessionTimePercentage = ((this.session.duration * 60 - remainingTimeEnd) / (this.session.duration * 60) * 100).toFixed(1);

      if (this.sessionEnded) {
          clearInterval(this.timerInterval);
      }
    },

    formatTime(seconds) {
      let minutes = Math.floor(seconds / 60);
      seconds %= 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },

    async connectToRoom() {
      this.isLoading = true;

      let response = await sendPost('/api/video/get-access-token')
      if (!response || response.status !== 'ok') {
        this.isLoading = false;
        console.error(`Unable to get access token: ${error.message}`);
        return;
      }

      this.token = response.access_token;
      this.name = response.name;

      Video.connect(this.token, {
        name: this.name,
        tracks: localTracks,
      }).then(room => {
        console.log(`Successfully joined a Room: ${room}`);
        roomInstance = room;
        this.waitingRoom = false;
        this.isLoading = false;

        window.addEventListener('beforeunload', () => {
          room.disconnect();
        });

        room.participants.forEach(participant => {
          console.log(`Participant connected: ${participant.identity}`);
          this.participantConnected = true;
          participant.tracks.forEach(publication => {
            if (publication.track) {
              document.getElementById('remote-media-div').appendChild(publication.track.attach());
              this.addVideoSizeListener('#remote-media-div video');
            }
          });

          participant.on('trackSubscribed', track => {
            document.getElementById('remote-media-div').appendChild(track.attach());
            this.addVideoSizeListener('#remote-media-div video');
          });

          participant.on('trackUnsubscribed', track => {
            track.detach().forEach(element => element.remove());
          });
        });

        roomInstance.on('participantConnected', participant => {
          console.log(`Participant "${participant.identity}" connected`);
          this.participantConnected = true;
          // Add this participant's existing tracks to the DOM
          participant.tracks.forEach(publication => {
            if (publication.isSubscribed) {
              const track = publication.track;
              document.getElementById('remote-media-div').appendChild(track.attach());
              this.addVideoSizeListener('#remote-media-div video');
            }
          });

          // When a track is subscribed to, add it to the DOM
          participant.on('trackSubscribed', track => {
            document.getElementById('remote-media-div').appendChild(track.attach());
            this.addVideoSizeListener('#remote-media-div video');
          });

          // When a track is unsubscribed from, remove it from the DOM
          participant.on('trackUnsubscribed', track => {
            track.detach().forEach(element => element.remove());
          });
        });

        roomInstance.on('disconnected', (room, error) => {
          this.waitingRoom = true;
          this.participantConnected = false;
          if (error) {
            console.error(`Disconnected from Room ${room.name} with error: ${error.message}`);
          } else {
            console.log(`Disconnected from Room ${room.name}`);
          }
        });

        roomInstance.on('participantDisconnected', participant => {
          this.participantConnected = false;
          console.log(`Participant disconnected: ${participant.identity}`);
          participant.tracks.forEach(publication => {
            if (publication.track) {
              publication.track.detach().forEach(element => element.remove());
            }
          });
        });
      }).catch(error => {
        this.isLoading = false;
        console.error(`Unable to connect to Room: ${error.message}`);
      });
    },

    async disconnect() {
      if (roomInstance && roomInstance.state === "connected") {
        roomInstance.disconnect();
        roomInstance = null;
        this.waitingRoom = true;
      }

      if (this.sessionEnded && this.isTherapist) {
        await sendPost('/api/video/set-session-status', {
          'id': this.session.id,
          'status': 'completed'
        });
      }

      this.$router.push({ name: 'Sessions' });
    },

    adjustChatContainerHeight() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        const offsetTop = chatContainer.getBoundingClientRect().top;
        chatContainer.style.height = `calc(100vh - ${offsetTop}px)`;
      });
    },

    addVideoSizeListener(selector) {
      this.$nextTick(() => {
        const video = document.querySelector(selector);
        if (video) {
          video.addEventListener('loadedmetadata', this.setVideoAspectRatio);
        }
      });
    },
  },
}
</script>
