import axios from "axios";

export const state = {
  emotionalstates: [],
};

export const mutations = {
  setEmotionalstates: (state, emotionalstates) => (state.emotionalstates = emotionalstates),
  addEmotionalstate: (state, emotionalstate) => state.emotionalstates.push(emotionalstate),
  removeEmotionalstate: (state, id) =>
    (state.emotionalstates = state.emotionalstates.filter((es) => es.id !== id)),
};

export const actions = {
  // Fetch all emotionalstates
  async fetchEmotionalstates({ commit }) {
    try {
      const response = await axios.get("/api/emotionalstates");
      commit("setEmotionalstates", response.data.emotionalstates);
    } catch (error) {
      alert("Failed to fetch emotionalstates.");
      console.error(error);
    }
  },

  // Add a new emotionalstate
  async addEmotionalstate({ commit }, emotionalstate) {
    try {
      const response = await axios.post("/api/emotionalstates", emotionalstate);
      commit("addEmotionalstate", response.data.emotionalstate); // Assuming the response contains the newly created emotionalstate
    } catch (error) {
      alert("Failed to add emotionalstate.");
      console.error(error);
    }
  },

  // Update an existing emotionalstate
  async updateEmotionalstate({ commit, state }, updatedEmotionalstate) {
    try {
      const response = await axios.put(
        `/api/emotionalstates/${updatedEmotionalstate.id}`,
        updatedEmotionalstate
      );
      
      // Find the index of the emotionalstate being updated
      const index = state.emotionalstates.findIndex((emotionalstate) => emotionalstate.id === updatedEmotionalstate.id);
      
      if (index !== -1) {
        // Replace the updated emotionalstate in the array
        state.emotionalstates[index] = response.data.emotionalstate;
      }

    } catch (error) {
      alert("Failed to update emotionalstate.");
      console.error(error);
    }
  },

  // Delete an emotionalstate
  async deleteEmotionalstate({ commit }, id) {
    try {
      await axios.delete(`/api/emotionalstates/${id}`);
      commit("removeEmotionalstate", id);
    } catch (error) {
      alert("Failed to delete emotionalstate.");
      console.error(error);
    }
  },
};

export const getters = {
  getEmotionalstates(state) {
    return state.emotionalstates;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};