import axios from "axios";

export const state = {
  symptoms: [],
};

export const mutations = {
  setSymptoms: (state, symptoms) => (state.symptoms = symptoms),
  addSymptom: (state, symptom) => state.symptoms.push(symptom),
  removeSymptom: (state, id) =>
    (state.symptoms = state.symptoms.filter((s) => s.id !== id)),
};

export const actions = {
  // Fetch all symptoms
  async fetchSymptoms({ commit }) {
    try {
      const response = await axios.get("/api/symptoms");
      commit("setSymptoms", response.data.symptoms);
    } catch (error) {
      alert("Failed to fetch symptoms.");
      console.error(error);
    }
  },

  // Add a new symptom
  async addSymptom({ commit }, symptom) {
    try {
      const response = await axios.post("/api/symptoms", symptom);
      commit("addSymptom", response.data.symptom); // Assuming the response contains the newly created symptom
    } catch (error) {
      alert("Failed to add symptom.");
      console.error(error);
    }
  },

  // Update an existing symptom
  async updateSymptom({ commit, state }, updatedSymptom) {
    try {
      const response = await axios.put(
        `/api/symptoms/${updatedSymptom.id}`,
        updatedSymptom
      );

      // Find the index of the symptom being updated
      const index = state.symptoms.findIndex((symptom) => symptom.id === updatedSymptom.id);

      if (index !== -1) {
        // Replace the updated symptom in the array
        state.symptoms[index] = response.data.symptom;
      }

      // Alternatively, commit a mutation that updates the symptom directly
      // commit('updateSymptom', response.data.symptom); // if you have an 'updateSymptom' mutation

    } catch (error) {
      alert("Failed to update symptom.");
      console.error(error);
    }
  },

  // Delete a symptom
  async deleteSymptom({ commit }, id) {
    try {
      await axios.delete(`/api/symptoms/${id}`);
      commit("removeSymptom", id);
    } catch (error) {
      alert("Failed to delete symptom.");
      console.error(error);
    }
  },
};

export const getters = {
  getSymptoms(state) {
    return state.symptoms;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};