<template>
  <HeaderComponent :pageTitle="'Values Finder'" />
  <main
    class="min-h-screen overflow-x-hidden px-4 pt-24 sm:pt-48 pb-24 md:pb-8"
    data-aos="slide-left"
    data-aos-once="true"
    data-aos-duration="250"
  >
    <!-- Back Button (Chevron Icon Only) -->
    <div v-if="currentStep > 0" class="flex justify-start mb-2">
      <button
        @click="goBack"
        :disabled="currentStep === 0"
        class="p-0.5 stroke-2 border bg-white text-gray-700 flex rounded-full items-center gap-2 hover:text-gray-800"
        data-aos="fade-down"
        data-aos-duration="300"
      >
        <ChevronLeftIcon class="h-6 w-6" />
      </button>
    </div>

    <!-- Current Step -->
    <div
      class="border w-full rounded-xl shadow-sm max-w-md px-4 py-8 bg-white"
      data-aos="fade-up"
      data-aos-duration="400"
    >
      <!-- Step 0: Welcome Screen -->
      <div v-if="currentStep === 0" class="intro" data-aos="fade-in" data-aos-duration="600">
        <h1 class="px-6 leading-8 text-center text-gray-700 font-semibold text-2xl">
          Welcome to the Values Finder
        </h1>
        <br />
        <p class="px-2 text-gray-600 font-normal text-base">
          This tool helps you identify your values, which is the first step toward aligning your actions and decisions with what truly matters to you.
          <br /><br />
          Understanding your core values can help you feel more purposeful and make better choices in life.
        </p>
        <br />
        <div class="text-center mx-auto pt-2 pb-6">
          <button
            class="font-semibold text-white btn-ff-primary rounded-md py-3 w-full"
            @click="goForward"
            data-aos="zoom-in"
            data-aos-duration="300"
          >
            Let’s Begin
          </button>
        </div>
      </div>

<!-- Step 1: Value Category -->
<template v-if="currentStep === 1">
  <h2
    class="text-xl text-gray-700 font-light text-center pt-4 pb-4"
    data-aos="fade-up"
    data-aos-duration="300"
  >
    Select the top-level category that best describes your values. We’ll narrow it down further.
  </h2>
  <div 
    class="flex flex-wrap gap-4 justify-center"
    data-aos="fade-up"
    data-aos-delay="100"
    data-aos-duration="500"
  >
    <button
      v-for="(type, index) in Object.keys(valuesData || {})"
      :key="index"
      class="font-semibold text-gray-700 bg-white border py-4 px-6 max-w-[200px]"
      @click="selectValueType(type)"
      data-aos="fade-right"
      :data-aos-delay="200 + index * 100"
    >
      {{ type }}
    </button>
  </div>
</template>

      <!-- Step 2: Specific Value -->
      <template v-if="currentStep === 2">
        <h2
          class="text-xl text-gray-700 font-light text-center mb-4"
          data-aos="fade-up"
          data-aos-duration="300"
        >
          Now, choose a specific value within the category you selected.
        </h2>
        <div class="grid grid-cols-2 gap-2" data-aos="fade-in" data-aos-duration="400">
          <button
            v-for="value in valuesByCategory"
            :key="value"
            class="w-full h-12 bg-white border font-semibold text-base text-gray-800 text-center flex items-center justify-center hover:bg-blue-600"
            @click="selectSpecificValue(value)"
            data-aos="zoom-in"
            data-aos-delay="100"
          >
            {{ value }}
          </button>
        </div>
      </template>

      <!-- Step 4: Reflection -->
      <template v-if="currentStep === 3">
        <h2
          class="text-xl font-light text-center mb-4"
          data-aos="fade-in"
          data-aos-duration="300"
        >
          I value
          <span class="font-bold text-gray-800">
            {{ values.specific || '...' }}
          </span>
          because...
        </h2>
        
        <textarea
          v-model="values.reflection"
          class="w-full p-4 border border-gray-300 rounded focus:outline-none focus:ring focus:ring-blue-300"
          rows="6"
          placeholder="Start typing..."
          data-aos="zoom-in"
          data-aos-duration="500"
        ></textarea>
        
        <div class="flex justify-between mt-4 gap-4">
          <button class="btn-ff-primary w-1/2" @click="completeCheckIn">Skip</button>
          <button class="btn-ff-primary w-1/2" @click="completeCheckIn">Finish</button>
        </div>
      </template>

      <!-- Step 5: Add Another Value -->
      <template v-if="currentStep === 4">
        <div key="step-5" data-aos="fade-in" data-aos-duration="300">
          <h2 class="text-xl text-gray-700 font-light text-center mb-8">
            Would you like to add another value?
          </h2>
          <div class="flex justify-between" data-aos="zoom-in" data-aos-delay="150">
            <button class="btn-ff-primary w-24" @click="addAnotherValue">Yes</button>
            <button class="btn-ff-primary w-24" @click="finalComplete">No</button>
          </div>
        </div>
      </template>

      <!-- Step 5: Summary -->
      <template v-if="currentStep === 5">
        <div key="step-5" data-aos="fade-in" data-aos-duration="300">
          <h2 class="text-xl font-semibold text-center mb-4">
            Your Values Summary
          </h2>
          <ul class="list-none">
            <li
              v-for="(value, index) in valuesList"
              :key="index"
              class="mb-4"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <div class="p-4 border rounded bg-gray-50">
                <h3 class="text-lg font-light text-gray-700">
                  I value
                  <span class="font-bold text-gray-800">
                    {{ value.specific }}
                  </span>
                </h3>
                <p class="text-gray-800 text-base mt-2">
                  This is because {{ value.reflection || '(no reflection added)' }}
                </p>
              </div>
            </li>
          </ul>
          <button
            class="btn-ff-primary w-full mt-4"
            @click="resetProcess"
            data-aos="fade-in"
            data-aos-duration="300"
          >
            Finish
          </button>
        </div>
      </template>

    </div>
  </main>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { valuesData } from '../data/valuesData';
import HeaderComponent from '../components/HeaderComponent';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';

// Reactive state
const currentStep = ref(0); // Start with the introduction
const selectedValueType = ref(null); // The selected top-level category
const valuesList = ref([]); // Store all values
const values = ref({
  primary: null,
  specific: null,
  reflection: null,
});

// Computed property to get values for the selected category
const valuesByCategory = computed(() => {
  return valuesData[selectedValueType.value] || [];
});

// Initialize on page load
onMounted(() => {
  currentStep.value = 0; // Show introduction
});

// Step control functions
const goBack = () => {
  currentStep.value = Math.max(0, currentStep.value - 1); // Prevent going below step 0
};

const goForward = () => {
  if (currentStep.value < 5) { // Ensure the step does not go beyond 6
    currentStep.value += 1;
  }
};

// Step handlers
const selectValueType = (type) => {
  selectedValueType.value = type;
  currentStep.value = 2; // Move to step 2 after selecting the category
};

const selectSpecificValue = (value) => {
  values.value.specific = value;
  currentStep.value = 3; // Proceed after selecting a specific value
};

const completeCheckIn = () => {
  if (currentStep.value === 2) {
    currentStep.value = 3; // Add this step to confirm the value before reflection
  } else if (currentStep.value === 3) {
    currentStep.value = 4; // Move to step 4 after confirming the value
  } else if (currentStep.value === 4) {
    currentStep.value = 5; // Move to step 5 after reflection
  }
};

const addAnotherValue = () => {
  // Log to confirm the value being added
  console.log('Adding value:', values.value);
  
  // Add the value to the list
  valuesList.value.push({ ...values.value });
  console.log('Updated valuesList:', valuesList.value);

  // Reset values for the next round
  values.value = { primary: null, specific: null, reflection: null };
  
  // Transition to step 1 to allow selecting a new value
  currentStep.value = 1;
};

const finalComplete = () => {
  if (valuesList.value.length > 0) { // Ensure valuesList has values before moving to summary
    currentStep.value = 5; // Go to the summary step
  }
};
const resetProcess = () => {
  valuesList.value = []; // Reset the values list
  values.value = { primary: null, specific: null, reflection: null };
  currentStep.value = 0; // Reset to the introduction step
};
</script>

<style scoped>
/* Add your custom styles here */
</style>