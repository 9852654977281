<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Values List -->
      <ion-list lines="full" v-if="getValues.length > 0" class="my-8">
        <ion-item v-for="value in getValues" :key="value.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ value.value }}</h3>
            <p class="text-muted">Category: {{ value.category }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditValueModal(value)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteValue(value.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No values have been recorded yet." />
      </div>

      <!-- Add Value Button -->
      <ion-button expand="block" @click="openAddValueModal">Add Value</ion-button>

      <!-- Add/Edit Value Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Value' : 'Add Value' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitValue">
            <div class="ion-margin-bottom">
              <ion-label>Value</ion-label>
              <ion-input v-model="newValue.value" placeholder="Enter value" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Category</ion-label>
              <ion-select v-model="newValue.category" placeholder="Select category" required>
                <ion-select-option v-for="category in categories" :key="category" :value="category">
                  {{ category }}
                </ion-select-option>
              </ion-select>
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Value' : 'Add Value' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentValueId = ref(null);
const newValue = ref({ value: '', category: '' });
const categories = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch values from Vuex store
const getValues = computed(() => store.getters['values/getValues'].slice().reverse());

// Fetch values on mount
onMounted(() => {
  store.dispatch('values/fetchValues');
});

// Open modal for adding a new value
const openAddValueModal = () => {
  isEditing.value = false;
  newValue.value = { value: '', category: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing value
const openEditValueModal = (value) => {
  isEditing.value = true;
  newValue.value = { value: value.value, category: value.category };
  currentValueId.value = value.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newValue.value = { value: '', category: '' }; // Reset form
  currentValueId.value = null;
};

// Add or update a value
const submitValue = () => {
  if (newValue.value.value && newValue.value.category) {
    if (isEditing.value) {
      store.dispatch('values/updateValue', { id: currentValueId.value, ...newValue.value });
    } else {
      store.dispatch('values/addValue', { ...newValue.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete a value
const deleteValue = (id) => {
  store.dispatch('values/deleteValue', id);
};
</script>

<style scoped>
ion-label {
  font-size: 18px;
  color: #333;
}

ion-button {
  --border-radius: 5px;
  --height: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

li .ion-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --height: 35px;
  --width: 35px;
}

li .ion-button .ion-icon {
  --color: white;
}
</style>