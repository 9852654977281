export const valuesData = {
  "Personal Growth": [
    "Self-Improvement",
    "Learning",
    "Creativity",
    "Curiosity",
    "Emotional Intelligence",
    "Adaptability"
  ],
  "Relationships": [
    "Love",
    "Compassion",
    "Communication",
    "Trust",
    "Connection",
    "Empathy"
  ],
  "Career and Success": [
    "Ambition",
    "Leadership",
    "Achievement",
    "Innovation",
    "Independence",
    "Collaboration"
  ],
  "Health and Well-being": [
    "Physical Health",
    "Mental Health",
    "Balance",
    "Wellness",
    "Nutrition",
    "Exercise"
  ],
  "Community and Social Impact": [
    "Giving Back",
    "Service",
    "Social Justice",
    "Volunteering",
    "Environmental Impact",
    "Equality"
  ],
  "Wealth and Abundance": [
    "Financial Freedom",
    "Wealth Creation",
    "Generosity",
    "Security",
    "Prosperity",
    "Gratitude"
  ],
  "Freedom and Independence": [
    "Autonomy",
    "Self-Sufficiency",
    "Adventure",
    "Personal Freedom",
    "Exploration",
    "Flexibility"
  ],
  "Spirituality and Inner Peace": [
    "Mindfulness",
    "Gratitude",
    "Peace of Mind",
    "Faith",
    "Purpose",
    "Connection to Higher Self"
  ],
  "Fun and Enjoyment": [
    "Adventure",
    "Laughter",
    "Playfulness",
    "Exploration",
    "Relaxation",
    "Joy"
  ],
  "Legacy and Impact": [
    "Leaving a Legacy",
    "Mentoring",
    "Influence",
    "Positive Change",
    "Creating History",
    "Inspiration"
  ]
};