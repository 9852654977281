<template>
  <ion-page>
   
      <ion-content>
      <!-- Floating Action Button for Creating a Note -->
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-fab-button @click="openCreateNoteModal" color="primary">
          <ion-icon :icon="pencilOutline"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <!-- Notes List -->
      <ion-list lines="full" class="ion-margin">
        <ion-item
        v-for="note in notes"
        :key="note.id"
        detail="true"
        button
        :router-link="{ name: 'UserNoteDetail', params: { id: note.id } }"
>
  <!-- Note Details -->
  <ion-label class="ion-text-wrap">
    <h3 class="text-sm text-primary">{{ formatDate(note.created_at) }}</h3>
    <p class="line-clamp-1 text-gray-700" v-html="note.content"></p>
  </ion-label>
  <!-- Trash Icon -->
  <ion-icon
  :icon="trashBinOutline"
  slot="end"
  class="text-gray-600 hover:text-red-700"
  @click.stop.prevent="openModal(note.id)" 
></ion-icon>
</ion-item>
      </ion-list>

      <!-- Confirmation Modal -->
      <ConfirmationModal
        :isOpen="isModalOpen"
        :onConfirm="confirmDelete"
        :onClose="closeModal"
        title="Delete Note"
        message="Are you sure you want to delete this note?"
      />

      <!-- Create Note Modal -->
      <CreateNoteModal
        :isOpen="isCreateNoteModalOpen"
        :onClose="closeCreateNoteModal"
        :onCreate="addNote"
      />
    </ion-content>
  </ion-page>
</template>

<script setup>
import { onMounted, ref } from 'vue'; // Import onMounted
import { pencilOutline, trashBinOutline, chevronBackOutline, arrowBackOutline, arrowForwardOutline } from 'ionicons/icons';
import CreateNoteModal from '../components/CreateNoteModal'; // Import your new modal
import ConfirmationModal from '../components/ConfirmationModal';
import axios from 'axios';

import { 
  IonPage, 
  IonContent,
  IonList,
  IonFab,
  IonFabButton, 
  IonIcon,
  IonItem, 
  IonCheckbox, 
  IonLabel, 
  IonInput, 
  IonImg 
  } from '@ionic/vue';

const notes = ref([]);
const isModalOpen = ref(false);
const isCreateNoteModalOpen = ref(false); // State for the create note modal
const noteIdToDelete = ref(null);

async function fetchNotes() {
  try {
    const response = await axios.get('/api/user-notes');
    notes.value = response.data;
  } catch (error) {
    console.error("Error fetching notes:", error);
  }
}

async function addNote(content) {
  if (!content.trim()) return; // Prevent empty notes
  try {
    const response = await axios.post('/api/user-notes', { content });
    notes.value.unshift(response.data); // Add the new note to the beginning of the list
  } catch (error) {
    console.error("Error adding note:", error);
  }
}

function openModal(noteId) {
  isModalOpen.value = true;
  noteIdToDelete.value = noteId;
}

function closeModal() {
  isModalOpen.value = false;
  noteIdToDelete.value = null;
}

async function confirmDelete() {
  try {
    await axios.delete(`/api/user-notes/${noteIdToDelete.value}`);
    await fetchNotes(); // Re-fetch notes after deletion
    closeModal();
  } catch (error) {
    console.error("Error deleting note:", error);
  }
}

function openCreateNoteModal() {
  isCreateNoteModalOpen.value = true;
}

function closeCreateNoteModal() {
  isCreateNoteModalOpen.value = false;
}

// Format date to display only the date
function formatDate(dateString) {
  const date = new Date(dateString);
  return date.toLocaleDateString(); // Use toLocaleDateString to get the date only
}

// Fetch notes on mount
onMounted(() => {
  fetchNotes();
});
</script>

<style scoped>

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.hover\\:text-red-700:hover {
  color: #dc2626;
}

</style>