<template>
  <div class="bg-secondary-100 py-16 sm:py-24 lg:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <h2 class="max-w-2xl text-balance text-3xl font-semibold tracking-tight text-gray-900 sm:text-4xl">
        Boost your mental health! Get tips, updates, and exclusive offers in your inbox.
      </h2>
      <form @submit.prevent="subscribeToList" class="mt-10 max-w-md">
        <div class="flex gap-x-4">
          <label for="email-address" class="sr-only">Email address</label>
          <input 
            type="email" 
            id="email-address" 
            v-model="form.email" 
            class="min-w-0 flex-auto rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm/6"
            placeholder="Enter your email" 
            required 
          />
          <button 
            type="submit" 
            class="flex-none rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Subscribe
          </button>
        </div>
        <p class="mt-4 text-sm/6 text-gray-800">
          We care about your data. Read our 
          <a href="/privacy" class="font-semibold text-primary hover:text-primary-500">privacy policy</a>.
        </p>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        email: '', // No name field anymore
      },
    };
  },
  methods: {
    async subscribeToList() {
      try {
        const response = await fetch('/api/subscribe', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ email: this.form.email }), // Only send email
        });
        const result = await response.json();
        if (response.ok) {
          alert(result.message);
          this.form.email = ''; // Reset the email field
        } else {
          alert(result.error || 'Subscription failed!');
        }
      } catch (error) {
        alert('An error occurred. Please try again later.');
      }
    },
  },
};
</script>