<template>
<ion-page>

<Header :title="'Support'" :default-href="'/test-home'" />

  <ion-content class="ion-padding">


    
    <h3 class="mt-4 text-display-base">
     Support
    </h3>
    <p class="mb-4 mt-1 text-body-base">
     Support coming soon.
    </p>

</ion-content>
</ion-page>

  <!-- Coming soon 
      <form action="#" method="POST">         

          <SwitchGroup as="div" class="flex items-center pt-8 pb-4">
            <Switch v-model="email" :class="[email ? 'bg-secondary' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2']">
              <span aria-hidden="true" :class="[email ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="text-sm font-medium text-gray-900">Email</span>
              <span class="text-sm text-gray-500">(Enable)</span>
            </SwitchLabel>
          </SwitchGroup>                  

          <SwitchGroup as="div" class="flex items-center py-4">
            <Switch v-model="whatsapp" :class="[whatsapp ? 'bg-secondary' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2']">
              <span aria-hidden="true" :class="[whatsapp ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="text-sm font-medium text-gray-900">WhatsApp</span>
              <span class="text-sm text-gray-500">(Enable)</span>
            </SwitchLabel>
          </SwitchGroup>                  

          <SwitchGroup as="div" class="flex items-center py-4">
            <Switch v-model="sms" :class="[sms ? 'bg-secondary' : 'bg-gray-200', 'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-gray-900 focus:ring-offset-2']">
              <span aria-hidden="true" :class="[sms ? 'translate-x-5' : 'translate-x-0', 'inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out']" />
            </Switch>
            <SwitchLabel as="span" class="ml-3">
              <span class="text-sm font-medium text-gray-900">SMS</span>
              <span class="text-sm text-gray-500">(Enable)</span>
            </SwitchLabel>
          </SwitchGroup>                  


        <div class="flex items-center justify-end mt-4 py-4">
          <button type="submit" class="w-1/2 mx-auto bg-white text-gray-700 rounded-full border border-gray-700 text-base font-bold p-2">
            Update
          </button>
        </div>    
      </form>
 -->

</template>
  
<script setup>

import { ref } from 'vue'
import { IonPage, IonContent } from '@ionic/vue';
import Header from '../components/Header';
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import {
  
  Switch,
  SwitchGroup,
  SwitchLabel,
} from '@headlessui/vue'


const email = ref(true);
const whatsapp = ref(true);
const sms = ref(true);

</script>
  