export const schemaList = [
  'Abandonment',
  'Mistrust',
  'Emotional Deprivation',
  'Defectiveness/Shame',
  'Failure',
  'Dependence/Incompetence',
  'Vulnerability to Harm',
  'Enmeshment',
  'Subjugation',
  'Self-Sacrifice',
  'Emotional Inhibition',
  'Unrelenting Standards',
  'Punitiveness',
  // Add more schemas as needed
];

export const modeList = [
  'Vulnerable Child',
  'Angry Child',
  'Healthy Adult',
  'Punitive Parent',
  'Demanding Parent',
  'Compliant Surrender',
  'Detached Protector',
  'Detached Self-Soother',
  'Overcompensator',
  // Add more modes as needed
];