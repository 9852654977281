import axios from "axios";

export const state = {
  lifetraps: [],
};

export const mutations = {
  setLifetraps: (state, lifetraps) => (state.lifetraps = lifetraps),
  addLifetrap: (state, lifetrap) => state.lifetraps.push(lifetrap),
  removeLifetrap: (state, id) =>
    (state.lifetraps = state.lifetraps.filter((lt) => lt.id !== id)),
};

export const actions = {
  // Fetch all lifetraps
  async fetchLifetraps({ commit }) {
    try {
      const response = await axios.get("/api/lifetraps");
      commit("setLifetraps", response.data.lifetraps);
    } catch (error) {
      alert("Failed to fetch lifetraps.");
      console.error(error);
    }
  },

  // Add a new lifetrap
  async addLifetrap({ commit }, lifetrap) {
    try {
      const response = await axios.post("/api/lifetraps", lifetrap);
      commit("addLifetrap", response.data.lifetrap); // Assuming the response contains the newly created lifetrap
    } catch (error) {
      alert("Failed to add lifetrap.");
      console.error(error);
    }
  },

  // Update an existing lifetrap
  async updateLifetrap({ commit, state }, updatedLifetrap) {
    try {
      const response = await axios.put(
        `/api/lifetraps/${updatedLifetrap.id}`,
        updatedLifetrap
      );
      
      // Find the index of the lifetrap being updated
      const index = state.lifetraps.findIndex((lifetrap) => lifetrap.id === updatedLifetrap.id);
      
      if (index !== -1) {
        // Replace the updated lifetrap in the array
        state.lifetraps[index] = response.data.lifetrap;
      }

      // Alternatively, commit a mutation that updates the lifetrap directly
      // commit('updateLifetrap', response.data.lifetrap); // if you have an 'updateLifetrap' mutation

    } catch (error) {
      alert("Failed to update lifetrap.");
      console.error(error);
    }
  },

  // Delete a lifetrap
  async deleteLifetrap({ commit }, id) {
    try {
      await axios.delete(`/api/lifetraps/${id}`);
      commit("removeLifetrap", id);
    } catch (error) {
      alert("Failed to delete lifetrap.");
      console.error(error);
    }
  },
};

export const getters = {
  getLifetraps(state) {
    return state.lifetraps;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};