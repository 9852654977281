import { createRouter, createWebHistory } from '@ionic/vue-router';
import store from "../store/index.js";

//auth
import ForgotPassword from '../pages/auth/ForgotPassword'
import Login from '../pages/auth/Login'
import RegisterOverview from '../pages/auth/RegisterOverview'
import RegisterStart from '../pages/auth/RegisterStart'
import ResetPassword from '../pages/auth/ResetPassword'
import VerifyEmail from '../pages/auth/VerifyEmail'
//Layouts
import NoLayout from '../layouts/NoLayout.vue'
import WebsiteLayout from '../layouts/WebsiteLayout.vue'
import AppLayoutFooter from '../layouts/AppLayoutFooter.vue'
import AppLayoutNoFooter from '../layouts/AppLayoutNoFooter.vue'
import RegisterLayout from '../layouts/RegisterLayout.vue'
//App
import Activities from '../pages/Activities';
import ActivityQuestionnaireInstance from '../pages/ActivityQuestionnaireInstance';
import ActivityReadingInstance from '../pages/ActivityReadingInstance';
import ActivityWorksheetInstance from '../pages/ActivityWorksheetInstance';
import ActivityQuestionnaireInstanceStart from '../pages/ActivityQuestionnaireInstanceStart';
import ActivityReadingInstanceStart from '../pages/ActivityReadingInstanceStart';
import ActivityWorksheetInstanceStart from '../pages/ActivityWorksheetInstanceStart'; 
import Chat from '../pages/Chat';
import ClientsPanel from '../pages/ClientsPanel';
import ClientView from '../pages/ClientView';
import Collections from '../pages/Collections';
import Dashboard from '../pages/Dashboard';
import DeepDive from '../pages/DeepDive';
import FeelingsFinder from '../pages/FeelingsFinder';
import FinderProblems from '../pages/FinderProblems';
import FinderValues from '../pages/FinderValues';
import FinderSchemas from '../pages/FinderSchemas';
import FinderModes from '../pages/FinderModes';
import Journal from '../pages/Journal';
import JournalDetail from '../pages/JournalDetail';
import MeActions from '../pages/MeActions';
import MeAims from '../pages/MeAims';
import MeBehaviours from '../pages/MeBehaviours';
import MeCharacters from '../pages/MeCharacters';
import MeFeelings from '../pages/MeFeelings';
import MeGoals from '../pages/MeGoals';
import MeLifeslices from '../pages/MeLifeslices';
import MeOverview from '../pages/MeOverview';
import MeParentstyles from '../pages/MeParentstyles';
import MeProblems from '../pages/MeProblems';
import MeSchemas from '../pages/MeSchemas';
import MeSchemaOverview from '../pages/MeSchemaOverview';
import MeSchemaModes from '../pages/MeSchemaModes';
import MeSchemaModeOverview from '../pages/MeSchemaModeOverview';
import MeSensations from '../pages/MeSensations';
import MeSymptoms from '../pages/MeSymptoms';
import MeThoughts from '../pages/MeThoughts';
import MeValues from '../pages/MeValues';
import SchemaTracking from '../pages/SchemaTracking';
import Toolbox from '../pages/Toolbox';
import Sessions from '../pages/Sessions';
import Settings from '../pages/Settings';
import SettingsAccount from '../pages/SettingsAccount';
import SettingsBilling from '../pages/SettingsBilling';
import SettingsNotifications from '../pages/SettingsNotifications';
import SettingsSupport from '../pages/SettingsSupport';
import Spotlights from '../pages/Spotlights';
import SpotlightView from '../pages/SpotlightView';
import Test from '../pages/Test';
import ToolboxLearn from '../pages/ToolboxLearn';
import ToolboxTrack from '../pages/ToolboxTrack';
import ToolboxPractise from '../pages/ToolboxPractise';
import ToolboxReset from '../pages/ToolboxReset';
import UserNotes from '../pages/UserNotes';
import UserNoteDetail from '../pages/UserNoteDetail';
import VideoChat from '../pages/VideoChat';

//website
import AddingPWA from '../website/pages/AddingPWA';
import Approach from '../website/pages/Approach';
import Anxiety from '../website/pages/common_problems/Anxiety';
import AssessmentOverview from '../website/pages/assessment/AssessmentOverview';
import AssessmentStart from '../website/pages/assessment/AssessmentStart';
import AssessmentPassword from '../website/pages/assessment/AssessmentPassword';
import AssessmentFinish from '../website/pages/assessment/AssessmentFinish';
import CommonProblems from '../website/pages/CommonProblems';
import Contact from '../website/pages/Contact';
import Depression from '../website/pages/common_problems/Depression';
import FAQ from '../website/pages/FAQ';
import Helplines from '../website/pages/Helplines';
import InitialSteps from '../website/pages/InitialSteps';
import Landing from '../website/pages/Landing';
import LandingMOT from '../website/pages/LandingMOT';
import LandingConsult from '../website/pages/LandingConsult';
import Individuals from '../website/pages/Individuals';
import IndividualsStudents from '../website/pages/IndividualsStudents';
import Individuals1 from '../website/pages/Individuals1';
import Individuals2 from '../website/pages/Individuals2';
import Individuals3 from '../website/pages/Individuals3';
import NotFound from '../website/pages/NotFound';
import Organisations from '../website/pages/Organisations';
import Organisations1 from '../website/pages/Organisations1';
import Organisations2 from '../website/pages/Organisations2';
import Organisations3 from '../website/pages/Organisations3';
import Partnerships from '../website/pages/Partnerships';
import Platform from '../website/pages/Platform';
import Pricing from '../website/pages/Pricing';
import PrivacyPolicy from '../website/pages/PrivacyPolicy';
import SelfEsteem from '../website/pages/common_problems/SelfEsteem';
import Stress from '../website/pages/common_problems/Stress';
import Terms from '../website/pages/Terms';
import TypesOfTherapy from '../website/pages/TypesOfTherapy';
import WhatTherapyProvides from '../website/pages/WhatTherapyProvides';



  const router = createRouter({
      history: createWebHistory(),
      routes: [

//protected routes   
      
    { name: 'Activities', 
      component: Activities, 
      path: '/activities',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Activities' }
    },

    { name: 'ActivityWorksheetInstance', 
      component: ActivityWorksheetInstance, 
      path: '/activities/worksheet/:worksheetinstanceid',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Exercise' }
    },

    { name: 'ActivityQuestionnaireInstance', 
      component: ActivityQuestionnaireInstance, 
      path: '/activities/questionnaire/:questionnaireinstanceid',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Questionnaire' }
    },

    { name: 'ActivityReadingInstance', 
      component: ActivityReadingInstance, 
      path: '/activities/reading/:readinginstanceid',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Reading' }
    },

    { name: 'ActivityWorksheetInstanceStart', 
      component: ActivityWorksheetInstanceStart, 
      path: '/activities/worksheetinstance/:worksheetinstanceid',
      meta: { requiresAuth: true, layout: AppLayoutNoFooter, title: 'Exercise' }
    },

    { name: 'ActivityReadingInstanceStart', 
      component: ActivityReadingInstanceStart, 
      path: '/activities/readinginstance/:readinginstanceid',
      meta: { requiresAuth: true, layout: AppLayoutNoFooter, title: 'Reading' }
    },

    { name: 'ActivityQuestionnaireInstanceStart', 
    component: ActivityQuestionnaireInstanceStart, 
    path: '/activities/questionnaireinstance/:questionnaireinstanceid',
    meta: { requiresAuth: true, layout: AppLayoutNoFooter, title: 'Questionnaire' }
    },


    { name: 'ClientsPanel',
    component: ClientsPanel,
    path: '/clients-panel',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'My Clients' }
    },

    { name: 'ClientView',
    component: ClientView,
    path: '/clients-panel/:clientId',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Client View' }
    },

    { name: 'Chat', 
      component: Chat, 
      path: '/chat',
      meta: { requiresAuth: true, layout: AppLayoutNoFooter, title: 'Chat' }
    },

    { name: 'Collections',
      component: Collections,
      path: '/collections/:slug',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'My Mental Health' }
    },

  
    { name: 'DeepDive', 
      component: DeepDive, 
      path: '/deep-dive',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Deep Dive' }
      },

    { name: 'FeelingsFinder', 
      component: FeelingsFinder, 
      path: '/feelings-finder',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Feelings Finder' }
    },

    { name: 'FinderProblems', 
      component: FinderProblems, 
      path: '/problem-finder',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Problems Finder' }
    },

    { name: 'FinderValues', 
      component: FinderValues, 
      path: '/values-finder',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Values Finder' }
    },

    { name: 'FinderSchemas', 
      component: FinderSchemas, 
      path: '/schema-finder',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Schema Finder' }
    },

    { name: 'Findermodes', 
      component: FinderModes, 
      path: '/mode-finder',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Mode Finder' }
    },

    { name: 'Home', 
      component: Dashboard, 
      path: '/dashboard',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Home' }
    },

    { name: 'Journal',
      component: Journal,
      path: '/journal',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Journal' }
    },

    { name: 'JournalDetail',
      component: JournalDetail,
      path: '/journal/:id',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Journal Entry' }
    },

    { name: 'MeValues', 
      component: MeValues, 
      path: '/values',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Values' }
    },

    { name: 'MeOverview', 
      component: MeOverview, 
      path: '/me',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Me, Myself & I' }
    },


    { name: 'MeBehaviours', 
      component: MeBehaviours, 
      path: '/behaviours',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Behaviours' }
    },

    { name: 'MeThoughts', 
      component: MeThoughts, 
      path: '/thoughts',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Thoughts' }
    },

    { name: 'MeFeelings', 
      component: MeFeelings, 
      path: '/feelings',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Feelings' }
    },

    { name: 'MeCharacters', 
      component: MeCharacters, 
      path: '/characters',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Characters' }
    },

    { name: 'MeSensations', 
      component: MeSensations, 
      path: '/sensations',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Sensations' }
    },

    { name: 'MeLifeslices', 
      component: MeLifeslices, 
      path: '/lifeslices',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Lifeslices' }
    },

    { name: 'MeParentstyles', 
      component: MeParentstyles, 
      path: '/parentstyles',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Parent Styles' }
    },

    { name: 'MeSymptoms', 
      component: MeSymptoms, 
      path: '/symptoms',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Symptoms' }
    },
    
    { name: 'MeProblems', 
    component: MeProblems, 
    path: '/problems',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Current Problems' }
    },
  

    { name: 'MeSchemas', 
    component: MeSchemas, 
    path: '/schemas',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Schemas' }
    },

    { name: 'MeSchemaModes', 
    component: MeSchemaModes, 
    path: '/schema-modes',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Schema Modes' }
    },


    { name: 'MeAims', 
    component: MeAims, 
    path: '/aims',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Aims' }
    },
    
    { name: 'MeGoals', 
    component: MeGoals, 
    path: '/goals',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Goals' }
    },



    { name: 'MeActions', 
    component: MeActions, 
    path: '/actions',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Actions' }
    },

    { name: 'MeSchemaOverview', 
      component: MeSchemaOverview, 
      path: '/schema/:id',
      props: true, // Enable route params as props
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Schema Overview' }
    },
    
    { name: 'MeSchemaModeOverview', 
      component: MeSchemaModeOverview, 
      path: '/schema-mode/:id',
      props: true, // Enable route params as props
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Schema Mode Overview' }
    },


    { name: 'SchemaTracking', 
      component: SchemaTracking, 
      path: '/schema-tracking',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Schema Tracking' }
    },

    { name: 'Sessions', 
      component: Sessions, 
      path: '/sessions',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Sessions' }
    },

    { name: 'Settings', 
      component: Settings, 
      path: '/settings',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Settings' }
    },

    { name: 'SettingsAccount', 
      component: SettingsAccount, 
      path: '/settings-account',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Account' }
    },

    { name: 'SettingsBilling', 
      component: SettingsBilling, 
      path: '/settings-billing',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Billings' }
    },

    { name: 'SettingsNotifications', 
      component: SettingsNotifications, 
      path: '/settings-notifications',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Notifications' }
    },

    { name: 'SettingsSupport', 
      component: SettingsSupport, 
      path: '/settings-support',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Support' }
    },
   
    { name: 'Spotlights', 
      component: Spotlights, 
      path: '/spotlights',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Spotlights' }
    },
      
    { name: 'Spotlight', 
    component: SpotlightView, 
    path: '/spotlights/:spotlightid',
    meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Spotlight' }
    },

    { name: 'Test', 
      component: Test, 
      path: '/test',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Test Page' }
    },

    { name: 'Toolbox', 
      component: Toolbox, 
      path: '/toolbox',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Toolbox' }
    },

    { name: 'ToolboxLearn', 
      component: ToolboxLearn, 
      path: '/toolbox-learn',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Learn' }
    },

    { name: 'ToolboxTrack', 
      component: ToolboxTrack, 
      path: '/toolbox-track',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Track' }
    },

    { name: 'ToolboxPractise', 
      component: ToolboxPractise, 
      path: '/toolbox-practise',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Practise' }
    },

    { name: 'ToolboxReset', 
      component: ToolboxReset, 
      path: '/toolbox-reset',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Reset' }
    },


    { name: 'UserNotes',
      component: UserNotes,
      path: '/user-notes',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Notes' }
    },

    { name: 'UserNoteDetail',
      component: UserNoteDetail,
      path: '/user-notes/:id',
      meta: { requiresAuth: true, layout: AppLayoutFooter, title: 'Note' }
    },

    { name: 'VideoChat',
      component: VideoChat,
      path: '/video-chat',
      meta: { requiresAuth: true, layout: AppLayoutFooter }
    },

         
  
                                    
//non-protected routes                    
      { name: 'Landing',
      component: Landing, 
      path: '/',
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'LandingMOT',
      component: LandingMOT, 
      path: '/landing-mot',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals',
      component: Individuals, 
      path: '/individuals',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'IndividualsStudents',
      component: IndividualsStudents, 
      path: '/students',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations',
      component: Organisations, 
      path: '/organisations',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals1',
      component: Individuals1, 
      path: '/individuals/weekly-therapy',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals2',
      component: Individuals2, 
      path: '/individuals/mental-health-programs',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Individuals3',
      component: Individuals3, 
      path: '/individuals/mental-health-mot',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations1',
      component: Organisations1, 
      path: '/organisations/schools-universities',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations2',
      component: Organisations2, 
      path: '/organisations/startups-small-businesses',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Organisations3',
      component: Organisations3, 
      path: '/organisations/charities-nonprofits',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Partnerships',
      component: Partnerships, 
      path: '/partnerships',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Platform',
      component: Platform, 
      path: '/platform',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'Contact',
      component: Contact, 
      path: '/contact',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'LandingConsult',
      component: LandingConsult, 
      path: '/landing-consult',
      meta: { isGuest: true, layout: WebsiteLayout }},
                      
      { name: 'Approach',
      component: Approach, 
      path: '/approach',
      meta: { isGuest: true, layout: WebsiteLayout }},
                      
      { name: 'Helplines',
      component: Helplines, 
      path: '/helplines',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'InitialSteps',
      component: InitialSteps, 
      path: '/initial-steps',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Pricing',
      component: Pricing, 
      path: '/pricing',
      meta: { isGuest: true, layout: WebsiteLayout }},

      { name: 'FAQ',
      component: FAQ, 
      path: '/faq',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'CommonProblems',
      component: CommonProblems,
      path: '/common-problems',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Depression',
      component: Depression, 
      path: '/common-problems/depression',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Anxiety',
      component: Anxiety,  
      path: '/common-problems/anxiety',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'SelfEsteem',
      component: SelfEsteem,  
      path: '/common-problems/self-esteem',
      meta: { isGuest: true, layout: WebsiteLayout }},
  
      { name: 'Stress',
      component: Stress, 
      path: '/common-problems/stress',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'WhatTherapyProvides',
      component: WhatTherapyProvides,
      path: '/therapy-provides',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'TypesOfTherapy',
      component: TypesOfTherapy,  
      path: '/types-of-therapy',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'PrivacyPolicy',
      component: PrivacyPolicy,
      path: '/privacy',
      meta: { isGuest: true, layout: WebsiteLayout }},
                  
      { name: 'Terms',
      component: Terms, 
      path: '/terms',
      meta: { isGuest: true, layout: WebsiteLayout }},   
      
      { name: 'AddingPWA',
      component: AddingPWA, 
      path: '/add-to-home-screen',
      meta: { isGuest: true, layout: NoLayout }},  

//not protected and no layout    

      { name: 'RegisterActivityQuestionnaireInstanceStart',
        component: ActivityQuestionnaireInstanceStart,
        path: '/register/questionnaire/:questionnaireinstanceid',
        meta: { layout: RegisterLayout, isGuest: true, requiresAuth: false }},

      { name: 'AssessmentOverview',
      component: AssessmentOverview,
      path: '/assessment/overview',
      meta: { isGuest: true, layout: NoLayout }},
      
      { name: 'AssessmentStart',
      component: AssessmentStart,
      path: '/assessment/start',
      meta: { isGuest: true, layout: NoLayout }},
      
      { name: 'AssessmentPassword',
      component: AssessmentPassword,
      path: '/assessment/password',
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'AssessmentFinish',
      component: AssessmentFinish,
      path: '/assessment/Finish',
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'RegisterOverview', 
      component: RegisterOverview, 
      path: '/register/overview', 
      meta: { isGuest: true, layout: NoLayout }}, 
      
      { name: 'RegisterStart', 
      component: RegisterStart, 
      path: '/register/start', 
      meta: { isGuest: true, layout: NoLayout }},
  
      { name: 'Login',
      component: Login, 
      path: '/login', 
      meta: { isGuest: true, layout: NoLayout }},    
  
      { name: '404',
      component: NotFound,
      path: '/:pathMatch(.*)*',
      meta: { isGuest: true, layout: NoLayout }},   
      
      { name: 'ForgotPassword', 
      component: ForgotPassword, 
      path: "/forgot-password",
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'ResetPassword',
      component: ResetPassword,
      path: "/reset-password/:token",
      meta: { isGuest: true, layout: NoLayout }},

      { name: 'VerifyEmail',
      component: VerifyEmail,
      path: "/verify-email",
      meta: { isGuest: true, layout: NoLayout }},
          
        ],
   

    scrollBehavior(to, from, savedPosition) {

    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
    },
    });


    router.beforeEach((to, from, next) => {
      if (to.meta.requiresAuth && !store.getters.user) {
        next({ name: "Login" });
      } else if (to.meta.isGuest && store.getters.user) {
        next({ name: "Home" });
      } else {
        // Scroll to top before navigating
        window.scrollTo(0, 0);
        next();
      }
    });

export default router;