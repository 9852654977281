<template>
  <ion-page>
    <ion-content class="ion-padding">

      <!-- Thoughts List -->
      <ion-list lines="full" v-if="getThoughts.length > 0" class="my-8">
        <ion-item v-for="thought in getThoughts" :key="thought.id" class="ion-align-items-center">
          <ion-label>
            <h3>{{ thought.thought }}</h3>
            <p class="text-muted">Category: {{ thought.category }}</p>
          </ion-label>

          <!-- Edit and Delete Buttons -->
          <ion-buttons slot="end">
            <ion-button @click="openEditThoughtModal(thought)" color="primary" size="small">
              <ion-icon :icon="createOutline" slot="icon-only" />
            </ion-button>
            <ion-button @click="deleteThought(thought.id)" color="danger" size="small">
              <ion-icon :icon="trashOutline" slot="icon-only" />
            </ion-button>
          </ion-buttons>
        </ion-item>
      </ion-list>

      <!-- Empty State -->
      <div v-else class="ion-text-center ion-margin-top">
        <EmptyState message="No thoughts have been recorded yet." />
      </div>

      <!-- Add Thought Button -->
      <ion-button expand="block" @click="openAddThoughtModal">Add Thought</ion-button>

      <!-- Add/Edit Thought Modal -->
      <ion-modal :is-open="isModalOpen" @did-dismiss="closeModal">
        <ion-header>
          <ion-toolbar>
            <ion-title>{{ isEditing ? 'Edit Thought' : 'Add Thought' }}</ion-title>
            <ion-buttons slot="end">
              <ion-button @click="closeModal">Close</ion-button>
            </ion-buttons>
          </ion-toolbar>
        </ion-header>

        <ion-content class="ion-padding">
          <form @submit.prevent="submitThought">
            <div class="ion-margin-bottom">
              <ion-label>Thought</ion-label>
              <ion-input v-model="newThought.thought" placeholder="Enter thought" required />
            </div>

            <div class="ion-margin-bottom">
              <ion-label>Category</ion-label>
              <ion-select v-model="newThought.category" placeholder="Select category" required>
                <ion-select-option v-for="category in categories" :key="category" :value="category">
                  {{ category }}
                </ion-select-option>
              </ion-select>
            </div>

            <ion-button expand="block" type="submit" color="primary">
              {{ isEditing ? 'Update Thought' : 'Add Thought' }}
            </ion-button>
          </form>
        </ion-content>
      </ion-modal>

    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent, IonLabel, IonList, IonItem, IonButton, IonButtons, IonModal, IonHeader, IonToolbar, IonTitle, IonInput, IonSelect, IonSelectOption, IonIcon } from '@ionic/vue';
import { createOutline, trashOutline } from 'ionicons/icons';
import EmptyState from '../components/EmptyState';
import { ref, computed, onMounted } from 'vue';
import { useStore } from 'vuex';

// Vuex store
const store = useStore();

// State variables
const isModalOpen = ref(false);
const isEditing = ref(false);
const currentThoughtId = ref(null);
const newThought = ref({ thought: '', category: '' });
const categories = ref(['Mental Health', 'Physical Health', 'Work', 'Relationships']);

// Fetch thoughts from Vuex store
const getThoughts = computed(() => store.getters['thoughts/getThoughts'].slice().reverse());

// Fetch thoughts on mount
onMounted(() => {
  store.dispatch('thoughts/fetchThoughts');
});

// Open modal for adding a new thought
const openAddThoughtModal = () => {
  isEditing.value = false;
  newThought.value = { thought: '', category: '' };
  isModalOpen.value = true;
};

// Open modal for editing an existing thought
const openEditThoughtModal = (thought) => {
  isEditing.value = true;
  newThought.value = { thought: thought.thought, category: thought.category };
  currentThoughtId.value = thought.id;
  isModalOpen.value = true;
};

// Close modal
const closeModal = () => {
  isModalOpen.value = false;
  newThought.value = { thought: '', category: '' }; // Reset form
  currentThoughtId.value = null;
};

// Add or update a thought
const submitThought = () => {
  if (newThought.value.thought && newThought.value.category) {
    if (isEditing.value) {
      store.dispatch('thoughts/updateThought', { id: currentThoughtId.value, ...newThought.value });
    } else {
      store.dispatch('thoughts/addThought', { ...newThought.value });
    }

    // Reset form and close modal
    closeModal();
  }
};

// Delete a thought
const deleteThought = (id) => {
  store.dispatch('thoughts/deleteThought', id);
};
</script>

<style scoped>
ion-label {
  font-size: 18px;
  color: #333;
}

ion-button {
  --border-radius: 5px;
  --height: 50px;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

li .ion-button {
  --border-radius: 50%;
  --padding-start: 0;
  --padding-end: 0;
  --height: 35px;
  --width: 35px;
}

li .ion-button .ion-icon {
  --color: white;
}
</style>