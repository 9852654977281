<template>
  <ion-page>
    <ion-content class="ion-padding">
      <ion-grid class="ion-text-center ion-padding-top">
        <!-- Centered Logo -->
        <ion-row class="ion-justify-content-center ion-align-items-center" style="height: 20vh;">
          <ion-col size="auto" class="ion-text-center">
  <a href="/">
    <ion-img
      src="img/logo-dark.svg"
      alt="Youlio logo"
      style="width: 120px; height: auto;"
      class="ion-margin-bottom"
    ></ion-img>
  </a>
</ion-col>
        </ion-row>
        <!-- Title -->
        <ion-row>
          <ion-col>
            <ion-text>
              <h2 style="font-size: 1.5rem; font-weight: bold; color: #333;">Log In to Your Account</h2>
            </ion-text>
          </ion-col>
        </ion-row>
        <!-- Login Form -->
        <ion-row class="ion-justify-content-center">
          <ion-col size="12" size-md="6">

                <form @submit.prevent="login">
  <!-- Email -->
  <ion-item>

    <ion-input
    placeholder="Email"
      :value="data.email"
      @ionInput="(e) => data.email = e.target.value"
      type="email"
      required
    ></ion-input>
  </ion-item>
  <!-- Password -->
  <ion-item>

    <ion-input
     placeholder="Password"
      :value="data.password"
      @ionInput="(e) => data.password = e.target.value"
      type="password"
      required
      autocomplete="current-password"
    >
    <ion-input-password-toggle slot="end"></ion-input-password-toggle>
  </ion-input>
  </ion-item>
  <!-- Error Message -->
  <ion-text color="danger" v-if="errors" style="font-size: 0.9rem;">
    <p>{{ errors.message }}</p>
  </ion-text>
  <!-- Remember Me & Forgot Password -->
  <ion-item lines="none" class="ion-margin-top">
    <ion-checkbox v-model="data.remember" slot="start"></ion-checkbox>
    <ion-label>Remember me</ion-label>
  </ion-item>
    <ion-text slot="end">
      <router-link
        :to="{ name: 'ForgotPassword' }"
        class="ion-text-color-primary"
        style="font-size: 0.9rem; text-decoration: underline;"
      >
        Forgot your password?
      </router-link>
    </ion-text>

  <!-- Login Button -->
  <ion-button expand="block" type="submit" class="ion-margin-top" style="font-size: 1rem;">
    Log in
  </ion-button>
</form>
           <!-- Sign Up Link -->
<ion-text class="ion-margin-top">
  <p style="font-size: 0.9rem; margin-top: 16px;">
    Don't have an account yet?
    <router-link
      :to="{ name: 'RegisterOverview' }"
      class="ion-text-color-primary"
      style="text-decoration: underline; font-weight: bold;"
    >
      Sign up now
    </router-link>
  </p>
</ion-text>

          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>


<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import axios from 'axios';
import {
  IonInputPasswordToggle,
  IonPage,
  IonContent,
  IonRow,
  IonGrid,
  IonCol,
  IonCard,
  IonCardContent,
  IonText,
  IonButton,
  IonItem,
  IonCheckbox,
  IonLabel,
  IonInput,
  IonImg
  } from '@ionic/vue';

const router = useRouter();
const store = useStore();

// Reactive data
const errors = ref(null);
const data = ref({
  email: '',
  password: '',
  remember: false,
});

// Login method
const login = async () => {
  errors.value = null;

  try {
    const response = await store.dispatch('login', data.value);

    if (response.two_factor) {
      router.replace({ name: 'TwoFactorChallenge' });
      return;
    }

    router.replace({ name: 'Home' });
  } catch (error) {
    errors.value = error.response.data;
  }
};
</script>
