<template>
   <router-link to="/settings" class="flex items-center">
  <div class="inline-block h-8 w-8 overflow-hidden rounded-full bg-primary-200 flex items-center justify-center">
    <span class="text-gray-700 font-semibold text-sm">
      {{ userInitials }}
    </span>
  </div>
</router-link>
</template>

<script>
export default {
  name: 'UserComponent',
  props: {
    // Optional prop in case the user object is passed from parent components
    user: {
      type: Object,
      default: null
    }
  },
  computed: {
    userInitials() {
      const userObj = this.user || this.$store.getters['user'];
      if (userObj && userObj.name) {
        // Extract initials from user's name
        return userObj.name.split(' ').map(namePart => namePart[0]).join('');
      }
      return '';
    }
  }
}
</script>
