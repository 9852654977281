<template>
  <div v-if="getAims.length > 0">
    <div
      v-for="(aim, index) in getAims"
      :key="aim.id"
    >
      <h3 class="p-4 font-semibold text-base text-gray-800 font-normal bg-secondary-300">
        {{ aim.aim }}
      </h3>
      <p class="p-4 text-body-base bg-white font-normal text-gray-600 rounded-lg shadow-sm border border-gray-100">
        {{ aim.description }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { sendPost } from './entities/functions.js';

// Reactive variables
const client = ref({});
const loading = ref(false);

// Get client ID from route
const route = useRoute();
const clientId = computed(() => route.params.clientId);

// Fetch client info
const fetchClientInfo = async () => {
  loading.value = true;
  try {
    const response = await sendPost('/api/get/client-info', {
      client_id: clientId.value,
    });
    if (response) {
      client.value = response.client || {};
    }
  } catch (error) {
    console.error('Error fetching client info:', error);
  } finally {
    loading.value = false;
  }
};

// Computed property for aims
const getAims = computed(() => client.value.aim || []);

// Fetch client info on component mount
onMounted(fetchClientInfo);
</script>