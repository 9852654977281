export async function sendGet(endpoint) {
  try {
    const token = localStorage.getItem('auth_token');

    const headers = {
      'Accept': 'application/json',
    };

    if (Capacitor.isNativePlatform() && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await axios.get(endpoint, {
      headers,
      withCredentials: !Capacitor.isNativePlatform()
    });
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export async function sendPost(endpoint, data = null) {
  try {
    const token = localStorage.getItem('auth_token');
    const headers = {
      'Accept': 'application/json'
    };

    if (Capacitor.isNativePlatform() && token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await axios.post(endpoint, data, {
      headers,
      withCredentials: !Capacitor.isNativePlatform()
    });
    return response.data;
  } catch (error) {
      console.error(error);
      if (error.response && error.response.status === 422) {
        return error.response.data;
      }
      throw error;
  }
}

export function generateColorCode(str, minBrightness = 0.45, minSaturation = 0.3, maxSaturation = 0.9) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const randomColor = (hash & 0x00FFFFFF).toString(16).toUpperCase();
    const colorCode = "#" + "0".repeat(6 - randomColor.length) + randomColor;

    let brightness = calculateBrightness(colorCode);
    let saturation = calculateSaturation(colorCode);

    if (brightness < minBrightness || saturation < minSaturation || saturation >= maxSaturation) {
      return generateColorCode(str + "~");
    }
    // console.log(str, brightness, saturation);
    return colorCode;
}

function calculateBrightness(colorCode) {
    const red = parseInt(colorCode.slice(1, 3), 16);
    const green = parseInt(colorCode.slice(3, 5), 16);
    const blue = parseInt(colorCode.slice(5, 7), 16);

    const relativeLuminance = (red * 0.299 + green * 0.587 + blue * 0.114) / 255;

    return relativeLuminance.toFixed(2);
  }

function calculateSaturation(colorCode) {
    const red = parseInt(colorCode.slice(1, 3), 16);
    const green = parseInt(colorCode.slice(3, 5), 16);
    const blue = parseInt(colorCode.slice(5, 7), 16);

    const max = Math.max(red, green, blue);
    const min = Math.min(red, green, blue);
    const delta = max - min;

    if (max === 0) {
      return 0;
    }

    return (delta / max).toFixed(2);;
  }
