<template>
  <ion-page>
    <ion-content class="ion-padding">

      <ion-button v-if="user.is_therapist" expand="block" routerLink="/clients-panel" color="primary">
    <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Clients Panel</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>


<header-in-page title="What Does It Look Like?" />


<ion-button expand="block" router-link="/symptoms" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Symptoms</p>     
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>



<ion-button expand="block" router-link="/problems" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Problems</p>   
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>



<ion-button expand="block" router-link="/schemas" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Schemas</p>   
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>


<ion-button expand="block" router-link="/schema-modes" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Modes</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/values" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Values</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>


<ion-button expand="block" router-link="/aims" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Aims</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/goals" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Goals & Actions</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/collections/psychological-health" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">My Mental Health</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/deep-dive" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Deep Dive</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>



<ion-button expand="block" router-link="/behaviours" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Behaviours</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/feelings" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Feelings</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/thoughts" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Thoughts</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/sensations" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Sensations</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/characters" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Characters</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/parentstyles" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Parent Styles</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>

<ion-button expand="block" router-link="/lifeslices" color="primary">
  <ion-label class="flex items-center justify-between w-full">
    <div class="flex flex-col text-left">
      <p class="text-base font-normal uppercase">Lifeslices</p>
    </div>
    <ion-icon :icon="chevronForwardOutline" size="large" class="text-white"></ion-icon>
  </ion-label>
</ion-button>



    </ion-content>
  </ion-page>
</template>

<script setup>
  import { ref, computed } from 'vue';
  import { useStore } from 'vuex';
  import { IonPage, IonContent, IonButton, IonIcon, IonLabel } from '@ionic/vue';
  import { chevronForwardOutline } from 'ionicons/icons'
  import Header from '../components/Header';
  import HeaderInPage from '../components/HeaderInPage';

  // Access the user data from the store
  const store = useStore();
  const user = computed(() => store.getters['user']);  // Assuming 'user' is stored in Vuex
</script>