<template>
  <IonModal :is-open="isOpen" @did-dismiss="close" class="w-full sm:max-w-lg sm:p-6">
    <div class="flex items-start">
      <div class="flex-shrink-0">
        <IonIcon :icon="alertCircle" class="h-8 w-8 text-gray-700" aria-hidden="true" />
      </div>
      <div class="mt-3 sm:ml-4 sm:text-left">
        <IonLabel class="text-lg font-medium leading-6 text-gray-900">{{ title }}</IonLabel>
        <div class="mt-2">
          <p class="mb-4 text-body-base">{{ message }}</p>
        </div>
      </div>
    </div>
    <div class="mt-6 sm:flex sm:flex-row-reverse">
      <IonButton @click="confirm" color="danger" expand="full" class="sm:ml-3 sm:w-auto sm:text-sm">
        Delete
      </IonButton>
      <IonButton @click="close" expand="full" class="mt-4 sm:mt-0 sm:w-auto sm:text-sm">
        Cancel
      </IonButton>
    </div>
  </IonModal>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { IonModal, IonButton, IonIcon, IonLabel } from '@ionic/vue';
import { alertCircle } from 'ionicons/icons';

// Define the props that the component expects
const props = defineProps({
  isOpen: {
    type: Boolean,
    required: true
  },
  onConfirm: {
    type: Function,
    required: true
  },
  onClose: {
    type: Function,
    required: true
  },
  title: {
    type: String,
    default: 'Confirmation'
  },
  message: {
    type: String,
    default: 'Are you sure?'
  }
});

// Define emits for closing the modal
const emits = defineEmits(['close']);

// Function to handle closing the modal
const close = () => {
  emits('close');
};

// Function to handle confirmation action
const confirm = () => {
  props.onConfirm(); // Call the passed onConfirm function
  close(); // Close the modal after confirming
};
</script>