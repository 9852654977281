<template>
  <ion-page>

    <!-- Main Content -->
    <ion-content class="ion-padding">
      <!-- Lifetrap Section -->
      <div v-if="lifetrap" class="bg-white shadow-xl rounded-lg overflow-hidden mb-6">
        <!-- Lifetrap Title -->
        <h1 class="bg-gradient-to-r from-primary-200 to-secondary-200 text-base font-semibold text-secondary-600 px-4 py-6">
          {{ lifetrap.lifetrap }}
        </h1>

        <!-- Lifetrap Category -->
        <div v-if="lifetrap.category" class="px-4 py-2 text-gray-500">
          <p><strong>Category:</strong> {{ lifetrap.category }}</p>
        </div>

        <!-- Lifetrap Examples -->
        <div v-if="lifetrapExamples.length > 0" class="px-4">
          <h2 class="text-lg font-semibold text-secondary-600 mb-2">Examples</h2>
          <ul>
            <li v-for="example in lifetrapExamples" :key="example.id" class="py-4 border-b">
              <h5>{{ example.title }}</h5>
              <p>{{ example.description }}</p>
            </li>
          </ul>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent } from '@ionic/vue';
import { useStore } from 'vuex';
import { ref, computed, watch, onMounted } from 'vue';

const props = defineProps({
  id: {
    type: String,
    required: true
  }
});

const store = useStore();
const loading = ref(true);

const lifetrap = computed(() => store.getters.getLifetrapById(props.id));
const lifetrapExamples = computed(() => store.getters.getLifetrapExamples(props.id));

const fetchLifetrap = async (id) => {
  loading.value = true;
  try {
    if (!lifetrap.value || lifetrap.value.id !== id) {
      await store.dispatch('fetchLifetrap', id);
    }
  } catch (error) {
    // Removed console.log here
  } finally {
    loading.value = false;
  }
};

const fetchLifetrapExamples = async (id) => {
  loading.value = true;
  try {
    await store.dispatch('fetchLifetrapExamples', id);
  } catch (error) {
    // Removed console.log here
  } finally {
    loading.value = false;
  }
};

// Watch for changes in the `id` prop
watch(() => props.id, (newId) => {
  fetchLifetrap(newId);
  fetchLifetrapExamples(newId);
}, { immediate: true });

onMounted(() => {
  fetchLifetrap(props.id);
  fetchLifetrapExamples(props.id);
});
</script>

<style scoped>
/* Additional Styling to Enhance UI */
.text-gradient {
  background: linear-gradient(to right, var(--ion-color-primary), var(--ion-color-secondary));
  -webkit-background-clip: text;
  color: transparent;
}

.border-b {
  border-bottom: 1px solid #e2e8f0;
}

.font-medium {
  font-weight: 500;
}
</style>