import axios from "axios";

export const state = {
  characters: [],
};

export const mutations = {
  setCharacters: (state, characters) => (state.characters = characters),
  addCharacter: (state, character) => state.characters.push(character),
  removeCharacter: (state, id) =>
    (state.characters = state.characters.filter((c) => c.id !== id)),
};

export const actions = {
  // Fetch all characters
  async fetchCharacters({ commit }) {
    try {
      const response = await axios.get("/api/characters");
      commit("setCharacters", response.data.characters);
    } catch (error) {
      alert("Failed to fetch characters.");
      console.error(error);
    }
  },

  // Add a new character
  async addCharacter({ commit }, character) {
    try {
      const response = await axios.post("/api/characters", character);
      commit("addCharacter", response.data.character); // Assuming the response contains the newly created character
    } catch (error) {
      alert("Failed to add character.");
      console.error(error);
    }
  },

  // Update an existing character
  async updateCharacter({ commit, state }, updatedCharacter) {
    try {
      const response = await axios.put(
        `/api/characters/${updatedCharacter.id}`,
        updatedCharacter
      );
    
      // Find the index of the character being updated
      const index = state.characters.findIndex((character) => character.id === updatedCharacter.id);
    
      if (index !== -1) {
        // Replace the updated character in the array
        state.characters[index] = response.data.character;
      }

      // Alternatively, commit a mutation that updates the character directly
      // commit('updateCharacter', response.data.character); // if you have an 'updateCharacter' mutation

    } catch (error) {
      alert("Failed to update character.");
      console.error(error);
    }
  },

  // Delete a character
  async deleteCharacter({ commit }, id) {
    try {
      await axios.delete(`/api/characters/${id}`);
      commit("removeCharacter", id);
    } catch (error) {
      alert("Failed to delete character.");
      console.error(error);
    }
  },
};

export const getters = {
  getCharacters(state) {
    return state.characters;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};