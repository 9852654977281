<template>
  <ion-page>

    <ion-content class="ion-padding">
      <div class="relative">
        <UserStatusFilter @update:selectedStatuses="handleSelectedStatuses" />
      </div>
      <div class="my-4">
        <div v-if="Object.keys(clients).length">
          <ClientList :data="clients" />
        </div>
        <div v-else>
          <EmptyState message="There are currently no 'Users' that fit here." />
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { IonPage, IonContent } from '@ionic/vue';

import Header from '../components/Header';
import ClientList from '../components/ClientList';
import EmptyState from '../components/EmptyState';
import UserStatusFilter from '../components/UserStatusFilter.vue';
import { sendPost } from '../components/entities/functions.js';

// Reactive data
const clients = ref({});
const loading = ref(false);
const statuses = ref(false);

// Methods
const getClientList = async () => {
  loading.value = true;
  const response = await sendPost('/api/get/clients', { statuses: statuses.value });
  if (response) {
    // Sort clients alphabetically by their names
    clients.value = response.clients.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
  loading.value = false;
};

const handleSelectedStatuses = (newStatuses) => {
  statuses.value = newStatuses;
  getClientList();
};

// Lifecycle hook
onMounted(() => {
  // Set the default status to "Client"
  statuses.value = ['Client'];
  getClientList();
});
</script>