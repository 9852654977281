<template>
   
   <ion-page>
      <Header :title="'My Mental Health'" :default-href="'/test-home'" />
        <ion-content>
 
<!--Next Check In-->
    <ion-card>
      <ion-card-content>
    
        <div class="pl-4 items-center text-display-base">
          <p v-if="untilRepeat !== 0">{{ untilRepeat }} DAYS <span class="text-body-base">until next 'Check In'</span></p>
          <p v-else class="text-center" >Check-In is now READY!</p>
        </div>
      
        <div v-if="untilRepeat === 0">  
<!-- CTA -->
          <ion-button expand="block" @click="startCheckIn">
            {{ notFinished ? 'Continue' : 'Start' }}
          </ion-button> 
        </div>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <psychological-health-factor-history v-for="chart in charts" :data="chart"/>
    </ion-card>

    </ion-content>
  </ion-page>

</template>
  
<script setup>

  import { ref, onMounted } from 'vue';
  import { 
    IonPage, 
    IonContent,
    IonCard, 
    IonCardContent, 
    IonButton
  } from '@ionic/vue';
  import Header from '../components/Header';
  import PsychologicalHealthFactorHistory from '../components/PsychologicalHealthFactorHistory';
  import { sendPost } from '../components/entities/functions.js';
  import { useRoute, useRouter } from 'vue-router';

// Reactive references
const title = ref('');
const description = ref('');
const slug = ref('');
const untilRepeat = ref(0);
const charts = ref({});
const loading = ref(true);
const notFinished = ref(false);

// Router hooks
const route = useRoute();
const router = useRouter();

// Methods
const getData = async () => {
  const response = await sendPost('/api/collections/' + slug.value);
  if (response) {
    title.value = response.title;
    description.value = response.description;
    untilRepeat.value = response.until_repeat;
    charts.value = response.questionnaires;
    notFinished.value = response.not_finished;
    loading.value = false;
  }
};

const startCheckIn = async () => {
  const response = await sendPost('/api/collections/' + slug.value + '/start');
  if (response) {
    let questionnaireInstanceId = response.questionnaire_instance_id;
    router.push({ name: 'ActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: questionnaireInstanceId } });
  }
};

// OnMounted hook
onMounted(() => {
  slug.value = route.params.slug;
  getData();
});
</script>
