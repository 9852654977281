import axios from "axios";

export const state = {
  lifeslices: [],
};

export const mutations = {
  setLifeslices: (state, lifeslices) => (state.lifeslices = lifeslices),
  addLifeslice: (state, lifeslice) => state.lifeslices.push(lifeslice),
  removeLifeslice: (state, id) =>
    (state.lifeslices = state.lifeslices.filter((l) => l.id !== id)),
};

export const actions = {
  // Fetch all lifeslices
  async fetchLifeslices({ commit }) {
    try {
      const response = await axios.get("/api/lifeslices");
      commit("setLifeslices", response.data.lifeslices);
    } catch (error) {
      alert("Failed to fetch lifeslices.");
      console.error(error);
    }
  },

  // Add a new lifeslice
  async addLifeslice({ commit }, lifeslice) {
    try {
      const response = await axios.post("/api/lifeslices", lifeslice);
      commit("addLifeslice", response.data.lifeslice); // Assuming the response contains the newly created lifeslice
    } catch (error) {
      alert("Failed to add lifeslice.");
      console.error(error);
    }
  },

  // Update an existing lifeslice
  async updateLifeslice({ commit, state }, updatedLifeslice) {
    try {
      const response = await axios.put(
        `/api/lifeslices/${updatedLifeslice.id}`,
        updatedLifeslice
      );
    
      // Find the index of the lifeslice being updated
      const index = state.lifeslices.findIndex((lifeslice) => lifeslice.id === updatedLifeslice.id);
    
      if (index !== -1) {
        // Replace the updated lifeslice in the array
        state.lifeslices[index] = response.data.lifeslice;
      }

      // Alternatively, commit a mutation that updates the lifeslice directly
      // commit('updateLifeslice', response.data.lifeslice); // if you have an 'updateLifeslice' mutation

    } catch (error) {
      alert("Failed to update lifeslice.");
      console.error(error);
    }
  },

  // Delete a lifeslice
  async deleteLifeslice({ commit }, id) {
    try {
      await axios.delete(`/api/lifeslices/${id}`);
      commit("removeLifeslice", id);
    } catch (error) {
      alert("Failed to delete lifeslice.");
      console.error(error);
    }
  },
};

export const getters = {
  getLifeslices(state) {
    return state.lifeslices;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};