<template>
  <ion-modal :is-open="isOpen" @ionModalDidDismiss="closeModal">
    <ion-header>
      <ion-toolbar>
        <ion-title>Spotlight Answer</ion-title>
        <ion-buttons slot="end">
          <ion-button @click="closeModal">
            <ion-icon :icon="close" slot="icon-only" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-padding">
      <!-- Scrollable content -->
      <p class="border-b text-base font-semibold text-gray-800">{{ spotlightQuestion }}</p>
      <p class="text-base text-gray-700 mt-4">{{ spotlightAnswer }}</p>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue';
import { close, home, person, videocam, chatbubbles, pizza } from 'ionicons/icons';
import { useRouter, useRoute } from 'vue-router';
import { IonModal, IonHeader, IonToolbar, IonTitle, IonButtons, IonButton, IonIcon, IonContent } from '@ionic/vue';

const props = defineProps({
  isOpen: Boolean,
  spotlightQuestion: String,
  spotlightAnswer: String
});

const emit = defineEmits(['close']);

const router = useRouter();
const route = useRoute();
const isOpen = ref(props.isOpen);

// Track the original route before opening the modal
const originalRoute = ref(route.fullPath);

// Watch for changes in the `isOpen` prop
watch(() => props.isOpen, (newValue) => {
  isOpen.value = newValue;
});

function closeModal() {
  // Close the modal
  isOpen.value = false;
  emit('close');

  // Navigate back to the original route, without adding the modal URL to the history
  setTimeout(() => {
    // Use `router.replace` to replace the modal URL with the original route
    router.replace(originalRoute.value);
  }, 0);
}
</script>

<style scoped>
/* Optional custom styles */
</style>