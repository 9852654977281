<template>

  <ion-page>
    <Header :title="'Spotlight'" :default-href="'/test-home'" />
      <ion-content class="ion-padding">

        <ion-card>    
          <img alt="spotlight image" src="/img/card-test.jpg" />
            <ion-card-header class="absolute top-0 right-0 p-4">
      
              <ion-chip fill="solid" style="background-color: white; color: var(--ion-color-dark); font-size: 12px; font-weight: 600;">
                {{ getSpotlight?.status }}
              </ion-chip>
                  
          </ion-card-header>
          <ion-card-header>
            <ion-card-title style="font-size: 18px;">{{ getSpotlight?.spotlight_question }}</ion-card-title>
            <ion-card-subtitle style="color: var(--ion-color-primary);">
              Spotlight
            </ion-card-subtitle>    
            </ion-card-header>
        
            <ion-card-content>
<!--Answer Box-->
<form v-if="getSpotlight" @submit.prevent="updateSpotlight">
    
      <!-- Textarea -->
      <ion-textarea
        v-model="getSpotlight.spotlight_answer"
        rows="12"
        placeholder="Write your answer here"
      />
   

    <!-- Submit Button -->    
    <ion-button type="submit" :disabled="!getSpotlight.spotlight_answer" expand="block">
      Save
    </ion-button>
  </form>

      </ion-card-content>
    </ion-card>

<!--<p class="mb-2 text-right text-gray-600 text-xs">Date - 11th May</p>-->

    </ion-content>
  </ion-page>

</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import Header from '../components/Header';
import { useStore } from 'vuex';
import router from '../router';
import { IonCardTitle, IonPage, IonContent, IonCardSubtitle, IonItem, IonTextarea, IonChip, IonCol, IonCard, IonImg, IonLabel, IonCardContent, IonButton, IonCardHeader } from '@ionic/vue';

const store = useStore();

// Make getSpotlight a reactive reference to avoid issues with v-model
const getSpotlight = computed(() => {
  return store.getters.getSpotlight; // Assuming the spotlight object comes from Vuex
});

// Update Spotlight logic
const updateSpotlight = () => {
  // Dispatch the action to update the spotlight in the Vuex store
  store.dispatch('updateSpotlight', getSpotlight.value);
  // After updating, navigate to the /spotlights page
  router.push('/spotlights');
};

// Fetch spotlight data on mounted
onMounted(() => {
  store.dispatch('fetchSpotlight');
});
</script>