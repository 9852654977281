<template>
  <ion-page>
    <ion-content>
    <!-- Loading Overlay -->
    <ion-grid class="ion-no-padding">
      <ion-row>
        <ion-col>
  
 
<!-- Dashboard Header -->
    <ion-toolbar class="ion-margin-bottom">
      <ion-buttons slot="start" class="ion-padding-start">
    <h4 class="ion-font-weight-bold">Hi {{ userName }} 👋</h4>
     </ion-buttons>
      <!-- Right Side (Notifications and Profile) -->
      <ion-buttons slot="end" class="ion-padding-end">
        <!-- Notification Bell -->
        <NotificationBell class="h-7 w-auto" />
        <!-- User Profile Icon -->
        <InitialsComponent />
      </ion-buttons>
    </ion-toolbar>
<!-- END Dashboard Header -->

      <dashboard-next-session />


      <header-in-page title="My Mental Health" end-link="collections/psychological-health" end-text="See All"/>
      <dashboard-collections />


      <header-in-page title="Next Spotlight" end-link="/spotlights" end-text="See All"/>
      <dashboard-spotlights />

      <header-in-page title="Next Activity" end-link="/activities" end-text="See All"/>
      <dashboard-activities />

        </ion-col>
      </ion-row>
        </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script setup>

  import { ref, computed } from 'vue';
  import DashboardCollections from '../components/DashboardCollections';
  import LoadingOverlay from '../components/LoadingOverlay';
  import DashboardNextSession from '../components/DashboardNextSession';
  import DashboardActivities from '../components/DashboardActivities';
  import HeaderInPage from '../components/HeaderInPage';
  import DashboardSpotlights from '../components/DashboardSpotlights';
  import WelcomeModal from '../components/WelcomeModal';
  import NotificationBell from '../components/NotificationBell';
  import InitialsComponent from '../components/InitialsComponent.vue';
  import { useStore } from 'vuex';
  import { 
  IonPage, 
  IonContent, 
  IonButtons,  
  IonButton, 
  IonGrid,
  IonCol,
  IonRow,
  IonToolbar,
  } from '@ionic/vue';

// Vuex store access
const store = useStore();

// Computed properties
const user = computed(() => store.getters['user']);
const userName = computed(() => {
  if (user.value) {
    return user.value.name.split(' ')[0];
  }
  return 'there';
});

const loading = ref(true);
// Simulate loading operation (e.g., data fetching)
setTimeout(() => {
  loading.value = false; // After data fetch or some operation, set loading to false
}, 1000); // Simulate a 5 second delay

</script>
