<template>
  <ion-page>
    <!-- Header section -->
    <ion-header>
      <ion-toolbar>
        <!-- Custom Back Button -->
        <ion-buttons slot="start">
          <button @click="goBack" class="circle-button">
            <ion-icon :icon="chevronBackOutline" class="icon"></ion-icon>
          </button>
        </ion-buttons>

        <!-- Title -->
        <ion-title>{{ currentTitle }}</ion-title>

        <!-- End Buttons (e.g., User Initials) -->
        <ion-buttons slot="end">
          <router-link to="/settings">
            <div class="circle-button">
              <span class="initials">{{ userInitials }}</span>
            </div>
          </router-link>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <!-- Main content section (where the route content will be injected) -->
 
      <router-view />
  

    <!-- Footer section -->
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="home" href="/dashboard">
          <ion-icon :icon="home" />
          <ion-label>Home</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="sessions" href="/sessions">
          <ion-icon :icon="videocam" />
          <ion-label>Sessions</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="my-journey" href="/me">
          <ion-icon :icon="person" />
          <ion-label>Me</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="chat" href="/chat">
          <ion-icon :icon="chatbubbles" />
          <ion-label>Chat</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="activities" href="/toolbox">
          <ion-icon :icon="pizza" />
          <ion-label>Toolbox</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script setup>
import { IonHeader, IonButtons, IonTitle, IonToolbar, IonContent, IonPage, IonTabs, IonRouterOutlet, IonTabBar, IonTabButton, IonLabel, IonIcon } from '@ionic/vue';
import { chevronBackOutline, home, person, videocam, chatbubbles, pizza } from 'ionicons/icons';
import { useStore } from 'vuex';
import { computed, watchEffect } from 'vue';
import { useRoute } from 'vue-router';

const store = useStore();
const route = useRoute(); // Get the current route
const user = store.getters.user;

const userInitials = computed(() => {
  if (user && user.name) {
    return user.name
      .split(' ')
      .map((namePart) => namePart[0])
      .join('');
  }
  return '';
});

// Dynamically set the title from the route meta
const currentTitle = computed(() => route.meta.title || 'Default Title');

// Handle back navigation
function goBack() {
  window.history.back();
}
</script>

<style scoped>
/* General Button Style */
.circle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px; /* Ensure consistent size */
  height: 25px;
  background-color: white; /* White background */
  border-radius: 50%; /* Circular shape */
  border: 1px solid var(--ion-color-medium); /* Optional border for clarity */
  cursor: pointer;
}

/* Back Icon Styling */
.circle-button .icon {
  font-size: 18px;
  color: var(--ion-color-dark); /* Dark icon */
}

/* User Initials Styling */
.circle-button .initials {
  font-size: 14px;
  font-weight: bold;
  color: var(--ion-color-dark); /* Dark text */
  text-transform: uppercase; /* Optional for uppercase initials */
}
</style>