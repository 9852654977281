import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from "./store";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'chartkick/chart.js'
import 'chartjs-adapter-date-fns'
import VueChartkick from 'vue-chartkick'
import { IonicVue } from '@ionic/vue';
import 'ionicons/icons';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';


import { Capacitor } from '@capacitor/core'; // Import Capacitor

window.axios = require('axios');


let apiBaseUrl = ''; // Declare the base URL

// Check if running in a Capacitor environment
if (Capacitor.isNativePlatform()) {
  console.log('Capacitor native platform detected');
  // Change host.docker.internal to localhost for the Capacitor app
  apiBaseUrl = 'http://localhost:80';
} else {
  console.log('Browser platform detected');
  // Use localhost for the browser
  apiBaseUrl = 'http://localhost:80';
}

// Set Axios's default base URL
window.axios.defaults.baseURL = apiBaseUrl;
console.log('API Base URL:', window.axios.defaults.baseURL);

// Set Axios headers
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['Content-Type'] = 'application/json';
window.axios.defaults.headers.common['Accept'] = 'application/json';
window.axios.defaults.withCredentials = true;

// After CSRF cookie is set, ensure the CSRF token is included in requests
window.axios.defaults.headers.common['X-XSRF-TOKEN'] = getCookie('XSRF-TOKEN');

// CSRF token getter function
function getCookie(name) {
  let cookieArr = document.cookie.split(';');
  for (let i = 0; i < cookieArr.length; i++) {
    let cookie = cookieArr[i].trim();
    if (cookie.indexOf(name) === 0) {
      console.log('Cookie found:', cookie);  // Log the cookie to verify
      return cookie.substring(name.length, cookie.length);
    }
  }
  console.log('Cookie not found');  // Log if cookie is not found
  return null;
}

// Axios response interceptor to handle 419 error (CSRF token expired)
window.axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response && error.response.status === 419) {
    location.reload();  // Reload to get a fresh CSRF token
  }
  return Promise.reject(error);
});

store.dispatch('attempt_user')
  .catch((error) => {
    if(error.response?.status !== 401) {
        console.error(error)
    }
  })
  .finally(() => {
    const app = createApp(App)
        app.use(store)
        app.use(router);
        app.use(IonicVue, {mode: 'ios'});
        app.use(VueChartkick)
        app.use(AOS.init());

    app.mount('#app');

  })
