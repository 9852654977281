<template>
  <div class="min-h-full bg-page-background">

<!-- Mobile Sidebar Transition -->
    <TransitionRoot as="template" :show="sidebarOpen">
      <Dialog as="div" class="relative z-40 lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <div class="fixed inset-0 bg-gray-600 bg-opacity-75" />
        </TransitionChild>

        <div class="fixed inset-0 flex z-40">
          <TransitionChild as="template" enter="transition ease-in-out duration-300 transform" enter-from="-translate-x-full" enter-to="translate-x-0" leave="transition ease-in-out duration-300 transform" leave-from="translate-x-0" leave-to="-translate-x-full">
            <DialogPanel class="relative flex-1 flex flex-col max-w-xs w-full pb-4 bg-white">
              <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">

                <div class="absolute pr-24 right-0 bottom-0 py-4">
                  <button type="button" class="flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-500" @click="sidebarOpen = false">
                    <XMarkIcon class="h-6 w-6 text-gray-600" aria-hidden="true" />
                  </button>
                </div>
              </TransitionChild>
<!-- Mobile Sidebar -->
              <div class="bg-transparent flex-shrink-0 flex items-center px-4 py-2 mt-6">
                <img class="h-10 w-auto" src="/img/logo-dark.svg" alt="Youlio logo" />
              </div>
              <nav class="mt-4 flex-shrink-0 h-full divide-y divide-gray-200 overflow-y-auto" aria-label="Sidebar">
                <div class="px-4 space-y-1">
                  <router-link @click="sidebarOpen = false" :to="item.router" v-for="item in mobileNavigation" :key="item.name" :class="[item.current ? 'bg-gray-100 text-gray-700' : 'text-gray-700 hover:text-gray-700 hover:bg-gray-100', 'group flex items-center px-2 py-2 font-sans text-md font-medium rounded-md']" :aria-current="item.current ? 'page' : undefined">
                    <component :is="item.icon" class="mr-4 flex-shrink-0 h-auto w-7 text-primary" aria-hidden="true" />
                    {{ item.name }}
                  </router-link>
                </div>
                <div class="mt-6 pt-6">
                  <div class="px-4 space-y-1">
                    <router-link @click="sidebarOpen = false" :to="item.router" v-for="item in secondaryNavigation" :key="item.name" class="group flex items-center px-2 py-2 bg-white font-sans text-medium font-medium rounded-md text-gray-700 hover:text-gray-700 hover:bg-gray-100">
                      <component :is="item.icon" class="mr-4 h-auto w-7 text-primary" aria-hidden="true" />
                      {{ item.name }}
                    </router-link>
     
                  
                  </div>
                </div>
              </nav>
            </DialogPanel>
          </TransitionChild>
          <div class="flex-shrink-0 w-14" aria-hidden="true">
        <!-- Dummy element to force sidebar to shrink to fit close icon -->
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
<!-- END mobile sidebar -->


<!-- Static sidebar for desktop -->
<div class="hidden lg:fixed lg:inset-y-0 lg:z-10 lg:flex lg:w-64 lg:flex-col">
      <div class="flex flex-col flex-grow bg-white shadow-sm pt-16 overflow-y-auto">
        <nav class="ml-6  flex-1 flex flex-col divide-y divide-gray-300 overflow-y-auto" aria-label="Sidebar">
          <div class="space-y-3 mt-12">
            <router-link :to="item.router" v-for="item in desktopNavigation" :key="item.name" :class="[item.current ? 'text-primary-600' : 'text-gray-700 hover:text-gray-700 hover:bg-gray-100', 'group flex gap-x-3 rounded-md p-2 text-sm font-normal leading-6']">
              <component :is="item.icon" :class="[item.current ? 'text-text-gray-500' : 'text-gray-700 group-hover:text-gray-700', 'h-6 w-6 shrink-0']" aria-hidden="true" />
              {{ item.name }}
            </router-link>
          </div>
        </nav>
      </div>
    </div>

<!-- Desktop Header -->
<div class="">
      <div class=" w-full hidden lg:flex fixed top-0 z-40 flex h-20 shrink-0 items-center gap-x-4 bg-white px-4 border-b border-gray-100 sm:gap-x-6 sm:px-6 lg:px-8">
        <div class="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">

          <div class="flex items-center flex-shrink-0">
          <img class="-ml-1 h-9 w-auto" src="/img/logo-dark.svg" alt="Youlio logo" />
        </div>
          <div class="relative flex flex-1">
          </div>

          <div class="flex items-center gap-x-4 lg:gap-x-6">
<!-- Notifications -->
<button type="button" @click="togglePopup" class="relative">
  <BellIcon class="h-6 w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
  <span v-if="hasUnreadNotifications" class="absolute top-0 right-0 block h-2 w-2 transform translate-x-1/2 -translate-y-1/2 rounded-full animate-pulse bg-green-400 ring-2 ring-white"></span>

  <div v-if="showPopup" class="absolute right-0 mt-[-1.5rem] w-64 max-w-sm bg-white rounded-lg shadow-lg p-2 flex flex-col gap-2">
    <div v-for="notification in unreadNotifications" :key="notification.chat_id" class="bg-blue-100 text-gray-600 text-sm font-normal px-4 py-3" role="alert">
      <router-link :to="{ name: 'Chat', params: { chatId: notification.chat_id } }" class="text-gray-600">
        <p>
          You have {{ notification.unread }} unread message{{ notification.unread > 1 ? 's' : '' }} in your chat with {{ notification.name }}
        </p>
      </router-link>
    </div>
  </div>
</button>

            <!-- Separator -->
            <div class="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" aria-hidden="true" />

            <!-- Profile dropdown -->
            <Menu as="div" class="relative">
  <MenuButton class="-m-1.5 flex items-center p-1.5">
    <span class="sr-only">Open user menu</span>
    <!-- Round circle with user's initials -->
    <span class="inline-block h-8 w-8 overflow-hidden rounded-full bg-secondary-400 flex items-center justify-center">
      <span class="text-white font-semibold">
        {{ user?.name ? user.name.split(' ').map(n => n[0]).join('') : '' }}
      </span>
    </span>
  </MenuButton>
  <transition
    enter-active-class="transition ease-out duration-100"
    enter-from-class="transform opacity-0 scale-95"
    enter-to-class="transform opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95">
    <MenuItems
      class="absolute right-0 z-10 mt-2.5 w-48 origin-top-right rounded-md bg-white pl-4 py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
      <MenuItem v-slot="{ active }">
        <router-link to="/settings" :class="[active ? 'bg-gray-50' : '', 'block py-1 text-base font-medium ml-4 leading-6 text-gray-800']">
          Settings
        </router-link>
      </MenuItem>
      <MenuItem>
        <log-out-modal />
      </MenuItem>
    </MenuItems>
  </transition>
</Menu>

          </div>
        </div>
      </div>
<!-- END Desktop Header -->
</div>

<!-- Main App -->
      <main class="flex-1 lg:pl-64">
          <div class="max-w-7xl mx-auto">
            <router-view />
          </div>
      </main>
<!-- END Main App -->


<!-- Footer Mobile Menu -->
<div class="lg:pl-64 flex flex-col flex-1 lg:hidden">
      <div class="h-16 standalone:h-20 px-8 flex flex-row justify-between border-t border-gray-200 bg-gray-100 fixed inset-x-0 bottom-0 w-screen z-10 flex-shrink-0 flex lg:border-none">
        <!-- Home -->
          <div class="pt-3 flex-shrink-0">
            <router-link to="/dashboard">
              <HomeIcon class="mx-auto h-8 w-8 text-gray-500" aria-hidden="true" />
              
              <p class="font-light text-xs text-gray-600 text-center">Home</p>
             
            </router-link>
	        </div>
           <!-- Sessions -->
           <div class="pt-3 flex-shrink-0">
            <router-link to="/sessions">
              <VideoCameraIcon class="mx-auto h-8 w-8 text-gray-500" aria-hidden="true" />
             
              <p class="font-light text-xs text-gray-600 text-center">Sessions</p>
             
            </router-link>
	        </div>

            <!-- Journey -->
            <div class="pt-3 flex-shrink-0">
            <router-link to="/my-journey">
              <MapIcon class="mx-auto h-8 w-8 text-gray-500" aria-hidden="true" />
             
              <p class="font-light text-xs text-gray-600 text-center">My Journey</p>
             
            </router-link>
	        </div>

       
            <!-- Chat -->
            <div class="pt-3 flex-shrink-0">
            <router-link to="/chat">
              <ChatBubbleOvalLeftEllipsisIcon class="h-8 w-8 text-gray-500" aria-hidden="true" />
               
              <p class="font-light text-xs text-gray-600 text-center">Chat</p>
           
            </router-link>
	        </div>

 <!-- Menu -->
 <div class=" pt-3 flex-shrink-0">
            <button type="button" class="" @click="sidebarOpen = true">
            <Bars3Icon class="h-8 w-8 text-gray-500" aria-hidden="true" />
            <p class="font-light text-xs text-gray-600 text-center">More</p>
          </button>
	        </div>

          

        <!-- Notifications 
        <div class="pt-3 flex-shrink-0 md:ml-6 relative">
  <button type="button" @click="togglePopup" class="bg-transparent rounded-full text-gray-500 hover:text-gray-500 focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-indigo-500">
    <BellIcon class="h-8 w-8" aria-hidden="true" />
  </button>
  <div v-if="showPopup" class="absolute -right-6 mt-[-9rem] w-64 max-w-sm bg-white rounded-lg shadow-lg p-2 flex flex-col gap-2">
    <div v-for="notification in unreadNotifications" :key="notification.chat_id" class="bg-blue-100 text-gray-600 text-sm font-normal px-4 py-3" role="alert">
      <router-link :to="{ name: 'Chat', params: { chatId: notification.chat_id } }" class="text-gray-600">
        <p>
          You have {{ notification.unread }} unread message{{ notification.unread > 1 ? 's' : '' }} in your chat with {{ notification.name }}
        </p>
      </router-link>
    </div>
  </div>
  <span v-if="hasUnreadNotifications" class="text-xs text-white absolute top-[+0.5rem] right-[-0rem] block h-2 w-2 transform rounded-full animate-pulse bg-green-400 ring-2 ring-white"></span>
</div>
-->

</div>
</div>



  </div>
</template>

<script setup>

  import { ref } from 'vue'
  import { Dialog, DialogPanel, DialogTitle, Menu, MenuButton, MenuItem, MenuItems, TransitionChild, TransitionRoot,} from '@headlessui/vue'
  import { ChevronRightIcon, HeartIcon, BellIcon, XMarkIcon, CogIcon, ChartBarIcon, HomeIcon, Bars3Icon, ShieldCheckIcon, VideoCameraIcon, ChatBubbleLeftEllipsisIcon, PuzzlePieceIcon, MapIcon, NewspaperIcon, FingerPrintIcon, LightBulbIcon, ChatBubbleOvalLeftEllipsisIcon, ClipboardDocumentListIcon } from '@heroicons/vue/24/outline'
  import { InboxIcon, ChevronDownIcon } from '@heroicons/vue/24/solid'
  import { mapActions, mapGetters } from "vuex";
  import { useRouter } from 'vue-router';
  import LogOutModal from '../components/LogOutModal';
  import { sendPost } from '../components/entities/functions.js';

  const sidebarOpen = ref(false)
</script>

<script>
export default {
  data: function () {
      return {
        showPopup: false,
        mobileNavigation: [
           
            { name: 'Activities', router: '/activities', icon: PuzzlePieceIcon, current: false },
            { name: 'Spotlights', router: '/spotlights', icon: LightBulbIcon, current: false },
            { name: 'Deep Dive', router: '/deep-dive', icon: FingerPrintIcon, current: false },
            { name: 'Tracking', router: '/collections/psychological-health', icon: ChartBarIcon, current: false },
         //  { name: 'Feelings Finder', router: '/feelings-finder', icon: HeartIcon, current: false },
          // { name: 'Values Finder', router: '/values-finder', icon: HeartIcon, current: false },
           { name: 'My Notes', router: '/user-notes', icon: ClipboardDocumentListIcon, current: false },
           
        ],
        desktopNavigation: [
            { name: 'Home', router: '/dashboard', icon: HomeIcon, current: false },
            { name: 'Sessions', router: '/sessions', icon: VideoCameraIcon, current: false },
            { name: 'Chat', router: '/chat', icon: ChatBubbleLeftEllipsisIcon, current: false },
            { name: 'Activities', router: '/activities', icon: PuzzlePieceIcon, current: false },
            { name: 'Spotlights', router: '/spotlights', icon: LightBulbIcon, current: false },
            { name: 'Deep Dive', router: '/deep-dive', icon: FingerPrintIcon, current: false },
            { name: 'My Journey', router: '/my-journey', icon: MapIcon, current: false },
            { name: 'My Notes', router: '/user-notes', icon: ClipboardDocumentListIcon, current: false },
           
        ],
        secondaryNavigation: [
            
        ],
        userNavigation: [
            { name: 'Your profile', href: '#' },
            { name: 'Sign out', href: '#' },
        ]
      }
  },

  beforeMount() {
  // Ensure 'user', 'mobileNavigation', and 'desktopNavigation' are properly defined
  if (this.user && this.user.is_therapist) {
    // Define the new navigation item
    const newItem = {
      name: 'Clients Panel',
      router: '/clients-panel',
      icon: ClipboardDocumentListIcon,
      current: false
    };

    // Ensure 'mobileNavigation' and 'desktopNavigation' are arrays
    if (Array.isArray(this.mobileNavigation)) {
      this.mobileNavigation.push(newItem);
    } else {
      console.error('Mobile navigation is not defined or not an array.');
    }

    if (Array.isArray(this.desktopNavigation)) {
      this.desktopNavigation.push(newItem);
    } else {
      console.error('Desktop navigation is not defined or not an array.');
    }
  }

  // Ensure $router is available
  if (this.$router) {
    // Add a route guard to handle the navigation items
    this.$router.beforeEach((to, from, next) => {
      const isMobile = typeof window !== 'undefined' && window.innerWidth < 1024;
      const navigationArray = isMobile ? this.mobileNavigation : this.desktopNavigation;

      // Ensure navigation arrays are defined
      if (Array.isArray(navigationArray)) {
        navigationArray.forEach((item) => {
          item.current = item.router === to.path;
        });
      } else {
        console.error('Navigation arrays are not defined or not an array.');
      }
      next();
    });
  } else {
    console.error('$router is not available.');
  }
},

  mounted() {
    this.sendOnlineStatus()

    this.timer = setInterval(() => {
      this.sendOnlineStatus()
    }, 10 * 1000)
  },

  beforeDestroy() {
    clearInterval(this.timer)
  },

  computed: {
    ...mapGetters({
      unreadNotifications: 'chatStore/unreadNotifications'
    }),
    hasUnreadNotifications() {
      return this.unreadNotifications.length > 0;
    },
    user() {
      return this.$store.getters['user'];
    }
  },

  watch: {
      hasUnreadNotifications(value) {
         if (!value && this.showPopup) {
          this.showPopup = false;
         }
      }
   },

  methods: {
    ...mapActions({
      updateOnlineStatuses: 'chatStore/updateOnlineStatuses'
    }),

    async sendOnlineStatus() {
      let response = await sendPost('/api/online');
      if (response) {
        this.updateOnlineStatuses(response.online_statuses);
      }
    },

    togglePopup() {
      if (!this.hasUnreadNotifications) {
        this.showPopup = false;
        return;
      }
      this.showPopup = !this.showPopup;
    }
  }
}
</script>
