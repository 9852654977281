<template>
  <ion-page>
   

    <ion-content class="ion-padding">
      <!-- Back Button (Chevron Icon Only) -->
      <div v-if="currentStep > 0" class="flex justify-start mb-2">
        <ion-button
          @click="goBack"
          :disabled="currentStep === 0"
          class="p-0.5 flex rounded-full"
          fill="clear"
          data-aos="fade-down"
          data-aos-duration="300"
        >
          <ion-icon :icon="chevronBack" slot="icon-only"></ion-icon>
        </ion-button>
      </div>

      <!-- Current Step -->
      <ion-card class="max-w-md mx-auto" data-aos="fade-up" data-aos-duration="400">
        <!-- Step 0: Welcome Screen -->
        <div v-if="currentStep === 0" data-aos="fade-in" data-aos-duration="600">
          <ion-card-header>
            <ion-card-title class="text-primary text-center text-2xl">
              Welcome to the Problems Finder
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p class="text-gray-600 font-normal text-base">
              This tool helps you identify your feelings in the moment, which is the first step toward managing them effectively.
              <br /><br />
              Often, our emotional vocabulary is limited, and by regularly checking in with how you’re feeling, you’ll become more in touch with your emotions.
              <br /><br />
              It’s not about tracking, just acknowledging your feelings and moving forward with your day.
            </p>
            <ion-button expand="block" class="mt-4" @click="goForward" data-aos="zoom-in" data-aos-duration="300">
              Let’s Begin
            </ion-button>
          </ion-card-content>
        </div>

        <!-- Step 1: Feeling Type -->
        <template v-if="currentStep === 1">
          <ion-card-header>
            <ion-card-title class="text-center">Firstly, is the feeling...</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-row class="ion-justify-content-center ion-align-items-center">
                <ion-col
                  size="6"
                  v-for="(type, index) in Object.keys(feelingsData)"
                  :key="index"
                  class="ion-text-center"
                  data-aos="fade-right"
                  :data-aos-delay="200 + index * 100"
                >
                  <ion-button expand="block" color="primary" @click="selectFeelingType(type)">
                    {{ type }}
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </template>

        <!-- Step 2: Feeling Category -->
        <template v-if="currentStep === 2">
          <ion-card-header>
            <ion-card-title class="text-center">Now choose a category...</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col
                  size="12"
                  v-for="category in Object.keys(feelingsData[selectedFeelingType] || {})"
                  :key="category"
                  class="ion-text-center"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <ion-button expand="block" fill="outline" @click="selectCategory(category)">
                    {{ category }}
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </template>

        <!-- Step 3: Specific Feelings -->
        <template v-if="currentStep === 3">
          <ion-card-header>
            <ion-card-title class="text-center">
              Pick the feeling that resonates with you...
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-grid>
              <ion-row>
                <ion-col
                  size="12"
                  v-for="feeling in feelingsByCategory"
                  :key="feeling.name"
                  class="ion-text-center"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <ion-button expand="block" fill="outline" @click="selectSpecificFeeling(feeling.name)">
                    I feel <span class="font-bold">{{ feeling.name }}</span>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </ion-card-content>
        </template>

        <!-- Step 4: Reflection -->
        <template v-if="currentStep === 4">
          <ion-card-header>
            <ion-card-title class="text-center">
              I'm feeling
              <span class="font-bold">{{ feelings.specific ? feelings.specific.toLowerCase() : '...' }}</span>
              because...
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-textarea
              v-model="feelings.reflection"
              rows="6"
              placeholder="Start typing..."
              data-aos="zoom-in"
              data-aos-duration="500"
            ></ion-textarea>
            <ion-row class="ion-margin-top">
              <ion-col>
                <ion-button expand="block" color="secondary" @click="completeCheckIn">Skip</ion-button>
              </ion-col>
              <ion-col>
                <ion-button expand="block" @click="completeCheckIn">Finish</ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </template>

        <!-- Step 5: Add Another Feeling -->
        <template v-if="currentStep === 5">
          <ion-card-header>
            <ion-card-title class="text-center">Would you like to add another feeling?</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-row>
              <ion-col>
                <ion-button expand="block" @click="addAnotherFeeling">Yes</ion-button>
              </ion-col>
              <ion-col>
                <ion-button expand="block" @click="finalComplete">No</ion-button>
              </ion-col>
            </ion-row>
          </ion-card-content>
        </template>

        <!-- Step 6: Summary -->
        <template v-if="currentStep === 6">
          <ion-card-header>
            <ion-card-title class="text-center">Your Feelings Summary</ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-list>
              <ion-item
                v-for="(feeling, index) in feelingsList"
                :key="index"
                class="ion-margin-vertical"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <ion-label>
                  <h3 class="text-lg">I feel <span class="font-bold">{{ feeling.specific }}</span></h3>
                  <p>
                    This is because {{ feeling.reflection || '(no reflection added)' }}
                  </p>
                </ion-label>
              </ion-item>
            </ion-list>
            <ion-button expand="block" color="primary" @click="resetProcess" data-aos="fade-in" data-aos-duration="300">
              Finish
            </ion-button>
          </ion-card-content>
        </template>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import Header from '../components/Header';
import { feelingsData } from '../data/feelingsData';
import { chevronBack } from 'ionicons/icons';

import { 
  IonPage, 
  IonContent, 
  IonRow, 
  IonGrid, 
  IonList,
  IonTextarea,
  IonRadio,
  IonRadioGroup,
  IonCol,
  IonIcon,
  IonHeader, 
  IonFooter, 
  IonToolbar, 
  IonCard,
  IonCardTitle,
  IonCardHeader,  
  IonCardContent, 
  IonText, 
  IonButton, 
  IonItem, 
  IonCheckbox, 
  IonLabel, 
  IonInput, 
  IonImg 
  } from '@ionic/vue';

// Reactive state
const currentStep = ref(0); // Start with the introduction
const selectedFeelingType = ref(null);
const selectedCategory = ref(null);
const feelingsList = ref([]); // Store all feelings
const feelings = ref({
  primary: null,
  category: null,
  specific: null,
  reflection: null,
});

// Computed property to filter feelings by selected type and category
const feelingsByCategory = computed(() => {
  return feelingsData[selectedFeelingType.value]?.[selectedCategory.value] || [];
});

// Initialize on page load
onMounted(() => {
  currentStep.value = 0; // Show introduction
});

// Step control
const goBack = () => {
  currentStep.value = Math.max(0, currentStep.value - 1);
};
const goForward = () => {
  if (currentStep.value === 0) {
    currentStep.value = 1; // Move to Feeling Type selection
  } else if (currentStep.value < 5) {
    currentStep.value++;
  } else if (currentStep.value === 5) {
    currentStep.value = 6; // Move to Summary
  }
};

// Handlers for selections
const selectFeelingType = (type) => {
  selectedFeelingType.value = type;
  feelings.value.primary = type;
  currentStep.value = 2;
};
const selectCategory = (category) => {
  selectedCategory.value = category;
  feelings.value.category = category;
  currentStep.value = 3;
};
const selectSpecificFeeling = (name) => {
  feelings.value.specific = name;
  currentStep.value = 4;
};

// Helper to check if the current feeling is already added
const isFeelingDuplicate = (feeling) => {
  return feelingsList.value.some(
    (item) =>
      item.primary === feeling.primary &&
      item.category === feeling.category &&
      item.specific === feeling.specific
  );
};

// Add another feeling
const addAnotherFeeling = () => {
  if (
    feelings.value.primary &&
    feelings.value.specific &&
    !isFeelingDuplicate(feelings.value)
  ) {
    feelingsList.value.push({ ...feelings.value });
  }
  currentStep.value = 1; // Reset to Feeling Type selection
  selectedFeelingType.value = null;
  selectedCategory.value = null;
  feelings.value = {
    primary: null,
    category: null,
    specific: null,
    reflection: null,
  };
};

// Completion
const completeCheckIn = () => {
  if (
    feelings.value.primary &&
    feelings.value.specific &&
    !isFeelingDuplicate(feelings.value)
  ) {
    feelingsList.value.push({ ...feelings.value });
  }
  currentStep.value = 5; // Move to Add Another Feeling
};
const finalComplete = () => {
  currentStep.value = 6; // Move to Summary
};

// Reset the process
const resetProcess = () => {
  currentStep.value = 0; // Return to introduction
  selectedFeelingType.value = null;
  selectedCategory.value = null;
  feelingsList.value = [];
  feelings.value = {
    primary: null,
    category: null,
    specific: null,
    reflection: null,
  };
};

// Can go forward based on current step
const canGoForward = computed(() => {
  if (currentStep.value === 0) return true; // Always allow moving forward from intro
  if (currentStep.value === 1) return !!selectedFeelingType.value;
  if (currentStep.value === 2) return !!selectedCategory.value;
  if (currentStep.value === 3) return !!feelings.value.specific;
  if (currentStep.value === 4) return true; // Can always move to Step 5
  return false;
});
</script>

