export const feelingsData = {
  Joy: {
    Joyful: [
      { name: 'happy', description: 'Feeling of joy or contentment.' },
      { name: 'excited', description: 'Feeling thrilled or eager.' },
    ],
    Grateful: [
      { name: 'thankful', description: 'Feeling appreciative of something.' },
      { name: 'blessed', description: 'Feeling fortunate or lucky.' },
    ],
  },
  Sadness: {
    Trapped: [
      { name: 'claustrophobic', description: 'Feeling confined or restricted.' },
      { name: 'discomfort', description: 'Feeling uneasy or physically uncomfortable.' },
    ],
    Sad: [
      { name: 'sad', description: 'Feeling sorrowful or unhappy.' },
      { name: 'agony', description: 'Feeling extreme physical or emotional pain.' },
      { name: 'blah', description: 'Feeling indifferent or uninterested.' },
    ],
  },
  Anger: {
    Irritated: [
      { name: 'frustrated', description: 'Feeling annoyed or bothered.' },
      { name: 'enraged', description: 'Feeling very angry or furious.' },
    ],
    Resentful: [
      { name: 'bitter', description: 'Feeling of lingering anger or dissatisfaction.' },
      { name: 'hostile', description: 'Feeling unfriendly or antagonistic.' },
    ],
  },
  Disgust: {
    Revolted: [
      { name: 'nauseated', description: 'Feeling physically sick or repelled.' },
      { name: 'disgusted', description: 'Feeling strong disapproval or revulsion.' },
    ],
    Contemptuous: [
      { name: 'scornful', description: 'Feeling disdain or disrespect.' },
      { name: 'disdainful', description: 'Feeling superior or dismissive.' },
    ],
  },
  Surprise: {
    Shocked: [
      { name: 'stunned', description: 'Feeling unable to react due to surprise.' },
      { name: 'dumbfounded', description: 'Feeling shocked and speechless.' },
    ],
    Amazed: [
      { name: 'amazed', description: 'Feeling overwhelmed by something surprising.' },
      { name: 'astonished', description: 'Feeling filled with wonder or surprise.' },
    ],
  },
  Fear: {
    Anxious: [
      { name: 'nervous', description: 'Feeling uneasy or worried about something.' },
      { name: 'apprehensive', description: 'Feeling fearful or uncertain about the future.' },
    ],
    Terrified: [
      { name: 'scared', description: 'Feeling afraid or frightened.' },
      { name: 'panicked', description: 'Feeling a sudden rush of fear or dread.' },
    ],
  },
};