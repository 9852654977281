<template>
  <!-- Header -->

  <ion-accordion-group>
  <ion-accordion>
      <ion-item slot="header" color="light">
        <ion-label><h2>{{ processedTitle }}</h2></ion-label>
      </ion-item>
      <div class="ion-padding" slot="content">
        <ion-card>
    <ion-card-header>
      <ion-item>
        <ion-label class="ion-text-wrap">
          <h2>{{ processedTitle }}</h2>
        </ion-label>
      </ion-item>
    </ion-card-header>

    <!-- Content -->
    <ion-card-content>
      <ion-grid>
        <ion-row v-for="item in sortedScores" :key="item.id" class="ion-align-items-center">
          <!-- Item Header -->
          <ion-col size="12">
            <ion-item lines="none" @click="openModal(item)">
              <ion-label>
                <h3 class="ion-text-wrap">{{ formatFactorName(item.factor) }}</h3>
              </ion-label>
              <ion-badge slot="end" color="secondary">
                {{ item.value + (item.is_percentage ? '%' : '') }}
              </ion-badge>
            </ion-item>
          </ion-col>

          <!-- Progress Bar -->
          <ion-col size="12" v-if="item.is_percentage && !isNaN(item.value)">
            <ion-progress-bar
              :value="item.value / 100"
              :color="getFactorColor(item)"
            ></ion-progress-bar>
          </ion-col>

          <!-- Description -->
          <ion-col size="12">
            <ion-text>
              <p>{{ item.personal_factor_description }}</p>
            </ion-text>
          </ion-col>
        </ion-row>
      </ion-grid>

      <!-- Footer -->
      <ion-footer class="ion-margin-top">
        <ion-text color="medium">
          <p>
            Last Measured - 
            <strong>{{ formattedUpdatedAt }}</strong>
          </p>
        </ion-text>
      </ion-footer>
    </ion-card-content>
  </ion-card>
</div>
    </ion-accordion>
  </ion-accordion-group>

  

  <!-- Modal -->
  <ion-modal mode="ios" :is-open="isModalOpen" @willDismiss="closeModal">
    <ion-header mode="ios">
      <ion-toolbar mode="ios">
        <ion-buttons mode="ios" slot="start">
          <ion-button mode="ios" @click="closeModal">Close</ion-button>
        </ion-buttons>
        <ion-title mode="ios">{{ modalTitle }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-for="(text, index) in modalContent" :key="index">
        <p>{{ text }}</p>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { ref, computed } from 'vue';
import { IonAccordion, IonAccordionGroup, IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent, IonItem, IonLabel, IonBadge, IonProgressBar, IonFooter, IonText, IonRow, IonCol, IonGrid, IonCard, IonCardHeader, IonCardContent } from '@ionic/vue';

// Props
const props = defineProps({
  data: Object,
});

// Modal state and content
const isModalOpen = ref(false);
const modalTitle = ref('');
const modalContent = ref([]);

// Computed properties
const formattedUpdatedAt = computed(() => {
  const datetime = new Date(props.data.updated_at);
  return datetime.toLocaleDateString('en-GB');
});

const processedTitle = computed(() => {
  return props.data.title.replace('Measuring', '').trim();
});

const sortedScores = computed(() => {
  return props.data.calculated_scores
    .filter((item) => item.is_percentage)
    .sort((a, b) => b.value - a.value);
});

const formatFactorName = (value) => value || 'Score';

const getFactorColor = (item) => item.color || 'primary'; // default to 'primary' if no color

// Open the modal with selected item details
const openModal = (item) => {
  modalTitle.value = formatFactorName(item.factor);
  modalContent.value = splitSentences([item.personal_factor_description]);
  isModalOpen.value = true;
};

// Close the modal
const closeModal = () => {
  isModalOpen.value = false;
};

// Utility to split sentences
const splitSentences = (arr) => {
  const result = [];
  arr.forEach((item) => {
    const sentences = item.split('. ');
    sentences.forEach((sentence, index) => {
      result.push(index === sentences.length - 1 ? sentence : sentence + '.');
    });
  });
  return result;
};
</script>