<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div>
        <!-- Loading State -->
        <div v-if="loading" class="text-center py-6">
          <p>Loading... Please wait</p>
        </div>

        <!-- Emotional State Details -->
        <div v-else-if="emotionalstate" class="bg-white shadow-xl shadow-gray-300/10 rounded-md overflow-hidden">
          <!-- Schema Mode Name -->
          <h1 class="flex bg-gradient-to-r from-primary-200 to-secondary-200 px-4 py-4 text-base font-semibold text-secondary-600">
            {{ emotionalstate.emotionalstate }}
          </h1>

          <!-- Schema Mode Category -->
          <p class="text-gray-600 px-4 py-4">
            Category: {{ emotionalstate.category || 'N/A' }}
          </p>

          <!-- Add more details from emotionalstate if available -->
          <div v-if="emotionalstate.details">
            <p>{{ emotionalstate.details }}</p>
          </div>
        </div>

        <!-- Error State -->
        <div v-else>
          <p v-if="errorMessage">{{ errorMessage }}</p>
          <p v-else>Error: Emotional state not found.</p>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonPage, IonContent } from '@ionic/vue';
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

// Vuex Store
const store = useStore();

// Get Route Parameter (ID)
const route = useRoute();
const id = computed(() => route.params.id);

// Local State
const loading = ref(true);
const emotionalstate = ref(null);
const errorMessage = ref(null);

// Fetch Emotional State
const fetchEmotionalState = async () => {
  loading.value = true;
  errorMessage.value = null;  // Reset error before fetching
  try {
    console.log(`Fetching emotional state with ID: ${id.value}`);
    const response = await store.dispatch('fetchEmotionalstate', id.value);

    // Check if response is valid
    if (response && response.emotionalstate) {
      emotionalstate.value = response;
      console.log('Fetched emotionalstate:', emotionalstate.value);  // Log fetched data
    } else {
      errorMessage.value = 'Emotional state not found';
      console.error('Emotional state not found');
    }
  } catch (error) {
    errorMessage.value = 'Error fetching emotional state';
    console.error('Error fetching emotional state', error);
  } finally {
    loading.value = false;
  }
};

// Watch ID Changes and Refetch Data
watch(id, async (newId) => {
  if (newId) {
    console.log(`ID changed to: ${newId}`);  // Log ID changes
    await fetchEmotionalState();
  }
}, { immediate: true });

// Fetch Data on Mount
onMounted(async () => {
  console.log(`Mounted with ID: ${id.value}`);  // Log initial mount
  if (id.value) {
    await fetchEmotionalState();
  }
});
</script>