<template>
  <ion-page>
    <Header :title="'Questionnaire'" :default-href="'/test-home'" />
    <ion-content >
      <div v-if="getQuestionnaireInstance && getQuestionnaireInstance.questionnaire">
        <ion-card class="pb-8 relative">
          <IonImg :src="'/storage/' + getQuestionnaireInstance.questionnaire.image" alt="Image to represent the reading" />
          
          <ion-card-header>
            <h4>{{ getQuestionnaireInstance.questionnaire.title }}</h4>
            <ion-label>Questionnaire</ion-label>
            
          </ion-card-header>

          <ion-card-content>
            <ion-list>
              <ion-item>
                <ion-icon :icon="albumsOutline" />
                
                <ion-label><p>{{ getQuestionnaireInstance.status }}</p></ion-label>
              </ion-item>
              <ion-item>
                <ion-icon :icon="timeOutline" />
                <ion-label><p>{{ getQuestionnaireInstance.questionnaire.estimated_time }}</p></ion-label>
              </ion-item>
            </ion-list>

            <p>{{ getQuestionnaireInstance.questionnaire.description }}</p>

            <router-link :to="{ name: 'ActivityQuestionnaireInstanceStart', params: { questionnaireinstanceid: getQuestionnaireInstance.id } }">
              <IonButton expand="full">
                {{ buttonText }}
              </IonButton>
            </router-link>
          </ion-card-content>
        </ion-card>
      </div>

      <div v-else class="text-display-base">
        Loading...
      </div>
    </ion-content>
  </ion-page>
</template>

<script setup>
 
  import { ref, onMounted, computed } from "vue";
  import { albumsOutline, timeOutline } from 'ionicons/icons';
  import { IonPage, IonIcon, IonContent, IonButton, IonImg, IonCard, IonCardHeader, IonCardContent, IonLabel, IonList, IonItem } from '@ionic/vue';
  import Header from '../components/Header';
  import { useStore } from "vuex";

  const store = useStore();

  onMounted(() => {
    store.dispatch('fetchQuestionnaireInstance');
  });

  const getQuestionnaireInstance = computed(() => {
    return store?.getters?.getQuestionnaireInstance || null;
  });

  const buttonText = computed(() => {
    if (getQuestionnaireInstance.value) {
      return getQuestionnaireInstance.value.status === 'New' ? 'Begin' : 'Continue';
    }
    return '';
  });
</script>
