import axios from "axios";

export const state = {
  expectations: []
};

export const mutations = {
  set_expectations: (state, expectations) => {
    state.expectations = expectations;
  }
};

export const actions = {
  async fetchExpectations({ commit }) {
    try {
      const response = await axios.get("/api/me/expectations");
      commit("set_expectations", response.data.expectations);
    } catch (error) {
      console.error("Error fetching expectations:", error);
      alert(error);
    }
  }
};

export const getters = {
  getExpectations(state) {
    return state.expectations;
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};