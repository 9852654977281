import axios from "axios";

export const state = {
  sensations: [],
};

export const mutations = {
  setSensations: (state, sensations) => (state.sensations = sensations),
  addSensation: (state, sensation) => state.sensations.push(sensation),
  removeSensation: (state, id) =>
    (state.sensations = state.sensations.filter((s) => s.id !== id)),
};

export const actions = {
  // Fetch all sensations
  async fetchSensations({ commit }) {
    try {
      const response = await axios.get("/api/sensations");
      commit("setSensations", response.data.sensations);
    } catch (error) {
      alert("Failed to fetch sensations.");
      console.error(error);
    }
  },

  // Add a new sensation
  async addSensation({ commit }, sensation) {
    try {
      const response = await axios.post("/api/sensations", sensation);
      commit("addSensation", response.data.sensation); // Assuming the response contains the newly created sensation
    } catch (error) {
      alert("Failed to add sensation.");
      console.error(error);
    }
  },

  // Update an existing sensation
  async updateSensation({ commit, state }, updatedSensation) {
    try {
      const response = await axios.put(
        `/api/sensations/${updatedSensation.id}`,
        updatedSensation
      );
    
      // Find the index of the sensation being updated
      const index = state.sensations.findIndex((sensation) => sensation.id === updatedSensation.id);
    
      if (index !== -1) {
        // Replace the updated sensation in the array
        state.sensations[index] = response.data.sensation;
      }

      // Alternatively, commit a mutation that updates the sensation directly
      // commit('updateSensation', response.data.sensation); // if you have an 'updateSensation' mutation

    } catch (error) {
      alert("Failed to update sensation.");
      console.error(error);
    }
  },

  // Delete a sensation
  async deleteSensation({ commit }, id) {
    try {
      await axios.delete(`/api/sensations/${id}`);
      commit("removeSensation", id);
    } catch (error) {
      alert("Failed to delete sensation.");
      console.error(error);
    }
  },
};

export const getters = {
  getSensations(state) {
    return state.sensations;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};