<template>
  <header class="bg-page-background h-20 fixed top-0 left-0 right-0 z-30 md:top-20 md:left-64 md:px-0  px-4 md:pt-4 flex items-center justify-between">
    <div class="flex items-center space-x-2 md:px-12">
      <!-- Back Button -->
      <button @click="$router.go(-1)" class="flex items-center">
        <ChevronLeftIcon class="-ml-1 h-7 w-auto text-gray-600 stroke-2" />
      </button>
      <!-- Page Title -->
      <h1 class="text-lg md:text-xl font-normal font-sans text-gray-700">{{ pageTitle }}</h1>
    </div>

    <div class="flex items-center space-x-2 md:hidden">
      <!-- Notification Bell -->
      <NotificationBell class="h-7 w-auto" />

      <!-- User Initials Circle -->
    
        <InitialsComponent />
     
    </div>
  </header>
</template>

<script>
import { ChevronLeftIcon } from '@heroicons/vue/24/outline';
import NotificationBell from './NotificationBell';
import InitialsComponent from './InitialsComponent';
import { mapGetters } from 'vuex';

export default {
  name: 'HeaderComponent',
  components: {
    ChevronLeftIcon,
    NotificationBell,
    InitialsComponent
  },
  computed: {
    // Accessing the user from Vuex store
    ...mapGetters({
      user: 'user' // Assuming the getter name is 'user'
    }),
    // Compute user initials from the user object
    userInitials() {
      if (this.user && this.user.name) {
        // Split the user's name and extract the first letter of each word
        return this.user.name.split(' ').map(namePart => namePart[0]).join('');
      }
      return '';
    }
  },
  props: {
    pageTitle: {
      type: String,
      default: 'Page Title'
    }
  }
}
</script>
