import axios from "axios";

export const state = {
  goals: [],
};

export const mutations = {
  setGoals: (state, goals) => (state.goals = goals),
  addGoal: (state, goal) => state.goals.push(goal),
  removeGoal: (state, id) =>
    (state.goals = state.goals.filter((g) => g.id !== id)),
};

export const actions = {
  // Fetch all goals
  async fetchGoals({ commit }) {
    try {
      const response = await axios.get("/api/goals");
      commit("setGoals", response.data.goals);
    } catch (error) {
      alert("Failed to fetch goals.");
      console.error(error);
    }
  },

  // Add a new goal
  async addGoal({ commit }, goal) {
    try {
      const response = await axios.post("/api/goals", goal);
      commit("addGoal", response.data.goal); // Assuming the response contains the newly created goal
    } catch (error) {
      alert("Failed to add goal.");
      console.error(error);
    }
  },

  // Update an existing goal
  async updateGoal({ commit, state }, updatedGoal) {
    try {
      const response = await axios.put(
        `/api/goals/${updatedGoal.id}`,
        updatedGoal
      );

      // Find the index of the goal being updated
      const index = state.goals.findIndex((goal) => goal.id === updatedGoal.id);

      if (index !== -1) {
        // Replace the updated goal in the array
        state.goals[index] = response.data.goal;
      }

      // Alternatively, commit a mutation that updates the goal directly
      // commit('updateGoal', response.data.goal); // if you have an 'updateGoal' mutation
    } catch (error) {
      alert("Failed to update goal.");
      console.error(error);
    }
  },

  // Delete a goal
  async deleteGoal({ commit }, id) {
    try {
      await axios.delete(`/api/goals/${id}`);
      commit("removeGoal", id);
    } catch (error) {
      alert("Failed to delete goal.");
      console.error(error);
    }
  },
};

export const getters = {
  getGoals(state) {
    return state.goals;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};