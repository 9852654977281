<template>
  <ion-page>

    <ion-content class="ion-padding">
      <IonAccordionGroup>
        <IonAccordion value="section1">
          <IonItem slot="header">
            <IonLabel>Activities</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewActivities />
          </div>
        </IonAccordion>

        <IonAccordion value="section2">
          <IonItem slot="header">
            <IonLabel>Spotlights</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientSpotlights />
          </div>
        </IonAccordion>

        <IonAccordion value="section3">
          <IonItem slot="header">
            <IonLabel>Deep Dive</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewDeepDive />
          </div>
        </IonAccordion>

        <IonAccordion value="section4">
          <IonItem slot="header">
            <IonLabel>Symptoms</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewSymptoms />
          </div>
        </IonAccordion>

        <IonAccordion value="section5">
          <IonItem slot="header">
            <IonLabel>Unhelpful Schemas</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewSchemas />
          </div>
        </IonAccordion>

        <IonAccordion value="section6">
          <IonItem slot="header">
            <IonLabel>Current Problems</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewProblems />
          </div>
        </IonAccordion>

        <IonAccordion value="section7">
          <IonItem slot="header">
            <IonLabel>Schema Modes</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewModes />
          </div>
        </IonAccordion>


        <IonAccordion value="section8">
          <IonItem slot="header">
            <IonLabel>Overall Aims</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewOverallAims />
          </div>
        </IonAccordion>

        <IonAccordion value="section9">
          <IonItem slot="header">
            <IonLabel>Goals and Actions</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewGoalsActions />
          </div>
        </IonAccordion>

        <IonAccordion value="section10">
          <IonItem slot="header">
            <IonLabel>Client Notes</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewNotes />
          </div>
        </IonAccordion>

        <IonAccordion value="section11">
          <IonItem slot="header">
            <IonLabel>Client Tracking</IonLabel>
          </IonItem>
          <div slot="content">
            <ClientViewTracking />
          </div>
        </IonAccordion>
      </IonAccordionGroup>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import Header from '../components/Header';
import { IonPage, IonContent, IonAccordionGroup, IonAccordion, IonItem, IonLabel } from '@ionic/vue';
import { sendPost } from '../components/entities/functions.js';
import ClientViewActivities from '../components/ClientViewActivities';
import ClientViewTracking from '../components/ClientViewTracking';
import ClientViewDeepDive from '../components/ClientViewDeepDive';
import ClientViewSchemas from '../components/ClientViewSchemas';
import ClientViewModes from '../components/ClientViewModes';
import ClientViewSymptoms from '../components/ClientViewSymptoms';
import ClientViewProblems from '../components/ClientViewProblems';
import ClientViewGoalsActions from '../components/ClientViewGoalsActions';
import ClientViewOverallAims from '../components/ClientViewOverallAims';
import ClientSpotlights from '../components/ClientViewSpotlights';
import ClientViewNotes from '../components/ClientViewNotes.vue';

// Reactive state
const client = ref({});
const loading = ref(false);

// Route parameters
const route = useRoute();
const client_id = computed(() => route.params.clientId);

// Fetch client info
const getClientInfo = async () => {
  loading.value = true;
  const response = await sendPost('/api/get/client-info', {
    client_id: client_id.value,
  });
  if (response) {
    client.value = response.client;
  }
  loading.value = false;
};

// Lifecycle hook
onMounted(() => {
  getClientInfo();
});
</script>