import axios from "axios";

export const state = {
  behaviours: [],
};

export const mutations = {
  setBehaviours: (state, behaviours) => (state.behaviours = behaviours),
  addBehaviour: (state, behaviour) => state.behaviours.push(behaviour),
  removeBehaviour: (state, id) =>
    (state.behaviours = state.behaviours.filter((b) => b.id !== id)),
};

export const actions = {
  // Fetch all behaviours
  async fetchBehaviours({ commit }) {
    try {
      const response = await axios.get("/api/behaviours");
      commit("setBehaviours", response.data.behaviours);
    } catch (error) {
      alert("Failed to fetch behaviours.");
      console.error(error);
    }
  },

  // Add a new behaviour
  async addBehaviour({ commit }, behaviour) {
    try {
      const response = await axios.post("/api/behaviours", behaviour);
      commit("addBehaviour", response.data.behaviour); // Assuming the response contains the newly created behaviour
    } catch (error) {
      alert("Failed to add behaviour.");
      console.error(error);
    }
  },

  // Update an existing behaviour
  async updateBehaviour({ commit, state }, updatedBehaviour) {
    try {
      const response = await axios.put(
        `/api/behaviours/${updatedBehaviour.id}`,
        updatedBehaviour
      );
    
      // Find the index of the behaviour being updated
      const index = state.behaviours.findIndex((behaviour) => behaviour.id === updatedBehaviour.id);
    
      if (index !== -1) {
        // Replace the updated behaviour in the array
        state.behaviours[index] = response.data.behaviour;
      }

      // Alternatively, commit a mutation that updates the behaviour directly
      // commit('updateBehaviour', response.data.behaviour); // if you have an 'updateBehaviour' mutation

    } catch (error) {
      alert("Failed to update behaviour.");
      console.error(error);
    }
  },

  // Delete a behaviour
  async deleteBehaviour({ commit }, id) {
    try {
      await axios.delete(`/api/behaviours/${id}`);
      commit("removeBehaviour", id);
    } catch (error) {
      alert("Failed to delete behaviour.");
      console.error(error);
    }
  },
};

export const getters = {
  getBehaviours(state) {
    return state.behaviours;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};