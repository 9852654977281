import { createStore } from "vuex";

import activity from "../../js/store/modules/activity.module";
import aims from "../../js/store/modules/aims.module";
import auth from "../../js/store/modules/auth.module";
import barriers from "../../js/store/modules/barriers.module";
import behaviours from "../../js/store/modules/behaviours.module";
import characters from "../../js/store/modules/characters.module";
import chatStore from './modules/chatStore';
import copings from "../../js/store/modules/copings.module"
import emotionalstates from "../../js/store/modules/emotionalstates.module";
import expectations from "../../js/store/modules/expectations.module";
import feelings from "../../js/store/modules/feelings.module";
import goals from "../../js/store/modules/goals.module";
import goalActions from "../../js/store/modules/goalActions.module";
import lifeslices from "../../js/store/modules/lifeslices.module";
import lifetraps from "../../js/store/modules/lifetraps.module";
import parentstyles from "../../js/store/modules/parentstyles.module";
import problems from "../../js/store/modules/problems.module";
import sensations from "../../js/store/modules/sensations.module";
import spotlight from "../../js/store/modules/spotlight.module";
import supports from "../../js/store/modules/supports.module";
import symptoms from "../../js/store/modules/symptoms.module";
import thoughts from "../../js/store/modules/thoughts.module";
import values from "../../js/store/modules/values.module";


const store = createStore({
  modules: {
    activity,
    aims,
    auth,
    barriers,
    behaviours,
    characters,
    chatStore,
    copings,
    emotionalstates,
    expectations,
    feelings,
    goals,
    goalActions,
    lifeslices,
    lifetraps,
    parentstyles,
    problems,
    sensations,
    spotlight,
    supports,
    symptoms,
    thoughts,
    values,
    
  }
});

export default store;