<template>
  <ion-page>

    <ion-content class="ion-padding">
      <!-- Back Button -->
      <div v-if="currentStep > 0" class="flex justify-start mb-2">
        <ion-button
          @click="goBack"
          :disabled="currentStep === 0"
          class="p-0.5 flex rounded-full"
          fill="clear"
        >
          <ion-icon :icon="chevronBack" slot="icon-only"></ion-icon>
        </ion-button>
      </div>

      <!-- Current Step -->
      <ion-card class="max-w-md mx-auto">
        <!-- Step 0: Introduction -->
        <div v-if="currentStep === 0">
          <ion-card-header>
            <ion-card-title class="text-primary text-center text-2xl">
              Welcome to the Schema Tracker
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p class="text-gray-600">
              Use this tool to track schemas and identify modes. Let’s begin!
            </p>
            <ion-button expand="block" class="mt-4" @click="goForward">
              Start
            </ion-button>
          </ion-card-content>
        </div>

        <!-- Step 1: Describe the Current Situation -->
        <template v-if="currentStep === 1">
          <ion-card-header>
            <ion-card-title class="text-center">
              Describe the Current Situation
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-textarea
              v-model="formData.currentSituation"
              rows="6"
              placeholder="Start typing..."
            ></ion-textarea>
            <ion-button expand="block" class="mt-4" @click="goForward">
              Next
            </ion-button>
          </ion-card-content>
        </template>

        <!-- Step 2: Select Activated Schema -->
        <template v-if="currentStep === 2">
          <ion-card-header>
            <ion-card-title class="text-center">
              What Schema Has Been Activated?
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-select
              v-model="formData.activatedSchema"
              placeholder="Select a schema"
            >
              <ion-select-option
                v-for="schema in schemaList"
                :key="schema"
                :value="schema"
              >
                {{ schema }}
              </ion-select-option>
            </ion-select>
            <ion-button expand="block" class="mt-4" @click="goForward">
              Next
            </ion-button>
          </ion-card-content>
        </template>

        <!-- Step 3: Select Activated Mode -->
        <template v-if="currentStep === 3">
          <ion-card-header>
            <ion-card-title class="text-center">
              What Mode Did You Enter?
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-select
              v-model="formData.activatedMode"
              placeholder="Select a mode"
            >
              <ion-select-option
                v-for="mode in modeList"
                :key="mode"
                :value="mode"
              >
                {{ mode }}
              </ion-select-option>
            </ion-select>
            <ion-button expand="block" class="mt-4" @click="goForward">
              Next
            </ion-button>
          </ion-card-content>
        </template>

        <!-- Step 4: Healthy Adult Response -->
        <template v-if="currentStep === 4">
          <ion-card-header>
            <ion-card-title class="text-center">
              What Would the Healthy Adult Do?
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <ion-textarea
              v-model="formData.healthyAdultResponse"
              rows="6"
              placeholder="Start typing..."
            ></ion-textarea>
            <ion-button expand="block" class="mt-4" @click="goForward">
              Next
            </ion-button>
          </ion-card-content>
        </template>

        <!-- Step 5: Summary -->
        <template v-if="currentStep === 5">
          <ion-card-header>
            <ion-card-title class="text-center">
              Summary
            </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <p><strong>Current Situation:</strong> {{ formData.currentSituation }}</p>
            <p><strong>Activated Schema:</strong> {{ formData.activatedSchema }}</p>
            <p><strong>Activated Mode:</strong> {{ formData.activatedMode }}</p>
            <p><strong>Healthy Adult Response:</strong> {{ formData.healthyAdultResponse }}</p>
            <ion-button expand="block" class="mt-4" @click="resetProcess">
              Finish
            </ion-button>
          </ion-card-content>
        </template>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref } from 'vue';
import { chevronBack } from 'ionicons/icons';
import { schemaList, modeList } from '../data/schemaData'; // Import schema and mode lists
import { 
  IonPage, 
  IonContent, 
  IonSelect, 
  IonSelectOption, 
  IonList,
  IonTextarea,
  IonRadio,
  IonRadioGroup,
  IonCol,
  IonIcon,
  IonHeader, 
  IonFooter, 
  IonToolbar, 
  IonCard,
  IonCardTitle,
  IonCardHeader,  
  IonCardContent, 
  IonText, 
  IonButton, 
  IonItem, 
  IonCheckbox, 
  IonLabel, 
  IonInput, 
  IonImg 
  } from '@ionic/vue';

const currentStep = ref(0);
const formData = ref({
  currentSituation: '',
  activatedSchema: '',
  activatedMode: '',
  healthyAdultResponse: '',
});

const goBack = () => {
  currentStep.value = Math.max(0, currentStep.value - 1);
};

const goForward = () => {
  currentStep.value++;
};

const resetProcess = () => {
  currentStep.value = 0;
  formData.value = {
    currentSituation: '',
    activatedSchema: '',
    activatedMode: '',
    healthyAdultResponse: '',
  };
};
</script>
