<template>
  <case-formulation-states :emotionalstates="client?.emotionalstate || []" :on-client-page="true" />
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { sendPost } from './entities/functions.js';
import CaseFormulationStates from '../components/CaseFormulationStates';

// Reactive variables
const client = ref({});
const loading = ref(false);

// Get client ID from route
const route = useRoute();
const clientId = computed(() => route.params.clientId);

// Fetch client info
const fetchClientInfo = async () => {
  loading.value = true;
  try {
    const response = await sendPost('/api/get/client-info', {
      client_id: clientId.value,
    });
    if (response) {
      client.value = response.client || {};
    }
  } catch (error) {
    console.error('Error fetching client info:', error);
  } finally {
    loading.value = false;
  }
};

// Fetch client info on component mount
onMounted(fetchClientInfo);
</script>