<template>

  <ion-page>
    <ion-content>

<!-- Card - No Upcoming Sessions -->   
    <div v-if="!sessions?.length || isTherapist" class="card-design-1 bg-gradient-to-r from-primary-100 to-secondary-100" >     
      <h1 class="pt-8 text-gray-800 font-normal text-base text-center">You have no upcoming {{ session_type }}s!</h1>
    
      <div class="px-4 pt-4"> 
      <!-- Date Picker -->    
        <VueDatePicker class="dp__theme_light"
          v-model="dateTime"
          light
          auto-apply
          keep-action-row
          partial-flow :flow="['calendar', 'time']"
          no-hours-overlay
          no-minutes-overlay
          :month-change-on-scroll="false"
          placeholder="Pick a date & time"
          month-name-format="long"
          minutes-increment="15"
          :start-time="startTime"
          :is-24="false"
          hide-offset-dates
          :min-date="minDate"
          :disabled-week-days="[6, 0]"
          :disabled-times="isTimeDisabled"      
          menu-class-name="dp-custom-menu"
          teleport-center
          
          :disabled-dates="disabledDates"
        />
      <!-- Select - Therapist Chooses Client -->  
      <div v-if="isTherapist">
        <div class="flex justify-between">
          <UserStatusFilter class="mt-2" @update:selectedStatuses="handleSelectedStatuses"></UserStatusFilter>
          <button
            :disabled="dateTime === null"
            :class="[dateTime === null ? 'bg-gray-300' : 'bg-rose-400']"
            @click="dayOff"
            class="mt-2 px-4 py-2 text-gray-700 text-sm rounded-md w-full"
          >
            Day Off
          </button>
        </div>
        <select v-model="selectedUserId" class="mt-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg block w-full p-2.5">
          <option v-for="user in users" :value="user.id">
            {{ user.name }}
          </option>
        </select>
      </div>
        <!--  Alert - Session Too Soon -->  
        <p v-if="wrongTime" class="pt-1 px-8 text-sm text-gray-700 text-center leading-snug">
              Oops! Please choose a time that's at least one hour from now.
              </p>
        <p v-if="bookSessionInfoMessage !== null" class="pt-1 text-center font-normal text-sm text-white">
          {{ bookSessionInfoMessage }}
        </p>
        <!-- CTA - Book Session -->  
      <button :disabled="dateTime === null || wrongTime" @click="book" class="px-4 py-2 rounded-md font-semibold w-full"
        :class="[dateTime !== null && !wrongTime ? 'mt-2 mb-4 bg-secondary-600 hover:bg-secondary-700 text-white' : 'bg-none text-transparent']">
        Book <span class="capitalize">{{ session_type }}</span>
      </button>
      </div>
    </div>
 



<!-- Header - Next Session -->
<ion-toolbar>
        <ion-buttons slot="start" >
          <div v-if="sessions?.length">
      <h4 class="ion-font-weight-bold">Next <span class="capitalize">{{ getSessionType(sessions[0]) }}</span>{{ sessions.length > 1 ? 's' : '' }}</h4>
    </div>
        </ion-buttons>
      </ion-toolbar>

  <div v-if="!loading">


<!-- Card - Next Session -->
    <div v-for="session in sessions">
     <div class="card-design-1 bg-gradient-to-r from-primary-100 to-secondary-100">

<div class="flex items-center justify-between pl-4">
  <!-- Left column with text lines -->
  <div class="pt-4" >
    <h1 class="text-xl font-semibold text-secondary-600">{{ getWeekDay(session.scheduled_time) }}, {{ getDate(session.scheduled_time) }}</h1>
    <h1 class="text-lg font-normal text-gray-600">{{ getStartTime(session.scheduled_time) }} - {{ getFinishTime(session.scheduled_time, session.duration) }}</h1>
  </div>

  <!-- Right column with the image -->
  <div class="pt-6">
    <img class="h-16" src="/img/webapp/dashboard/icon_session2.png" alt="imageAltText" />
  </div>
</div> 
      <!-- adding the therapists avatar and name --> 
      <div v-if="session.user_name" class="pl-4 pb-1 text-sm text-secondary-600 font-medium">
           <div class="flex items-center">
                <div class="inline-block h-7 w-auto overflow-hidden ring-1 ring-white shadow-md rounded-full">
                <img class="h-7" src="/img/avatar.jpg" alt="Therapist face image" />
                </div>
                <h1 class="text-body-sm ml-2">{{ session.user_name }}</h1>                 
              </div>  
            </div>

       
          <div class="mx-4 border-t border-secondary/70 mt-2"></div>
        <div class="flex justify-between items-center px-4">
        
                  
<!-- CTA - Enter Waiting Room -->
          <div v-if="session.isStartingSoon" class="mx-auto text-gray-700 font-normal text-base">
        
            <p v-if="!session.sessionStarted && !session.sessionEnded" class="pt-2">
              Get ready, this {{ getSessionType(session) }} will begin in <span class="font-semibold text-gray-700"> {{ session.timeTillSessionStart }}</span>
            </p>
            
            <div class="mx-auto">
            <button class="my-4 button-waiting-room cursor-pointer flex items-center" @click="joinSession(session)">
              <p class="pr-4">Enter Waiting Room</p>
              <component :is="VideoCameraIcon" class="h-7 w-auto stroke-1 text-white" aria-hidden="true" />
            </button>
          </div>
          </div>
<!-- ALERT - Session Upcoming -->
          <p v-else-if="session.isInFuture" class=" text-xs text-gray-700 font-medium uppercase">
            {{ getSessionType(session) }} Upcoming
          </p>
<!-- ALERT - Missed Session  -->
          <p v-else-if="session.sessionEndTimeExtraTime" class="py-4 text-sm text-text-gray-700 font-medium italic">
            'It looks like you missed your {{ getSessionType(session) }}'
          </p>
          <p v-else class="py-4 text-sm text-gray-700 font-medium italic">
            It appears that your {{ getSessionType(session) }} has ended
          </p>

          
<!-- CTA - Edit Session -->  
  
<button v-if="(session.isInFuture && !session.isStartingSoon) || session.sessionEndTimeExtraTime" class="pt-1 group cursor-pointer" @click="editSessionToggle(session)">
      <component :is="WrenchIcon" class="h-7 w-auto stroke-1 text-gray-700 mx-auto group-hover:text-primary-600" aria-hidden="true" />
      <p class="text-center text-xs font-normal text-gray-700 group-hover:text-primary-600 pb-2 ">Edit</p>
    </button>
    
  </div>

     
<!-- New Date Picker  -->        
    <div v-if="session.id === editSessionId" class="px-4">  
        <p class="pt-2 pb-2 text-base font-semibold text-gray-700 text-center">
          Change <span class="capitalize">{{ getSessionType(session) }}</span>
        </p>

        <div> 
          <VueDatePicker class="dp__theme_light"
            v-model="editSessionDateTime"
            light
            auto-apply
            keep-action-row
            partial-flow :flow="['calendar', 'time']"
            no-hours-overlay
            no-minutes-overlay
            :month-change-on-scroll="false"
            placeholder="Choose a new date & time"
            month-name-format="long"
            minutes-increment="15"
            :start-time="startTime"
            :is-24="false"
            hide-offset-dates
            :min-date="minDate"
            :disabled-week-days="[6, 0]"
            :disabled-times="isTimeDisabled"      
            menu-class-name="dp-custom-menu" 
            teleport-center
            :disabled-dates="disabledDates"
            />
<!--  Alert - Session Too Soon -->            
            <p v-if="editSessionWrongTime" class="px-16 text-sm text-gray-700 text-center leading-snug">
            Oops! Please choose a time that's at least one hour from now.
            </p>
            <p v-if="editSessionInfoMessage !== null" class="pt-1 text-gray-700 text-sm text-center">
              {{ editSessionInfoMessage }}
            </p>
<!--  CTA - Change Session -->          
            <button :disabled="!editButtonActive(session)" @click="editSession" 
            :class="[editButtonActive(session) ? 'bg-secondary-600 text-white' : 'bg-none text-transparent', 'my-2 w-full px-4 py-2 rounded-md text-gray-700 font-semibold']">
              Change <span class="capitalize">{{ getSessionType(session) }}</span>
            </button>      
<!-- CTA -Cancel Session -->     
          <button @click="confirmAndCancelSession" class="group">
            <TrashIcon class="mx-auto h-7 w-auto stroke-1 text-gray-700 group-hover:text-primary-600" />
            <p class="text-center text-xs font-normal text-gray-700 group-hover:text-primary-600">Cancel</p>
          </button> 
<!-- CTA - Mark as Finished -->        
            <button v-if="session.status == 'active' && isTherapist" @click="finishSession(session)" class="px-4 py-2 rounded-md text-gray-700 font-semibold bg-blue-300">
              Mark as finished
            </button>
   
        
      </div>
    </div>
  </div>
</div>
</div>



 <!-- Session History Header -->
        <ion-toolbar>
          <ion-buttons slot="start">
          <div v-if="archivedSessions?.length" class="">
          <h4 class="ion-font-weight-bold">Session History</h4>
          </div>
          </ion-buttons>
        </ion-toolbar>

<!-- Session History Cards -->
  
        <!-- Loop through sessions -->
        <ion-col size="12" size-md="4" v-for="session in archivedSessions" :key="session.id">
          <ion-card class="ion-no-margin" style="background-color: white;">
            <ion-card-header>
              <h3>{{ getWeekDay(session.scheduled_time) }}, {{ getDate(session.scheduled_time) }}</h3>
            </ion-card-header>
            <ion-card-content>
              <p>{{ getStartTime(session.scheduled_time) }} - 
                 {{ session.ended_at ? getStartTime(session.ended_at) : getFinishTime(session.scheduled_time, session.duration) }}
              </p>
              <p v-if="session.user_name">
                With {{ session.user_name }}
                <span class="badge">{{ getSessionType(session) }}</span>
              </p>
            </ion-card-content>
          </ion-card>
        </ion-col>
  


    </ion-content>
  </ion-page>
</template>

<script setup>
import { ref } from 'vue'
import { VideoCameraIcon, WrenchIcon, TrashIcon } from '@heroicons/vue/24/outline'
import LoadingOverlay from '../components/LoadingOverlay';
import { IonContent, IonGrid, IonRow, IonCol, IonCard, IonCardHeader, IonCardContent, IonToolbar, IonButtons, IonButton } from '@ionic/vue';

const startTime = ref({ hours: 10, minutes: 0 });
const customPosition = () => ({ top: -65, left: -18 });

</script>

<script>
  import { sendPost } from '../components/entities/functions.js';
  import { IonPage, IonContent } from '@ionic/vue';
  import Header from '../components/Header';
  import { ChevronLeftIcon, BellIcon, UserCircleIcon } from '@heroicons/vue/24/outline';

  import VueDatePicker from '@vuepic/vue-datepicker';
  import '@vuepic/vue-datepicker/dist/main.css';
  import UserStatusFilter from '../components/UserStatusFilter.vue';

  export default {
    data () {
      return {
        dateTime: null,
        minDate: null,
        wrongTime: false,
        sessions: [],
        archivedSessions: [],
        editSessionId: null,
        editSessionDateTime: null,
        editSessionWrongTime: false,
        editSessionInfoMessage: null,
        bookSessionInfoMessage: null,
        users: [],
        selectedUserId: null,
        isTherapist: false,
        timerInterval: null,
        loading: true,
        statuses: false,
        disabledDates: [],
        timerActive: false,
        timerCounter: 0
      }
    },

    watch: {
      dateTime(value) {
        this.wrongTime = value !== null && value < this.getMinDateTime();
        this.bookSessionInfoMessage = null;
      },
      editSessionDateTime(value) {
        this.editSessionInfoMessage = null;
        let sessionIndex = this.sessions?.findIndex(s => s.id === this.editSessionId);
        let sessionDateTime = null;
        let sameTime = false;
        if (sessionIndex !== -1) {
          sessionDateTime = new Date(this.sessions[sessionIndex].scheduled_time);
          sameTime = sessionDateTime.getTime() === value.getTime();
        }
        this.editSessionWrongTime = value !== null && value.getTime() < this.getMinDateTime().getTime() && !sameTime;
      },
    },

    computed: {
      user() {
        return this.$store.getters['user'];
      },
      session_type() {
        return this.user.is_free_consultation ? 'consultation' : 'session';
      }
    },

    mounted() {
      this.minDate = new Date();
      this.getSessions();
    },

    beforeUnmount() {
      if (this.timerInterval) {
        clearInterval(this.timerInterval);
      }
    },

    methods: {
      setTimer() {
        this.timerTick();
        this.timerInterval = setInterval(() => {
          this.timerTick();
        }, 1000);
      },

      timerTick() {
        this.timerActive = true;
        this.timerCounter++;
        let now = Date.now();

        if (this.timerCounter >= 10) {
          this.timerCounter = 0;
          this.getSessions();
        }

        this.sessions.forEach((session) => {
          let sessionStartDateTime = new Date(session.scheduled_time);
          let sessionStartTime = sessionStartDateTime.getTime();
          let sessionEndTime = sessionStartTime + session.duration * 1000;
          let sessionEndTimeExtraTime = sessionEndTime + 60 * 1000;

          session.sessionStarted = now >= sessionStartTime;
          session.sessionEnded = now >= sessionEndTime;
          session.sessionEndTimeExtraTime = now >= sessionEndTimeExtraTime;

          session.isStartingSoon = this.isSessionStartingSoon(session.scheduled_time, session.duration);
          session.isInFuture = this.isSessionInFuture(session.scheduled_time);

          if (!session.sessionStarted && ! session.sessionEnded) {
            let remainingTimeStart = Math.ceil((sessionStartTime - now) / 1000);
            session.timeTillSessionStart = this.formatTime(remainingTimeStart);
          }
        });
      },

      formatTime(seconds) {
        let minutes = Math.floor(seconds / 60);
        seconds %= 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      },

      async getSessions() {
        let response = await sendPost('/api/video/get-sessions', {
          'filter': this.statuses
        });
        if (response) {
          this.isTherapist = response.is_therapist;

          this.$store.dispatch('set_user', response.user);

          if (this.sessions.length == 0) {
            this.sessions = response.sessions;
          } else {
            response.sessions.forEach((newSession) => {
              let existingSession = this.sessions.find(session => session.id === newSession.id);

              if (existingSession) {
                Object.keys(newSession).forEach(key => {
                  if (existingSession[key] !== newSession[key]) {
                    existingSession[key] = newSession[key];
                  }
                });
              } else {
                this.sessions.push(newSession);
              }
            });

            this.sessions = this.sessions.filter(session =>
              response.sessions.some(newSession => newSession.id === session.id)
            );
          }

          this.archivedSessions = response.archived_sessions;
          this.disabledDates = response.disabled_dates;
          if (response.users) {
            this.users = response.users;
          }
          if (!this.timerActive) {
            this.setTimer();
          }
        }
        this.loading = false;
      },

      async book() {
        let params = {
          'dateTime': this.dateTime.toUTCString()
        };
        if (this.selectedUserId) {
          params.userId = this.selectedUserId;
        }
        let response = await sendPost('/api/video/edit-session', params);
        if (response) {
          if (response.status === 'ok') {
            if (this.sessions === null) {
              this.sessions = [];
            }
            let bookedSession = response.session;
            bookedSession.isInFuture = true;
            this.sessions.push(bookedSession);
          }
          this.bookSessionInfoMessage = response.message || null;
        }
      },

      async dayOff() {
        let params = {
          'dateTime': this.dateTime.toUTCString()
        };
        let response = await sendPost('/api/video/day-off', params);
        if (response) {
          if (response.status === 'ok') {
            if (this.sessions === null) {
              this.sessions = [];
            }
            let bookedSession = response.session;
            bookedSession.isInFuture = true;
            this.sessions.push(bookedSession);
            this.disabledDates.push(new Date(bookedSession.scheduled_time));
          }
          this.bookSessionInfoMessage = response.message || null;
        }
      },

      isTimeDisabled(time) {
        if (
          time.hours < 8 ||
          (time.hours === 8 && time.minutes < 0) ||
          time.hours > 22 ||
          (time.hours === 22 && time.minutes > 0)
        ) {
          return true;
        }
        return false;
      },

      getMinDateTime() {
        let now = new Date();
        now.setHours(now.getHours() + 1);
        return now;
      },

      getWeekDay(scheduled_time) {
        let date = new Date(scheduled_time);
        let weekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        return weekDays[date.getUTCDay()];
      },
      getDate(scheduled_time) {
        let date = new Date(scheduled_time);
        return date.toLocaleDateString([], { day: 'numeric', month: 'long' }); // will return date like "7 March"
      },
      getStartTime(time) {
        let utcTime = new Date(time);
        return utcTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      },

      getFinishTime(time, duration) {
        let finishTime = new Date(time);
        finishTime.setSeconds(finishTime.getSeconds() + duration);
        return finishTime.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
      },

      editSessionToggle(session) {
        if (!session || session.id === this.editSessionId) {
          this.editSessionId = null;
          this.editSessionDateTime = null;
          return;
        }

        this.editSessionInfoMessage = null;
        this.editSessionId = session.id;
        this.editSessionDateTime = this.isSessionInFuture(session.scheduled_time) ? new Date(session.scheduled_time) : null;
      },

      editSession() {
        sendPost('api/video/edit-session', {'id': this.editSessionId, 'dateTime': this.editSessionDateTime.toUTCString()}).then((response) => {
          if (response) {
            if (response.message) {
              this.editSessionInfoMessage = response.message;
            }
            if (response.status === 'ok') {
              let sessionIndex = this.sessions.findIndex(s => s.id === response.session.id);
              if (sessionIndex !== -1) {
                // Replace the existing session if found
                this.sessions[sessionIndex] = response.session;
              } else {
                // Add the new session if not found
                this.sessions.push(response.session);
              }
            }
          }
        });
      },

      async finishSession(session) {
        await sendPost('/api/video/set-session-status', {
          'id': session.id,
          'status': 'completed'
        }).then((response) => {
          this.editSessionToggle(session);
          let sessionIndex = this.sessions.findIndex(s => s.id === session.id);
          if (sessionIndex !== -1) {
              this.sessions.splice(sessionIndex, 1);
          }
          session.ended_at = (new Date()).toString();
          this.archivedSessions.push(session);
        });
      },

      editButtonActive(session) {
        let sessionDateTime = new Date(session.scheduled_time);
        return this.editSessionDateTime !== null && !this.editSessionWrongTime && sessionDateTime.getTime() !== this.editSessionDateTime.getTime();
      },

      joinSession(session) {
        this.$router.push({ name: 'VideoChat' });
      },

      isSessionStartingSoon(scheduled_time, duration) {
        let sessionStart = new Date(scheduled_time);
        let sessionEnd = new Date(scheduled_time);
        sessionEnd.setMinutes(sessionEnd.getMinutes() + duration / 60);

        let now = new Date();
        return now.getTime() >= sessionStart.getTime() - 15 * 60 * 1000
              && now.getTime() <= sessionEnd.getTime();
      },

      isSessionInFuture(scheduled_time) {
        let sessionStart = new Date(scheduled_time);
        let now = new Date();
        return sessionStart.getTime() > now.getTime();
      },

      confirmAndCancelSession() {
        if (window.confirm("Are you sure you want to cancel the " + this.session_type + "?")) {
          this.cancelSession();
        }
      },

      getSessionType(session) {
        let type = 'session';
        if (session.is_consultation) {
            type = 'consultation';
        }
        return type;
      },

      cancelSession() {
        sendPost('/api/video/cancel', {id: this.editSessionId}).then((response) => {
          if (response && response.status === 'ok') {
            let sessionIndex = this.sessions.findIndex(s => s.id === this.editSessionId);
            if (sessionIndex !== -1) {
                this.sessions.splice(sessionIndex, 1);
            }
            this.editSessionToggle(this.sessions[sessionIndex]);
          }
        });
      },

      async handleSelectedStatuses(statuses) {
        this.statuses = statuses;
        await this.getSessions();
      }
    }
  }

</script>


<style scoped>
header {
  padding-top: env(safe-area-inset-top);
}

</style>

<style>
/* Import the CSS or define the class here */
.dp__theme_light {
   --dp-background-color: #ffffff;
   --dp-text-color: #323232;
   --dp-hover-color: #dae8e5;
   --dp-hover-text-color: #212121;
   --dp-hover-icon-color: #80bfaf;
   --dp-primary-color: #4CA08B;
   --dp-primary-disabled-color: #bebebe;
   --dp-primary-text-color: #ffffff;
   --dp-secondary-color: #d9d9d9;
   --dp-border-color: #ffffff;
   --dp-border-color-hover: #aaaeb7;
   --dp-menu-border-color: #ddd;
   --dp-disabled-color: #f6f6f6;
   --dp-scroll-bar-background: #f3f3f3;
   --dp-scroll-bar-color: #959595;
   --dp-success-color: #76d275;
   --dp-success-color-disabled: #a3d9b1;
   --dp-icon-color: #4CA08B;
   --dp-danger-color: #ff6f60;
   --dp-highlight-color: rgba(25, 118, 210, 0.1);
  }
  :root {
   /*General*/
   --dp-font-family: "Open Sans", "Helvetica Neue", -apple-system, blinkmacsystemfont, Segoe UI, roboto, oxygen, ubuntu, cantarell, sans-serif;
    --dp-border-radius: 4px; /*Configurable border-radius*/
    --dp-cell-border-radius: 4px; /*Specific border radius for the calendar cell*/
    --dp-common-transition: all 0.2s ease-in; /*Generic transition applied on buttons and calendar cells*/

    /*Sizing*/
    --dp-button-height: 35px; /*Size for buttons in overlays*/
    --dp-month-year-row-height: 40px; /*Height of the month-year select row*/
    --dp-month-year-row-button-size: 40px; /*Specific height for the next/previous buttons*/
    --dp-button-icon-height: 24px; /*Icon sizing in buttons*/
    --dp-cell-size: 35px; /*Width and height of calendar cell*/
    --dp-cell-padding: 4px; /*Padding in the cell*/
    --dp-common-padding: 8px; /*Common padding used*/
    --dp-input-icon-padding: 35px; /*Padding on the left side of the input if icon is present*/
    --dp-input-padding: 10px 30px 10px 12px; /*Padding in the input*/
    --dp-menu-min-width: 360px; /*Adjust the min width of the menu*/
    --dp-action-buttons-padding: 0px 10px; /*Adjust padding for the action buttons in action row*/
    --dp-row-maring:  12px 4px; /*Adjust the spacing between rows in the calendar*/
    --dp-calendar-header-cell-padding:  2.5rem; /*Adjust padding in calendar header cells*/
    --dp-two-calendars-spacing:  10px; /*Space between multiple calendars*/
    --dp-overlay-col-padding:  3px; /*Padding in the overlay column*/
    --dp-time-inc-dec-button-size:  40px; /*Sizing for arrow buttons in the time picker*/
    --dp-menu-padding: 12px 12px; /*Menu padding*/
    
    /*Font sizes*/
    --dp-font-size: 1rem; /*Default font-size*/
    --dp-preview-font-size: 0.8rem; /*Font size of the date preview in the action row*/
    --dp-time-font-size: 2.0rem; /*Font size in the time picker*/
    
    /*Transitions*/
    --dp-animation-duration: 0.1s; /*Transition duration*/
    --dp-menu-appear-transition-timing: cubic-bezier(.4, 0, 1, 1); /*Timing on menu appear animation*/
    --dp-transition-timing: ease-out; /*Timing on slide animations*/
  }
  
    .dp-custom-menu {
  box-shadow: 0 0 6px #e0e3e5;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}


.ion-font-weight-bold {
  font-weight: medium;
  font-size: 18px;
}

</style>

