<template>
  <ion-modal mode="ios" :is-open="isOpen" @willDismiss="closeModal">
    <ion-header mode="ios">
      <ion-toolbar mode="ios">
        <ion-buttons mode="ios" slot="start">
          <ion-button mode="ios" @click="closeModal">Close</ion-button>
        </ion-buttons>
        <ion-title mode="ios">{{ title }}</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-for="(text, index) in content" :key="index">
        <p>{{ text }}</p>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, inject } from 'vue';
import { IonModal, IonHeader, IonToolbar, IonButtons, IonButton, IonTitle, IonContent } from '@ionic/vue';

// Reactive modal state
const isOpen = ref(false);
const title = ref('');
const content = ref([]);

// Injecting modal actions
const informationModalActions = inject('informationModalActions');

// Modal control methods
const closeModal = () => {
  isOpen.value = false; // Ensure this controls visibility
};

const openModal = (modalTitle, modalContent) => {
  title.value = modalTitle;
  content.value = splitSentences(
    Array.isArray(modalContent) ? modalContent : [modalContent]
  );
  isOpen.value = true;
};

// Utility to split sentences
const splitSentences = (arr) => {
  const result = [];
  arr.forEach((item) => {
    const sentences = item.split('. ');
    sentences.forEach((sentence, index) => {
      result.push(index === sentences.length - 1 ? sentence : sentence + '.');
    });
  });
  return result;
};

// Set up modal actions for external usage
onMounted(() => {
  // Direct access to methods is not needed anymore
  // You can skip the injection-related code
});

onBeforeUnmount(() => {
  if (informationModalActions) {
    informationModalActions.openModal = null;
    informationModalActions.closeModal = null;
  }
});
</script>